import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import awsLogo from '../../../assets/images/aws.svg';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import secretDetectionIcon from '@iso/assets/images/secret-detection-icon.svg';
import sonarcubeIcon from '@iso/assets/images/sonarcube-icon.svg';
import checkmarkIcon from '@iso/assets/images/checkmark-icon.svg';
import { marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import plusIcon from '@iso/assets/images/plusIcon.svg';

const Option = SelectOption;

const titleStyle = {
    color: "#7b7b7b",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const subTitleStyle = {
    color: "#1F1F1F",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// import TableDemoStyle from './Demo.styles';
// import { tableinfos } from './configs';
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '220px'
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '220px'
    }
]

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "15px"
};

let innerSubtitleStyle = {
    fontSize: "13px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};

const fadeMarginBot = {
    position: 'fixed',
    opacity: '0',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '-1',
    width: "25%",
    padding: '15px'
};

const addButtonStyle = {
    marginRight: "10px",
    fontSize: "25px"
};

const buttonCss = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 46px"
};

const widgetStyle = {
    margin: "20px",
    width: "100%",
};

export default ({
    workflowNodeData,
    workflowEdgesData,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();

    const handelCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }


    const handleClick = (type) => {
        console.log("clicked handleClick :", type);
        setOpenRightDrawer({
            drawerType: type,
            drawerProps: {
                data: workflowData,
                columnId: columnId,
                type: "build",
                className: "expend",
                workflowNodeData: workflowNodeData,
                workflowEdgesData: workflowEdgesData
            },
        });
    }


    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            {currentWorkflowData?.deployConfig?.enabled === false && <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "40px" }}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("deployClickRightDrawer", 0)}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                        <img src={secretDetectionIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Environment</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Add an environment (Development, Staging, Production) to your workflow. Please note, you will only be able to see ACTIVE environments associated with the service.</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper>}

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px"}}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("post-build-customScript")}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{display:"flex", justifyContent: "start", alignItems: "center"}}>
                                    <span style={{display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)"}}>
                                        <img src={customScriptIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Post Build Custom Script {currentWorkflowData && currentWorkflowData.buildConfig?.customScriptConfig?.config?.script && <b style={{color: "#46C0FD", fontWeight: "400", fontSize: "12px"}}> ( already added )</b>}</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Write a script to perform custom tasks.</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper>

            {/* <Box
                title="Select tools from following"
                subtitle="Click to add & configure tools in the workflow"
                titleStyle={innerTitleStyle}
                subTitleStyle={innerSubtitleStyle}
                style={{ border: 0, padding: "20px 20px", margin: 0 }}
            /> */}
            
            {/* <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("buildTools")}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                        <img src={secretDetectionIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Environment</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper> */}

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px"}}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("buildTools", 0)}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{display:"flex", justifyContent: "start", alignItems: "center"}}>
                                    <span style={{display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)"}}>
                                        <img src={sonarcubeIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Trivy {currentWorkflowData && currentWorkflowData.buildConfig?.tools.length > 0 && <b style={{color: "#46C0FD", fontWeight: "400", fontSize: "12px"}}> ( already added )</b>}</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Trivy is a comprehensive security scanner for vulnerabilities in container images, file systems, and Git repositories. It identifies potential security risks by detecting known vulnerabilities and misconfigurations, ensuring that the deployed application is secure and compliant with industry standards.</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper>

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px", marginBottom: "100px"}}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("post-push-customScript")}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{display:"flex", justifyContent: "start", alignItems: "center"}}>
                                    <span style={{display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)"}}>
                                        <img src={customScriptIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Custom Deploy Scripts {currentWorkflowData && currentWorkflowData.buildConfig?.postPushCustomScriptConfig?.config?.script && <b style={{color: "#46C0FD", fontWeight: "400", fontSize: "12px"}}> ( already added )</b>}</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Write a script to perform custom tasks.</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper>

            {/* <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("sourceTools")}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                        <img src={sonarcubeIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Trivy</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper>

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0" }} onClick={() => handleClick("sourceTools")}>
                    <ContentHolder
                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px", minHeight: "122px" }}
                    >
                        <Box
                            title={
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                        <img src={sonarcubeIcon} />
                                    </span>
                                    <p style={{ color: "#1F1F1F", marginLeft: "5px" }}>Tool 3</p>
                                </div>
                            }
                            subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p>}
                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                            titleStyle={innerTitleStyle}
                            subTitleStyle={innerSubtitleStyle}
                        />
                        <img src={plusIcon} alt="add" style={{ position: "absolute", top: "10px", right: "10px", width: "16px", height: "16px", objectFit: "cover" }} />
                    </ContentHolder>
                </Box>
            </StickerWidgetWrapper> */}
        </LayoutWrapper>
    );
};