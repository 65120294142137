import React, { memo, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import Switch from '@iso/components/uielements/switch';
import Select, { SelectOption } from '@iso/components/uielements/select';
import Form from '@iso/components/uielements/form';
import PageHeader from '@iso/components/utility/pageHeader';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import ContentHolder from '@iso/components/utility/contentHolder';
import basicStyle from '@iso/assets/styles/constants';
import { switchOptions, selectOptions, defaultValues } from '../../AdvancedUI/CodeMirror/config';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from "@codemirror/language";
import { shell } from "@codemirror/legacy-modes/mode/shell";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
// import { javascript } from '@codemirror/lang-javascript';
import { androidstudio, androidstudioInit } from '@uiw/codemirror-theme-androidstudio';
import { BreadcrumbContext } from '../../../context/breadcrumbContext';
import { validateYaml, yamlLinter } from '../../../context/helper';
import { lintGutter } from '@codemirror/lint';
import { linter } from '@codemirror/lint';
import { EditorState } from "@codemirror/state";
import useDebounce from '../../Dashboard/CustomHooks/useDebounce';
import { RangeSetBuilder } from "@codemirror/state";
import { EditorView, Decoration, DecorationSet } from "@codemirror/view";


// import { tags as t } from '@lezer/highlight';

const FormItem = Form.Item;
const Option = SelectOption;

let code = `%TAG ! tag:clarkevans.com,2002:
--- !shape
  # Use the ! handle for presenting
  # tag:clarkevans.com,2002:circle
- !circle
  center: &ORIGIN {x: 73, y: 129}
  radius: 7
- !line
  start: *ORIGIN
  finish: { x: 89, y: 102 }
- !label
  start: *ORIGIN
  color: 0xFFEEBB
  text: Pretty vector drawing.`;

const compareProps = (prevProps, nextProps) => {
  if (prevProps.data !== nextProps.data) {
    return false;
  }
  return true;
}

export default memo(({
  data,
  height,
  minHeight,
  type,
  setData,
  setError,
  customPadding,
  onChange,
  form,
  values,
  submitCount,
  onCancel,
  onDelete,
  onEditCancel,
  tabSelected,
  readOnly
}) => {
  // const { rowStyle, colStyle, gutter } = basicStyle;
  const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, setMarketplaceData } = useContext(AppContext);
  const [newYAMLCode, setNewYAMLCode] = useState(data);
  const [extensions, seExtensions] = useState([]);
  const { pageHeading } = useContext(BreadcrumbContext);
  const editorRef = useRef(null);
  const isAtBottomRef = useRef(true); // Tracks if the user is at the bottom

  const yamlCodeRef = useRef(""); //using a reference to avoid heavy operations. Whenever there is a change in data, reference along with original state gets updated. Otherwise, only reference gets updated significantly increasing performance.

  const options = {

  }

  console.log("Re-rendering, :");

  useEffect(() => {
    if (type === "script") {
      seExtensions([
        StreamLanguage.define(shell),
        EditorState.readOnly.of(readOnly)
      ]);
    }
    else if (type !== "logs") {
      seExtensions([
        StreamLanguage.define(yaml),
        lintGutter(), // Linting gutter
        linter((view) => yamlLinter(view, form)), // Custom linter function
        EditorState.readOnly.of(readOnly)
      ]);
    }
  }, []);

  // useEffect(() => {
  //   if (marketplaceData.overviewData) {
  //     marketplaceData.overviewData.yaml.updatedContent = newYAMLCode;
  //     setMarketplaceData({ ...marketplaceData });
  //   }
  // }, [newYAMLCode]);

  useEffect(() => {
    console.log("Old and new: ", data, yamlCodeRef.current);
      if (data != yamlCodeRef.current) {
        console.log("data :", data);
        console.log("newYAMLCode :", yamlCodeRef.current);
        setNewYAMLCode(data);
        yamlCodeRef.current = data;
      }
  }, [data]);

  const handleChange = (value) => {
    console.log("Calling handleChange: ", value);
    // setNewYAMLCode(value);
    if (type === "script" || type === "logs") {
      setData(value);
      // setNewYAMLCode(value);
      yamlCodeRef.current = value;
      setError(false);
    }
    else if (["Add Services", "Edit Services", "Clone Environment Services", "Add New Services"].includes(pageHeading.title)) {
      // setNewYAMLCode(value);
      setData(value);
      yamlCodeRef.current = value;
      // setNewYAMLCode(value);
    }
    else if (!["Add Services", "Edit Services", "Clone Environment Services", "Add New Services"].includes(pageHeading.title)) {
      // setNewYAMLCode(value);
      yamlCodeRef.current = value;
      setMarketplaceData((marketplaceData) => ({ 
        ...marketplaceData,
        overviewData: {
          ...marketplaceData?.overviewData,
          yaml: {
            ...marketplaceData.overviewData?.yaml,
            updatedContent: value
          }
        }
      }));    
    }

    if (onChange) {
      onChange(value);
    }
  }

  const isUserAtBottom = () => {
    console.log("Custom scroll triggered===", editorRef.current);
    if (editorRef.current) {
      if (editorRef.current.view) {
        const { scrollTop, scrollHeight, clientHeight, offsetHeight } = editorRef.current.view.scrollDOM;
        const scrollbarHeight = offsetHeight - clientHeight;
        console.log("Entering scroll heights...", scrollTop, clientHeight, scrollHeight);
        isAtBottomRef.current = (scrollTop + clientHeight - scrollbarHeight) >= scrollHeight; // 5px margin
      }
    }
    // else {
    //   isAtBottomRef.current = false;
    // }
  }

  const scrollToBottom = (view) => {
    if (type === "logs") {
      console.log("Updated scroll value: ", isAtBottomRef.current);
      if (isAtBottomRef.current) {
        const docHeight = view.contentHeight;
        if (view.scrollDOM) {
          view.scrollDOM.scrollTop = docHeight; // Scroll to the bottom
        }
      }
      isUserAtBottom();
    }
  };

  const debouncedChange = useDebounce(handleChange, 300);

  console.log("My debounce: ", debouncedChange);

  // Create decorations for rendering HTML logs
  const createDecorations = (htmlLogs) => {
    const builder = new RangeSetBuilder();
    let position = 0;

    htmlLogs.forEach((html, index) => {
      const widget = Decoration.widget({
        widget: new HTMLWidget(html),
        side: 1,
      });
      builder.add(position, position, widget);
      position += html.length + 1; // Account for line breaks
    });

    return builder.finish();
  };

  // Custom widget to render HTML logs
  class HTMLWidget {
    constructor(html) {
      this.html = html;
      this.container = null; // Reference to the DOM element
    }
  
    toDOM() {
      // Create the container for the widget
      this.container = document.createElement("div");
      this.container.innerHTML = this.html;
      return this.container;
    }
  
    ignoreEvent() {
      return true; // Prevent events on the widget from affecting the editor
    }
  
    destroy() {
      // Clean up the DOM element if necessary
      if (this.container) {
        this.container.remove();
        this.container = null;
      }
    }
  }
  

  useEffect(() => {
    if (type === "logs") {
      console.log("My data in html: ", data);
    const decorations = createDecorations(data);
    seExtensions([
      EditorView.decorations.of(decorations),
      // EditorView.editable.of(false), // Read-only mode
      EditorState.readOnly.of(readOnly)
    ]);
  }
  }, [data]);
  


  return (
    <LayoutWrapper style={{ padding: (type === "script" || type === "logs") ? "0" : customPadding ? customPadding : "40px 20px", cursor: "text" }}>
      <Box style={{ padding: 0 }}>
        <ContentHolder style={{ marginTop: "0" }}>
          {/* {console.log("Getting logs :", data?.map(() => "").join("\n"))} */}
          <CodeMirror
            value={newYAMLCode}
            ref={editorRef}
            height={height}
            minHeight={minHeight ? minHeight : ""}
            theme={androidstudio}
            resetCursorOnSet={false}
            onBlur={(e) => {
              // if (["Add Services", "Edit Services", "Clone Environment Services", "Add New Services"].includes(pageHeading.title)) {
              //   console.log("My value: ", form.getFieldValue("yamlData"));
              //   setData(form.getFieldValue("yamlData"));
              // }
            }}
            // extensions={[javascript({ jsx: true })]}


            // extensions={type === "script" ? [StreamLanguage.define(shell)] : [StreamLanguage.define(yaml)]}

            options={{
              mode: type !== "logs" ? 'yaml' : undefined, // YAML mode
              lineNumbers: true,
              lineWrapping: type === "logs",
              lint: !["script", "logs"].includes(type) ? true : false, // Enable linting
              gutters: !["script", "logs"].includes(type) ? ['CodeMirror-lint-markers'] : [], // Show lint markers
            }}
            extensions={[...extensions]}

            // options={{
            //   theme: 'androidstudio',
            //   keyMap: 'sublime',
            //   mode: 'text/x-yaml',
            // }}
            // options={{
            //   "lineNumbers": true,
            //   "indentWithTabs": true,
            //   "mode": "css",
            //   "gutters": ['CodeMirror-lint-markers'],
            //   "lint": true
            // }}

            onChange={debouncedChange}
            onUpdate={(viewUpdate) => scrollToBottom(viewUpdate.view)} />
        </ContentHolder>
      </Box>
    </LayoutWrapper>
  );
}, compareProps);