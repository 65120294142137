import validator from 'validator';
import overviewImg from '../assets/images/cluster-overview-img.svg';
import logoImg from '../assets/images/cluster-logsImg.svg';
import addonsImg from '../assets/images/cluster-logsImg.svg';
import nodegroupsImg from '../assets/images/cluster-nodegroupImg.svg';
import marketplaceImg from '../assets/images/cluster-marketplaceImg.svg';
import overviewImgBlue from '../assets/images/cluster-overview.png';
import logoImgBlue from '../assets/images/cluster-logsImgBlue.svg';
import addonsImgBlue from '../assets/images/cluster-logsImgBlue.svg';
import nodegroupsImgBlue from '../assets/images/cluster-nodegroupImgBlue.svg';
import marketplaceImgBlue from '../assets/images/cluster-marketplaceImgBlue.svg';
import awsIcon from '@iso/assets/images/aws.svg';
import githubIcon from '@iso/assets/images/aws.svg';
import gitlabIcon from '@iso/assets/images/aws.svg';
import azure from '@iso/assets/images/aws.svg';
import gcp from '@iso/assets/images/aws.svg';
import cloudflare from '@iso/assets/images/aws.svg';
import docker from '@iso/assets/images/aws.svg';
import ecr from '@iso/assets/images/aws.svg';
import restartBlueButton from '@iso/assets/images/restart-with-blue-background.svg';
import failedRedButton from '@iso/assets/images/failed-with-red-background.svg';
import cancelledRedButton from '@iso/assets/images/cancel-icon-workflow-steps.svg';
import successGreenButton from '@iso/assets/images/success-with-green-background.svg';
import pendingYellowButtonButton from '@iso/assets/images/pending-clock-with-yellow-background.svg';
import { LoadingOutlined, ArrowDownOutlined } from "@ant-design/icons";
import yaml from "js-yaml";
import api from '../api';

import rbacSelected from '../assets/images/rbacSelected.svg';
import rbacUnselected from '../assets/images/rbacUnselected.svg';

export const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const dashboardContent = {
    "no_of_aws_account": "AWS",
    "no_of_github_account": "GitHub",
    "no_of_gitlab_account": "GitLab",
    "no_of_azure_account": "Azure",
    "no_of_gcp_account": "GCP",
    "no_of_cloudflare_account": "Cloudflare",
    "no_of_dockerhub_account": "Docker Hub",
    "no_of_ecr_account": "ECR",
};

export const integrationLogoType = {
    "aws": "no_of_aws_account",
    "github": "no_of_github_account",
    "gitlab": "no_of_gitlab_account",
    "azure": "no_of_azure_account",
    "gcp": "no_of_gcp_account",
    "cloudflare": "no_of_cloudflare_account",
    "dockerhub": "no_of_dockerhub_account",
    "ecr": "no_of_ecr_account",
};

export const superDashboardContent = {
    "org": "tempOrg",
    "env": "tempEnv",
    "range": "tempRange",
};

export const dashboardImages = {
    "no_of_aws_account": awsIcon,
    "no_of_github_account": githubIcon,
    "no_of_gitlab_account": gitlabIcon,
    "no_of_azure_account": azure,
    "no_of_gcp_account": gcp,
    "no_of_cloudflare_account": cloudflare,
    "no_of_dockerhub_account": docker,
    "no_of_ecr_account": ecr,
};

// export const DyanamicBranchNames = {
//     "Secret Detection": "detect-secrets",
//     "Trivy": "trivy",
//     // "Deploy": "deploy",
//     // "Script": "pre_build_script",
//     "pre_build_script": "Pre build script",
//     "post_build_script": "Post build script",
// };

export const workflowContents = {
    "": "",
    undefined: "",
    null: "",
    0: "",
    "Secret Detection": "detect-secrets",
    "Trivy": "trivy",
    // "Deploy": "deploy",
    // "Script": "pre_build_script",
    "pre_build_script": "Pre build script",
    "post_build_script": "Post build script",
    "custom_deploy_script": "Custom deploy script",
    "Branch": "branch",
    "branch": "Branch",
    "Wildcard Branch": "wildcard_branch",
    "wildcard_branch": "Wildcard Branch",
    "wildcard branch": "Wildcard Branch",
    "Manual": "manual",
    "manual": "Manual",
    "Automatic": "automatic",
    "automatic": "Automatic",
    "Deploy": "deploy",
    "arm": "arm64",
    "amd": "amd64",
    "arm64": "arm",
    "amd64": "amd",
    "abort": "Abort",
    "skip": "Skip",
};

export const workflowNodePipelineStatus = {
    "Succeeded": {
        background: "#E3FBE4",
    },
    "Failed": {
        background: "#FFDCDC",
    },
    "Running": {
        background: "#FFF6DE",
    },
};

export const workflowImgObj = {
    "Pending": pendingYellowButtonButton,
    "Failed": failedRedButton,
    "Success": successGreenButton,
    "Succeeded": successGreenButton,
    "Cancelled": cancelledRedButton,
    "Error": failedRedButton,
    "Running": <div style={{  }}>
        <LoadingOutlined style={{ fontSize: "14px", color: "#F6C344" }} />
        {/* <ArrowDownOutlined style={{ transform: "translate(-18px, -3px)", width: "12px", height: "12px" }} /> */}
    </div>
};

export const workflowStatuses = {
    "Running": {
        color: "#F6C344"
    },
    "abort": {
        color: "#D20A0A",
        text: "Abort",
    },
    "skip": {
        color: "#667085",
        text: "Skip",
    },
    "Success": {
        color: "#008143"
    },
    "Succeeded": {
        color: "#008143"
    },
    "Pending": {
        color: "#F4A460"
    },
    "Failed": {
        color: "#D20A0A"
    },
    "Cancelled": {
        color: "#CB444A"
    },
    "Error": {
        color: "#CB444A"
    },
};

export const myClusterStatus = {
    "CLUSTER_FAILED": "Failed",
    "CLUSTER_CREATING": "Creating",
    "CLUSTER_CREATED": "Created",
    "CLUSTER_DESTROYED": "Destroyed",
    "BOOTSTRAP_INITIALIZING": "Addons Initialising",
    "BOOTSTRAP_INITIALIZED": "Active",
    "BOOTSTRAP_FAILED": "Failed",
    "BOOTSTRAP_DESTROYING": "Addons Destroying",
    "CLUSTER_DESTROYING": "Destroying",
    "CREATING": "Creating",
    "Active": "Active",
    "ACTIVE": "Active",
    "DELETING": "Destroying",
    "FAILED": "Failed",
    "UPDATING": "Updating",
    "PENDING": "Pending",
    "Out Of Sync": "Out Of Sync",
    "CLUSTER_DESTROY_FAILED": "Destroy Failed",
    "CLUSTER_UPDATING": "Cluster Updating",
    "CLUSTER_UPDATED": "Cluster Updated",
    "UPDATE_FAILED": "Update Failed",
    "NODEGROUP_CREATING": "Nodegroup Creating",
    "NODEGROUP_CREATED": "Nodegroup Created",
    "NODEGROUP_FAILED": "Nodegroup Failed",
    "NODEGROUP_DESTROYING": "Nodegroup Destroying",
    "NODEGROUP_DESTROYED": "Nodegroup Destroyed",
    "ADDON_DESTROY_FAILED": "Addon Destroy Failed",
    "NODEGROUP_DESTROY_FAILED": "Nodegroup Destroy Failed",
    "NODEGROUP_UPDATING": "Nodegroup Updating",
    "NODEGROUP_UPDATE_FAILED": "Nodegroup Update Failed",
    "NODEGROUP_UPDATED": "Nodegroup Updated",
    "ADDON_UPDATING": "Addon Updating",
    "ADDON_UPDATED": "Addon Updated",
    "ADDON_UPDATE_FAILED": "Addon Update Failed"
};
export const provisionerStatuses = {
    "default": {
        border: "2px solid",
        backgroundColor: "white",
        color: "#9b9b9b",
        padding: "1px 14px",
        borderRadius: "17px",
        display: "flex",
        justifyContent: "center",
        fontFamily: "Nunito Sans"
    },
    "atmosly": {
        border: "2px solid",
        backgroundColor: "white",
        color: "#46C0FD",
        padding: "1px 14px",
        borderRadius: "17px",
        display: "flex",
        justifyContent: "center",
        fontFamily: "Nunito Sans"
    },
    "imported": {
        border: "2px solid",
        backgroundColor: "white",
        color: "#EFA00C",
        padding: "1px 14px",
        borderRadius: "17px",
        display: "flex",
        justifyContent: "center",
        fontFamily: "Nunito Sans"
    }
};

export const nodegroupStatuses = {
    "Failed": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "Creating": {
        color: "#343434",
        background: "#FFC107",
    },
    "Created": {
        color: "#FFFFFF",
        background: "#53A551",
    },
    "Active": {
        color: "#FFFFFF",
        background: "#53A551",
    },
    "Updating": {
        color: "#343434",
        background: "#FFC107",
    },
    "Update Failed": {
        color: "#FFFFFF",
        background: "#980000",
    },
    "Failed": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "Destroying": {
        color: "#343434",
        background: "#FFC107",
    },
    "Deleting": {
        color: "#343434",
        background: "#FFC107",
    },
    "Destroy Failed": {
        color: "#FFFFFF",
        background: "#CB444A",
    }
};
export const clusterStatuses = {
    "CLUSTER_FAILED": {
        background: "#CB444A",
        color: "white",
        text: "Failed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_CREATING": {
        background: "#FFC107",
        color: "#343434",
        text: "Creating",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_CREATED": {
        background: "#53A551",
        color: "white",
        text: "Created",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_DESTROYED": {
        background: "#CB444A",
        color: "white",
        text: "Destroyed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "BOOTSTRAP_INITIALIZING": {
        background: "#FFC107",
        color: "#343434",
        text: "Addons Initialising",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "BOOTSTRAP_INITIALIZED": {
        background: "#53A551",
        color: "white",
        text: "Active",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "BOOTSTRAP_FAILED": {
        background: "#CB444A",
        color: "white",
        text: "Failed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "BOOTSTRAP_DESTROYING": {
        background: "#DBA1A1",
        color: "white",
        text: "Addons Destroying",
        padding: "3px 14px",
        border: "#DBA1A1",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_DESTROYING": {
        background: "#DBA1A1",
        color: "white",
        text: "Destroying",
        padding: "3px 14px",
        border: "#DBA1A1",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CREATING": {
        background: "#FFC107",
        color: "#343434",
        text: "Creating",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Running": {
        background: "#FFC107",
        color: "#1f1f1f",
        text: "Running",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Active": {
        background: "#53A551",
        color: "white",
        text: "Active",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "ACTIVE": {
        background: "#53A551",
        color: "white",
        text: "Active",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Completed": {
        background: "#53A551",
        color: "white",
        text: "Completed",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DELETING": {
        background: "#DBA1A1",
        color: "white",
        text: "Destroying",
        padding: "3px 14px",
        border: "#DBA1A1",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "FAILED": {
        background: "#CB444A",
        color: "white",
        text: "Failed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Failed": {
        background: "#CB444A",
        color: "white",
        text: "Failed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "UPDATING": {
        background: "#FFC107",
        color: "#343434",
        text: "Updating",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "PENDING": {
        background: "#FFC107",
        color: "#343434",
        text: "Pending",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Pending": {
        background: "#FFC107",
        color: "#343434",
        text: "Pending",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Out Of Sync": {
        background: "#CB444A",
        color: "white",
        text: "Out Of Sync",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_DESTROY_FAILED": {
        text: "Destroy Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "ADDON_DESTROY_FAILED": {
        text: "Addon Destroy Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "ADDON_UPDATING": {
        text: "Addon Updating",
        color: "#343434",
        background: "#FFC107",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "ADDON_UPDATED": {
        background: "#53A551",
        color: "white",
        text: "Addon Updated",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "ADDON_UPDATE_FAILED": {
        text: "Addon Update Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "UPDATE_FAILED": {
        text: "Update Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_UPDATING": {
        text: "Updating",
        color: "#FFFFFF",
        background: "#FFC107",
        padding: "3px 14px",
        border: "1px solid #FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "CLUSTER_UPDATED": {
        background: "#53A551",
        color: "white",
        text: "Cluster Updated",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_CREATING": {
        text: "Nodegroup Creating",
        color: "#343434",
        background: "#FFC107",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_CREATED": {
        text: "Nodegroup Created",
        color: "#FFFFFF",
        background: "#53A551",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_FAILED": {
        text: "Nodegroup Failed",
        color: "#FFFFFF",
        background: "#CB444A",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_DESTROYING": {
        text: "Nodegroup Destroying",
        background: "#DBA1A1",
        color: "white",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_DESTROYED": {
        background: "#CB444A",
        color: "white",
        text: "Nodegroup Destroyed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_DESTROY_FAILED": {
        text: "Nodegroup Destroy Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_UPDATING": {
        text: "Nodegroup Updating",
        color: "#343434",
        background: "#FFC107",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_UPDATE_FAILED": {
        text: "Nodegroup Update Failed",
        color: "#FFFFFF",
        background: "#CB444A",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "NODEGROUP_UPDATED": {
        background: "#53A551",
        color: "white",
        text: "Nodegroup Updated",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "ACTIVE": {
        background: "#53A551",
        color: "white",
        text: "Active",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DESTROYING": {
        text: "Addon Destroying",
        background: "#DBA1A1",
        color: "white",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "UPDATING": {
        text: "Addon Updating",
        color: "#343434",
        background: "#FFC107",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "UPDATE FAILED": {
        text: "Addon Update Failed",
        background: "#980000",
        color: "white",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DESTROY FAILED": {
        text: "Addon Destroy Failed",
        background: "#980000",
        color: "white",
        padding: "3px 14px",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    //...nodegroupStatuses
};
export const nodesStatuses = {
    "running": {
        color: "#343434",
        background: "#FFC107",
    },
    "terminated": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "shutting-down": {
        color: "#343434",
        background: "#CB444A",
    },
    "pending": {
        background: "#DBA1A1",
        color: "white",
    },
    "stopped": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "rebooting": {
        background: "#FFC107",
        color: "#343434",
    }
}
export const PodsStatuses = {
    "Running": {
        color: "#343434",
        background: "#FFC107",
    },
    "Terminating": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "Succeeded": {
        color: "#FFFFFF",
        background: "#53A551",
    },
    "Pending": {
        color: "#343434",
        background: "#FFC107",
    },
    "Failed": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "Unknown": {
        background: "#DBA1A1",
        color: "white",
    },
    "CrashLoopBackOff": {
        color: "#FFFFFF",
        background: "#CB444A",
    },
    "ImagePullBackOff": {
        background: "#DBA1A1",
        color: "white",
    },
    "ImagePullError": {
        color: "#FFFFFF",
        background: "#CB444A",

    },
}

export const clusterAddonFormat = {
    "cert_manager_enabled": "Cert Manager Enabled",
    "create_efs_storage_class": "Create Efs Storage Class",
    "nginx_alb_controler": "Enable Ingress ALB Controller",
    "nginx_ingress_controler": "Enable Nginx Ingress Controller",
    "pgl_stack": "PGL Stack",

    "Cert Manager": "cert_manager_enabled",
    "EFS Storage Class": "create_efs_storage_class",
    "Ingress ALB Controller": "nginx_alb_controler",
    "Nginx Ingress Controller": "nginx_ingress_controler",
    "PGL stack": "pgl_stack",
    "K8s Dashboard": "k8s_dashboard",
    "ArgoFlow": "argoFlow",
    "Reloader": "enable_reloader"
    // "enable_ingress_nginx": "-",
    // "cert_manager_install_letsencrypt_http_issuers": "-",
    // "cert_manager_letsencrypt_email": "-",
    // "create_service_monitor_crd": "-",
    // "enable_amazon_eks_aws_ebs_csi_driver": "-",
    // "enable_amazon_eks_vpc_cni": "-",
    // "enable_aws_node_termination_handler": "-",
    // "enable_cluster_autoscaler": "-",
    // "enable_cluster_propotional_autoscaler": "-",
    // "enable_external_secrets": "-",
    // "enable_istio": "-",
    // "enable_karpenter": "-",
    // "enable_keda": "-",
    // "enable_metrics_server": "-",
    // "enable_reloader": "-",
    // "enable_single_az_ebs_gp3_storage_class": "-",
    // "karpenter_provisioner_enabled": "-",
};

export const clusterAddonsContext = {
    "Cert Manager": "cert_manager_enabled",
    "Istio": "enable_istio",
    "EFS Storage Class": "create_efs_storage_class",
    "Karpenter": "enable_karpenter",
    "External Secrets": "enable_external_secrets",
    "Ingress ALB Controller": "nginx_alb_controler",
    "Nginx Ingress Controller": "nginx_ingress_controler",
    "PGL stack": "pgl_stack",
    "K8s Dashboard": "k8s_dashboard",
    "ArgoFlow": "argoFlow",
    "Keda": "enable_keda",
    "Reloader": "enable_reloader"
}


export const enumImgs = {
    "Cluster Details": overviewImg,
    "Cluster Logs": logoImg,
    "Addons Logs": addonsImg,
    "Nodegroups & Pods": nodegroupsImg,
    "Marketplace": marketplaceImg,
    "Cluster Details Active": overviewImgBlue,
    "Cluster Logs Active": logoImgBlue,
    "Addons Logs Active": addonsImgBlue,
    "Nodegroups & Pods Active": nodegroupsImgBlue,
    "Marketplace Active": marketplaceImgBlue,
    "RBAC": rbacUnselected,
    "RBAC Active": rbacSelected
}

export const marketplaceStatuses = {
    "DEPLOY_FAILED": {
        text: "Failed",
        color: "#FFFFFF",
        background: "#CB444A",
        border: "#CB444A",
    },
    "DELETE_FAILED": {
        text: "Delete Failed",
        color: "#FFFFFF",
        background: "#CB444A",
        border: "#CB444A",
    },
    "UPDATE_FAILED": {
        text: "Update Failed",
        color: "#FFFFFF",
        background: "#CB444A",
        border: "#CB444A",
    },
    "DEPLOYING": {
        text: "Deploying",
        color: "#FFFFFF",
        background: "#FFC107",
        border: "1px solid #F6C344",
    },
    "UPDATING": {
        text: "Updating",
        color: "#FFFFFF",
        background: "#FFC107",
        border: "1px solid #F6C344",
    },
    "DEPLOYED": {
        text: "Deployed",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
    },
    "DELETING": {
        text: "Deleting",
        color: "#FFFFFF",
        background: "#FFC107",
        border: "1px solid #F6C344",
    },
    "HEALTHY": {
        text: "Healthy",
        color: "#53A551",
        background: "#FFC107",
        border: "1px solid #F6C344",
    },
    "UNHEALTHY": {
        text: "Unhealthy",
        color: "#D20A0A",
        background: "#FFC107",
        border: "1px solid #F6C344",
    },
    "PROGRESSING": {
        text: "Progressing",
        color: "#39cced",
        background: "#39cced",
        border: "1px solid #39cced",
    },
};

export let releaseTypes = ["App CI", "App CD", "Cron CD", "Queues & Workers CD"];

export const environmentStatuses = {
    "release_failed": {
        text: "Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Failed": {
        text: "Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Healthy": {
        text: "Completed",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Complete": {
        text: "Complete",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Succeeded": {
        text: "Succeeded",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Active": {
        text: "Active",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Completed": {
        text: "Completed",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Running": {
        text: "Running",
        color: "#1f1f1f",
        background: "#FFC107",
        padding: "3px 14px",
        border: "1px solid #FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Deployment Pending": {
        background: "#FFC107",
        color: "#343434",
        text: "Pending",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "running": {
        text: "Running",
        color: "#1f1f1f",
        background: "#FFC107",
        padding: "3px 14px",
        border: "1px solid #FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "progressing": {
        text: "Progressing",
        color: "#ffffff",
        background: "#39cced",
        padding: "3px 14px",
        border: "1px solid #39cced",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "PENDING": {
        background: "#FFC107",
        color: "#343434",
        text: "Pending",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Pending": {
        background: "#FFC107",
        color: "#343434",
        text: "Pending",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Deployment Pending": {
        background: "#FFC107",
        color: "#343434",
        text: "Pending",
        padding: "3px 14px",
        border: "#FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "missing": {
        text: "Missing",
        color: "#ffffff",
        background: "#808080",
        padding: "3px 14px",
        border: "1px solid #808080",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "degraded": {
        text: "Degraded",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "healthy": {
        text: "Healthy",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DEPLOYING": {
        text: "Deploying",
        color: "#ffffff",
        background: "#39cced",
        padding: "3px 14px",
        border: "1px solid #39cced",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Initialising": {
        text: "Initialising",
        color: "#ffffff",
        background: "#808080",
        padding: "3px 14px",
        border: "1px solid #808080",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DEPLOY_FAILED": {
        text: "Deploy Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DEPLOYED": {
        text: "Deployed",
        padding: "3px 14px",
        color: "#FFFFFF",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "UPDATING": {
        text: "Updating",
        color: "#FFFFFF",
        background: "#FFC107",
        padding: "3px 14px",
        border: "1px solid #FFC107",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Updating": {
        text: "Updating",
        color: "#FFFFFF",
        background: "#1e90ff",
        padding: "3px 14px",
        border: "1px solid #1e90ff",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "UPDATE_FAILED": {
        text: "Update Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DELETING": {
        text: "Deleting",
        color: "#ffffff",
        background: "#39cced",
        padding: "3px 14px",
        border: "1px solid #39cced",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Destroying": {
        text: "Destroying",
        color: "#ffffff",
        background: "#e38084",
        padding: "3px 14px",
        border: "1px solid #e38084",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DELETE_FAILED": {
        text: "Delete Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Error": {
        text: "Error",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "DELETED": {
        text: "Deleted",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Destroyed": {
        text: "Destroyed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Destroy Failed": {
        text: "Destroy Failed",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#980000",
        border: "#980000",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Service Unavailable": {
        text: "Service Unavailable",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#cbcbcb",
        border: "#cbcbcb",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Scaling Up": {
        text: "Scaling Up",
        color: "#ffffff",
        padding: "3px 14px",
        background: "#53A551",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Scaling Down": {
        text: "Scaling Down",
        color: "#ffffff",
        padding: "3px 14px",
        background: "#FF9800",
        border: "#FF9800",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Unavailable": {
        text: "Unavailable",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#cbcbcb",
        border: "#cbcbcb",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Cancelled": {
        text: "Cancelled",
        color: "#FFFFFF",
        padding: "3px 14px",
        background: "#CB444A",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
};

export const NoDataContent = {
    Cluster: {
        title: "No Data",
        subTitle: "Let's add your first cluster in ATMOSLY.",
        buttontext: "Add Cluster"
    },
    Project: {
        title: "No Data",
        subTitle: "Let's add your first project in ATMOSLY.",
        buttontext: "Add Project"
    },
    Service: {
        title: "No Data",
        subTitle: "Let's create your first service in ATMOSLY.",
        buttontext: "Run Scan"
    },
    RolesAndPermissions: {
        title: "No Data",
        subTitle: "Let's create your first cluster in ATMOSLY.",
        buttontext: "Run Scan"
    },
    Pipelines: {
        title: "No Data",
        subTitle: "Let's create your first cluster in ATMOSLY.",
        buttontext: "Run Scan"
    },
    SecurityVulnerabilities: {
        title: "No Data",
        subTitle: "Let's perform your first security scan in ATMOSLY.",
        buttontext: "Run Scan"
    },
}

export const commonStatus = {
    "Active": {
        background: "#53A551",
        color: "white",
        text: "Active",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Failed": {
        background: "#CB444A",
        color: "white",
        text: "Failed",
        padding: "3px 14px",
        border: "#CB444A",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Running": {
        background: "rgba(92, 215, 255, 1)",
        color: "#1f1f1f",
        text: "Running",
        padding: "3px 14px",
        border: "rgba(92, 215, 255, 1)",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    },
    "Succeeded": {
        background: "#53A551",
        color: "white",
        text: "Succeeded",
        padding: "3px 14px",
        border: "#53A551",
        borderRadius: "17px",
        fontFamily: "Nunito Sans"
    }
}

export const enums = {
    "ondemand": "ON_DEMAND",
    "spot": "SPOT",
    "ON_DEMAND": "ondemand",
    "SPOT": "spot",
    "on-demand": "ON_DEMAND",
    "On-demand": "ondemand"
};

let envRegex = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;

export const validatePassword = (rule, value, callback) => {
    let val = value;
    if (val === "") {
        callback();
    }
    else {
        if (val.length < 8) {
            callback("Password must have at least 8 characters.");
        }
        else {
            callback();
        }
    }
}


export const validateName = (rule, value, callback) => {
    let val = "";
    if (value) {
        val = value.replace(/\s/g, '');
    }
    let myRegex = /^[a-z0-9]([_a-z0-9]*[a-z0-9])?$/;
    if (rule.field === "appName") {
        if (!envRegex.test(val)) {
            callback("Please enter a valid app name.");
        }
        else {
            if (val.length > 63) {
                callback("App name should have at most 63 characters.");
            }
            else {
                callback();
            }
        }
    }
    else if (rule.field === "displayName") {
        if (!envRegex.test(val)) {
            callback("Please enter a valid display name.");
        }
        else {
            if (val.length > 63) {
                callback("Display name should have at most 63 characters.");
            }
            else {
                callback();
            }
        }
    }
    else if (rule.field === "databaseName") {
        if (!myRegex.test(val)) {
            callback("Please enter a valid database name.");
        }
        else {
            if (val.length > 63) {
                callback("Database name should have at most 63 characters.");
            }
            else {
                callback();
            }
        }
    }
    else if (rule.field === "username") {
        if (!myRegex.test(val)) {
            callback("Please enter a valid username.");
        }
        else {
            if (val.length > 63) {
                callback("Username should have at most 63 characters.");
            }
            else {
                callback();
            }
        }
    }
}

function ifObj(err) {
    if (Array.isArray(Object.values(err)[0])) {
        console.log("Object.values(err)[0][0] :", Object.values(err)[0][0]);
        return Object.values(err)[0][0];
    }
    else if (typeof Object.values(err)[0] === 'object') {
        ifObj(Object.values(err)[0]);
    }
    else {
        console.log("Object.values(err)[0] :", Object.values(err)[0][0]);
        return Object.values(err)[0];
    }
}

export function formatErrors(err) {
    console.log("inside formatErrors :", err);
    if (Array.isArray(err)) {
        if (typeof err[0] === 'object') {
            return ifObj(err);
        }
        else {
            return err[0];
        }
    }
    else if (typeof err === 'object') {
        // console.log("error is object :", err);
        return ifObj(err);
    }
    else if (typeof err === 'string') {
        // console.log("error is object :", err);createPro
        return err;
    }
}

export function hasPermission(checkForPermission) {
    // console.log('checkForPermission :', checkForPermission);
    if (localStorage.getItem("profile-data")) {
        let userProfile = JSON.parse(localStorage.getItem("profile-data"));
        // console.log("checkForPermission :", checkForPermission);
        // console.log("userProfile :", userProfile);
        if (userProfile.permissions.includes(checkForPermission)) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export function isSuperUser() {
    // console.log('localStorage.getItem("profile-data") :', localStorage.getItem("profile-data"));
    let myData = localStorage.getItem("profile-data");
    console.log("checking for super user :", myData);
    if (myData) {
        let userProfile = JSON.parse(myData);
        console.log("userProfile in isSuperUser :", userProfile);
        if (userProfile.super) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export const permissionReducer = (state, action) => {
    console.log("Action title: ", action.title);
    console.log("Action type: ", action.type);
    console.log("state: ", state);
    switch (action.title) {
        case "Projects": return "Can add project"
        case "Project Services":
            switch (action.type) {
                case "CREATE": return "Can add project"
                case "DELETE": return "Can delete project"
                case "UPDATE": return "Can add project"
                case "VIEW_SECRET": return "Can view secret"
                //case ""
                default: return "Can add project"
            }
        case "Environment Variables": return "Can add project"
        case "Clusters": return "Can create cluster"
        // case "Basic Details": return "Can create cluster"
        // case "Basic Details":
        //     switch (action.type) {
        //         case "CREATE": return "Can create cluster"
        //         case "DELETE": return "Can delete cluster"
        //         default: return "Can create cluster"
        //     }
        case "Nodegroups Details": return "Can create cluster"
        case "RBAC":
            switch (action.type) {
                case "CREATE": return "Can create RBAC role"
            }
        case "Environments": return "Can add pipeline"
        case "Add Environment": return "Can add pipeline"
        case "Environment Overview": return "Can add pipeline"
        case "Add Services": return "Can add pipeline"
        case "Services Overview": return "Can add pipeline"
        case "Add New Services": return "Can add pipeline"
        case "Security": return "Can do security scan"
        case "Marketplace": return "Can only create, update and delete marketplace repositories"
        case "Marketplace Details": return "Can only create, update and delete marketplace repositories"
        case "Security Scans": return "Can do security scan"
        case "Deploy Marketplace":
            switch (action.type) {
                case "CREATE": return "Can only create, update and delete marketplace repositories"
                case "DELETE": return "Can only create, update and delete marketplace repositories"
                case "UPDATE": return "Can only create, update and delete marketplace repositories"
                case "DEPLOY": return "Can manage marketplace"
            }
        case "Users": return "Can invite user"
        case "Roles": return "Can add role"
        case "Integrations": return "Can integrate account"
        case "Workflow Details": return "Can add pipeline"
        case "Guardrail List": return "Can add guardrail"
        //case "Edit Guardrail": return "Can change guardrail"
        case "Create Guardrail": return "Can add guardrail"
        default: return state
    }
};

export const fetchingReducer = (state, action) => {
    switch (action.status) {
        case 'loading':
            return {
                ...state,
                [action.loader]: true
            }
        case 'loaded':
            return {
                ...state,
                [action.loader]: false
            }
        default:
            return state
    }
}

// export function checkDummyUser = () => {
// }
export function formatTime(seconds) {
    if ((seconds / 31536000) > 1) {
        let time = Math.floor(seconds / 31536000);
        return time + (time === 1 ? " year" : " years");
    }
    // const interval = seconds / 2592000;
    if ((seconds / 2592000) > 1) {
        let time = Math.floor(seconds / 2592000);
        return time + (time === 1 ? " month" : " months");
    }
    // const interval = seconds / 86400;
    if ((seconds / 86400) > 1) {
        let time = Math.floor(seconds / 86400);
        return time + (time === 1 ? " day" : " days");
    }
    // const interval = seconds / 3600;
    if ((seconds / 3600) > 1) {
        let time = Math.floor(seconds / 3600);
        return time + (time === 1 ? " hour" : " hours");
    }
    // const interval = seconds / 60;
    if ((seconds / 60) > 1) {
        let time = Math.floor(seconds / 60);
        return time + (time === 1 ? " min" : " mins");
    }
    // if interval is less then 0
    if (Math.floor(seconds) < 0) {
        return "0 second";
    }

    let time = Math.floor(seconds);
    return time + (time === 1 ? " second" : " seconds");
}

export function timeSince(date) {
    // console.log("date in timeSince :", date);

    let seconds = Math.floor((new Date() - date) / 1000);
    // console.log("seconds in timeSince :", seconds);
    return formatTime(seconds);

}
export function UpSince(seconds) {
    // If seconds are less than 0, return 0 seconds
    return formatTime(seconds);
}


export function ConvertUTCToLocal(rawDate, inDays) {
    const dayFormat = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    const timeFormat = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(rawDate).toLocaleString("en-US", inDays ? dayFormat : timeFormat);
}

export function ConvertUTCToLocalDate(rawDate) {
    const dateFormat = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Date(rawDate).toLocaleString("en-US", dateFormat);
}

export function PasswordValidator(value) {
    if (validator.isStrongPassword(value, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
        return true;
    } else {
        return false;
    }
}

export function RemoveClusterFromLocal() {
    localStorage.removeItem("Cluster");
    localStorage.removeItem("updateCluster");
    localStorage.removeItem("oneClickCluster");
    localStorage.removeItem("clusterTabSelected");
    localStorage.removeItem("isRegionAvailable");
    localStorage.removeItem("availablilityZones");
    localStorage.removeItem("no_of_azs");
}

export function RemoveEnvironmentFromLocal() {
    localStorage.removeItem("app-data");
    localStorage.removeItem("service-categories");
    localStorage.removeItem("dataSourcesData");
    localStorage.removeItem("ProjectDep");
    localStorage.removeItem("servicesEnabled");
    localStorage.removeItem("redisData");
    localStorage.removeItem("mongoDataSourcesData");
    localStorage.removeItem("stopFetch");
    localStorage.removeItem("deploymentData");
    localStorage.removeItem("container-registries");
    localStorage.removeItem("selectedProjectData");
    localStorage.removeItem("postgresDataSourcesData");
}

export function RemoveCloneEnvironmentFromLocal() {
    localStorage.removeItem("cloneAppData");
    localStorage.removeItem("cloneServiceCategories");
    localStorage.removeItem("cloneDataSourcesData");
    localStorage.removeItem("cloneSelectedProjectData");
    localStorage.removeItem("cloneServicesEnabled");
    localStorage.removeItem("cloneRedisData");
    localStorage.removeItem("cloneMongoDataSourcesData");
    localStorage.removeItem("stopFetch");
    localStorage.removeItem("cloneDeploymentData");
    localStorage.removeItem("cloneContainerRegistries");
    localStorage.removeItem("ProjectDep");
    localStorage.removeItem("clonePostgresDataSourcesData");
    localStorage.removeItem("importantFields");
    localStorage.removeItem("stopFetch");
}


export const EmailValid = (test) => {
    let invalidEmails = ["@gmail.com", "@yahoo.com", "@hotmail.com", "@outlook.com", "@live.com", "@aol.com", "@icloud.com", "@mail.com", "@yandex.com", "@zoho.com", "@gmx.com", "@inbox.com", "@fastmail.com", "@me.com", "@msn.com", "@yahoo.co.uk", "@yahoo.co.in", "@hotmail.co.uk", "@hotmail.fr", "@sbcglobal.net", "@comcast.net", "@verizon.net", "@bellsouth.net", "@att.net", "@cox.net", "@charter.net", "@optonline.net", "@earthlink.net", "@qq.com", "@naver.com", "@hanmail.net", "@nate.com", "@163.com", "@126.com", "@protonmail.com", "@tutanota.com", "@yopmail.com", "@mailinator.com", "@trashmail.com", "@10minutemail.com"];

    let emails = invalidEmails.filter((item) => test.endsWith(item));

    if (emails.length > 0) {
        return false;
    }
    else {
        return true;
    }

}

export const copyText = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        return "Copied";
    }
    catch (err) {
        console.log("Error", err);
        return "Failed";
    }
}
// Custom sorting function based on search input
export const customSortOptions = (optionA, optionB, searchValue) => {
    // Exact match should come before partial matches
    const optionAExactMatch = optionA.value === searchValue;
    const optionBExactMatch = optionB.value === searchValue;

    if (optionAExactMatch && !optionBExactMatch) {
        return -1; // optionA comes before optionB
    } else if (!optionAExactMatch && optionBExactMatch) {
        return 1; // optionB comes before optionA
    }
    else {
        // Check if either starts with the search value
        const aStartsWith = optionA.value.startsWith(searchValue.toLowerCase());
        const bStartsWith = optionB.value.startsWith(searchValue.toLowerCase());

        if (aStartsWith && !bStartsWith) {
            return -1; // a comes before b
        } else if (!aStartsWith && bStartsWith) {
            return 1; // b comes before a
        } else {
            return optionA.value.localeCompare(optionB.value); // alphabetical sorting
        }
    }
    // else {
    //   // Default alphabetical sorting if neither or both are exact matches
    //   return optionA.value.localeCompare(optionB.value);
    // }
};

// Custom filtering function based on search input
export const customFilterOption = (inputValue, option) => {
    if (isNaN(option.value)) {
        const optionValue = option.value ? option.value.toLowerCase() : "";
        return optionValue.includes(inputValue.toLowerCase());
    }
    else {
        if (typeof option.children === "string") {
            const optionValue = option.children ? option.children.toLowerCase() : "";
            return optionValue.includes(inputValue.toLowerCase());
        }
    }
};

export async function validateYaml(yamlString, clusterId, callApi, form) {
    try {
        console.log("My yaml string: ", yamlString);
        const doc = yaml.loadAll(yamlString);
        console.log("My doc: ", doc);
        if (callApi) {
            let data = await backendYamlValidate(Array.isArray(doc) ? doc.flat() : [doc], clusterId, form);
            form.setFields([{ name: "yamlData", errors: [] }]);
        }
        console.log("Returned val: ", doc);
        return Array.isArray(doc) ? doc.flat() : [doc];
    } catch (err) {
        console.log("Failed", err);
        if (form) {
            form.setFields([{
                name: "yamlData",
                errors: err ? err.error_details.yaml_content : ["Your provided yaml code is invalid."]
            }]);
        }
        throw err;
    }
}

export async function backendYamlValidate(yaml, clusterId, form) {
    console.log("My yaml: ", yaml);
    await api.post(process.env.REACT_APP_API_URI + "/api/project_env/env/yaml-validation/", {
        "cluster_unique_id": clusterId,
        "yaml_content": yaml
    }).then((res) => {
        let data = res.data;
        if (data.status) {
            return data;
        }
        else {
            if (form) {
                form.setFields([{
                    name: "yamlData",
                    errors: data.error_details.yaml_content
                }]);
            }
            throw data;
        }
    }).catch((err) => {
        if (form) {
            form.setFields([{
                name: "yamlData",
                errors: {
                    error_details: {
                        yaml_content: ["Your YAML code provided is invalid."]
                    }
                }
            }]);
        }
        throw err;
    });
}


export const yamlLinter = (view, form) => {
    const errors = [];
    console.log("Beginning yaml linting...", view.state.doc.toString());
    try {
        // console.log("My YAML: ", view.state);
        yaml.loadAll(view.state.doc.toString());
        if (form) {
            form.setFields([{ name: "yamlData", errors: [] }]);
        }
    } catch (error) {
        if (error.name === 'YAMLException') {
            console.log("Error: ", error);
            errors.push({
                from: error.mark?.position, // Position in the document
                to: error.mark?.position + 1, // Example: highlight a single character
                message: error.reason,
                severity: "error"
            });
            if (form) {
                form.setFields([{
                    name: "yamlData",
                    errors: ["Your YAML code provided is invalid."]
                }]);
            }
        }
        console.log("Error:", error);
    }
    return errors;
};

  export const workflowPipelineSettings = {
    hideAdd: true,
    hideDelete: true,
    hideStatus: true
  };

