import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertContext } from "../../../context/alertContext";
import { DeploymentContext } from "../../../context/deploymentContext";
import api from "../../../api";
import { validateYaml } from "../../../context/helper";

export function useDeploymentFeatures(keySelected, setKeySelected, services, setServices, setServiceSelected) {
    console.log("My services: ", services);
    const { fetching, setFetching, updatedData, setUpdatedData, setUpdateDep, validateYamls } = useContext(DeploymentContext);
    const { set_alertOptions } = useContext(AlertContext);
    const location = useLocation();
    let navigate = useNavigate();
    function toggleAlert(type, message, description) {
        console.log("Toggle alert called: ", type, message, description);
        set_alertOptions({
            status: true,
            type: type,
            message: message,
            description: description,
        });

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions({
                status: false,
                type: type,
                message: message,
                description: description,
            });
        }, 5 * 1000);
    }
    const redirectToNext = () => {
        let nextActiveService = "", index;
        services.forEach((val, idx) => {
            if (val.isActive && idx !== keySelected) {
                nextActiveService = val;
                index = idx;
                return;
            }
            if (idx === keySelected) {
                val.isActive = false;
                setServices([...services]);
            }
        });
        if (nextActiveService === "") {
            setUpdatedData({});
            setUpdateDep(false);
            localStorage.removeItem('updateDep');
            localStorage.removeItem('updatedData');
            navigate(`/environments/environment/${location.pathname.split('/').slice(-1)[0]}/env-overview`);
        }
        else {
            if (nextActiveService !== "") {
                setKeySelected(index);
                setServiceSelected(nextActiveService);
            }
        }
    }
    const handleApplicationRedeploy = async (appData, currentProjectDep) => {
        let allErrors = [], myErrors = {};
        let yamls = await validateYamls([appData]);

        let yamlError = false;

        yamls.forEach((val, idx) => {
            if (val && val.data && idx === val.idx) {
                appData.deployments.yamlData = [...val.data];
            }
            else if (val && appData.serviceEnabled) {
                myErrors = {
                    ...myErrors,
                    [val.serviceName]: "Your YAML code provided is invalid."
                };
                allErrors = [...allErrors, {
                    serviceSelected: val.serviceName,
                    error: "Your YAML code provided is invalid."
                }];
                yamlError = true;
            }
        });
        if (allErrors.length > 0) {
            toggleAlert("error", "Error", "Please provide correct yaml file.");
        }
        else {
            console.log("Redeploying app...", appData);
            let myConfigVol = [];
            let mySecretVol = [];
            let myStorageVol = [];
            let myEnvs = appData.environments.length > 0 ? (appData.environments.length === 1 ? (appData.environments[0].name === "" && appData.environments[0].value === "") ? [] : appData.environments : appData.environments) : appData.environments;
            let labels = {};
            appData.deployments.tags.map((val, idx) => {
                if (val.name !== "" && val.value !== "") {
                    labels[val.name] = val.value;
                }
            })
            appData.volumes.map((ele) => {
                if (ele.volumeType === "Configmap") {
                    let rawStr = ele.config.createMountPath.split('/')
                let tempFilename = rawStr.pop()  // removing filename from rawStr
                let tempMountPath = rawStr.join("/")   // join for mountPath

                    console.log("tempFilename :", tempFilename)
                    console.log("tempMountPath :", tempMountPath)

                myConfigVol.push({
                        "name": ele.config.configName,//"test-cm",
                        "filename": tempFilename,//"index.yaml",
                        "mountPath": tempMountPath,//"/app",
                        "data": ele.config.createConfig//"this the configmap data...."
                    });
                }
                else if (ele.volumeType === "Secrets") {
                    let rawStrSecrets = ele.secrets.createSecretsMountPath.split('/')
                let tempFilenameSecrets = rawStrSecrets.pop()  // removing filename from rawStrSecrets
                let tempMountPathSecrets = rawStrSecrets.join("/")   // join for mountPath

                    console.log("tempFilenameSecrets :", tempFilenameSecrets)
                    console.log("tempMountPathSecrets :", tempMountPathSecrets)

                mySecretVol.push({
                        "name": ele.secrets.secretsName,//"test-secretqs",
                        "filename": tempFilenameSecrets,//"index.yaml",
                        "mountPath": tempMountPathSecrets,//"/app",
                        "data": ele.secrets.createSecrets//"this the configmap data...."
                    });
                }
                else if (ele.volumeType === "Storage") {
                    myStorageVol.push({
                        "name": ele.storage.storageDes.storageName,//"test-secrets",
                        "size": ele.storage.storageDes.size !== "" ? ele.storage.storageDes.size : 0,//+ 'Gi' : "",//"10Gi",
                        "mountPath": ele.storage.storageDes.path,//"/app",
                        "storageClass": ele.storage.storageDes.type.toLowerCase() === "efs" ? 'efs-sc' : ele.storage.storageDes.type.toLowerCase(),//"gp2",
                        "accessMode": "ReadWriteOnce"
                    });
                }
            });
            let payload = {
                "name": appData.basicDetails.appName,
                "service_id": appData.serviceSelectedId,//serviceSelected.id,
                "cd_values_in_yaml": appData.deployments.deploymentOption === "yaml",
                "config": {
                    "app_source_type": appData.basicDetails.sourceType === "Container Registry" ? 0 : 1,
                    "docker_file": appData.basicDetails.sourceType === "Container Registry" ? undefined : './' + appData.basicDetails.dockerFilePath,
                    "build_context": appData.basicDetails.sourceType === "Container Registry" ? undefined : appData.basicDetails.buildContext && appData.basicDetails.buildContext !== "" ? "./" + appData.basicDetails.buildContext : null,
                    "container_registry_id": appData.basicDetails.appSource === "PUBLIC" ? null : appData.basicDetails.sourceType === "Container Registry" ? appData.basicDetails.appSource?.id : appData.basicDetails.containerRegistryId.id,
                    "repository_name": appData.basicDetails.imageRepo,
                    "image_tag": appData.basicDetails.imageTag && appData.basicDetails.imageTag !== "" ? appData.basicDetails.imageTag : undefined,
                    "container_registry_type": appData.basicDetails.sourceType === "Container Registry" ? appData.basicDetails.appSource === "PUBLIC" ? 0 : 1 : undefined,
                    "port": appData.deployments.deploymentOption === "visual" ? parseInt(appData.deployments.port) : undefined,
                    "create_storage": appData.deployments.deploymentOption === "visual" ? myStorageVol.length > 0 ? "true" : "false" : undefined,
                    "create_secret": appData.deployments.deploymentOption === "visual" ? mySecretVol.length > 0 ? "true" : "false" : undefined,
                    "ingress_enabled": appData.deployments.deploymentOption === "visual" ? appData.deployments.serviceType.includes("Existing Load Balancer") ? "true" : "false" : undefined,//val.deployments.customDomain === true ? "true" : "false",
                    "ingress_https_enabled": appData.deployments.deploymentOption === "visual" ? "true" : undefined,
                    "ingress_host": appData.deployments.deploymentOption === "visual" ? appData.deployments.domainHostUrl === "" ? "" : appData.deployments.domainHostUrl + '.' + updatedData.deploymentData.dns : undefined,
                    "ingress_path": appData.deployments.deploymentOption === "visual" ? appData.deployments.gatewayHttps.gPath === "" ? "/" : appData.deployments.gatewayHttps.gPath : undefined,
                    "env_variables": myEnvs,
                    "record_prefix": appData.deployments.deploymentOption === "visual" ? appData.deployments.serviceType.includes("Existing Load Balancer") ? appData.deployments.domainHostUrl : "" : undefined,
                    "service_type": appData.deployments.deploymentOption === "visual" ? (appData.deployments.endpointType === "Cluster IP" || appData.deployments.serviceType.includes("Existing Load Balancer")) ? "ClusterIP" : !appData.deployments.endpointType ? "" : "LoadBalancer" : undefined,//serviceSelected.type,
                    "service_protocol": appData.deployments.deploymentOption === "visual" ? "TCP" : undefined,
                    "enable_plaintext_env": myEnvs.length > 0 ? "true" : "false",
                    "enable_secret_env": myEnvs.length > 0 ? "true" : "false",
                    "vcs_branch": appData.basicDetails.branch === "" ? null : appData.basicDetails.branch,
                    "secretfile_enabled": appData.deployments.deploymentOption === "visual" ? mySecretVol.length > 0 ? "true" : "false" : undefined,
                    "create_configmap": appData.deployments.deploymentOption === "visual" ? myConfigVol.length > 0 ? "true" : "false" : undefined,
                    "cpu_request": appData.deployments.deploymentOption === "visual" ? parseInt(appData.deployments.resources.cpu.requestCpu) : undefined, //+ 'm',
                    "cpu_limit": appData.deployments.deploymentOption === "visual" ? parseInt(appData.deployments.resources.cpu.limitCpu) : undefined, //+ 'm',
                    "memory_request": appData.deployments.deploymentOption === "visual" ? parseInt(appData.deployments.resources.memory.requestMem) : undefined, //+ 'Mi',
                    "memory_limit": appData.deployments.deploymentOption === "visual" ? parseInt(appData.deployments.resources.memory.limitMem) : undefined, //+ 'Mi',
                    "target_cpu_utilization": appData.deployments.deploymentOption === "visual" ? appData.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto === "" ? 0 : parseInt(appData.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto) : undefined, //+ '%',
                    "target_memory_utilization": appData.deployments.deploymentOption === "visual" ? appData.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto === "" ? 0 : parseInt(appData.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto) : undefined, //+ '%',
                    "enable_autoscaling": appData.deployments.deploymentOption === "visual" ? appData.deployments.autoScaling.enabled === false ? "false" : "true" : undefined,
                    "maximum_replicas": appData.deployments.deploymentOption === "visual" ? appData.deployments.autoScaling.autoScalingData.maximumReplica : undefined,
                    "minimum_replicas": appData.deployments.deploymentOption === "visual" ? appData.deployments.autoScaling.autoScalingData.minimumReplica : undefined,
                    "config_files_config": appData.deployments.deploymentOption === "visual" ? myConfigVol === 1 ? myConfigVol[0].name === "" ? [] : myConfigVol : myConfigVol : undefined,
                    "storages_config": appData.deployments.deploymentOption === "visual" ? myStorageVol.length === 1 ? myStorageVol[0].name === "" ? [] : myStorageVol : myStorageVol : undefined,
                    "secret_files_config": appData.deployments.deploymentOption === "visual" ? mySecretVol.length === 1 ? mySecretVol[0].name === "" ? [] : mySecretVol : mySecretVol : undefined,
                    "labels": labels,
                    "health_check_path": appData.deployments.deploymentOption === "visual" ? appData.deployments.healthCheckPath : undefined,
                    "auto_trigger": appData.basicDetails.enableAutoTrigger?.toString(),
                    "create_cron": appData.deployments.deploymentOption === "visual" ? appData.deployments.taskSchedulers.crons ? "true" : "false" : undefined,
                    "create_queue_worker": appData.deployments.deploymentOption === "visual" ? appData.deployments.taskSchedulers.queuesAndWorkers ? "true" : "false" : undefined,
                    "cron_command": appData.deployments.deploymentOption === "visual" ? appData.deployments.taskSchedulers.entryPointCommandCrons : undefined,
                    "queue_worker_command": appData.deployments.deploymentOption === "visual" ? appData.deployments.taskSchedulers.entryPointCommandQueues : undefined,
                    "pass_env_vars_at_build_time": currentProjectDep.tasks.find(x => x.serviceId === appData.serviceSelectedId).envs.some(x => x.injectAt.includes("Build Time") && x.lastAction !== "Delete").toString(),
                    "nodegroup_name": appData.deployments.deploymentOption === "visual" ? appData.deployments.nodeGroupName : undefined,
                    "nodegroup_type": appData.deployments.deploymentOption === "visual" ? appData.deployments.nodeGroupType : undefined,
                    "ingress_alb_enabled": appData.deployments.deploymentOption === "visual" ? appData.deployments.serviceType === "Existing Load Balancer(ALB Ingress)" ? "true" : "false" : undefined,//deploymentData.ingressAlbEnabled.toString(),
                    "list_of_nodegroups": appData.deployments.deploymentOption === "visual" ? appData.deployments.listOfNodegroups : undefined,
                    "yaml_content": appData.deployments.deploymentOption === "yaml" ? appData.deployments.yamlData : undefined,
                    "yaml_kind": appData.deployments.deploymentOption === "yaml" ? appData.deployments.yamlType : undefined
                },
            };
            let envId = location.pathname.split('/').slice(-1)[0];
            setFetching(true);
            console.log("Final payload: ", payload);
            api.post(`/api/project_env/update_service/${envId}/${appData.serviceSelectedId}`, payload).then((res) => {
                let data = res.data;
                setFetching(false);
                if (data.status) {
                    toggleAlert("success", "Success", data.message);
                    // setUpdatedData({});
                    // setUpdateDep(false);
                    // localStorage.removeItem('updateDep');
                    // localStorage.removeItem('updatedData');
                    redirectToNext(services);
                    //navigate(`/environments/environment/${envId}`);
                    setFetching(false);
                }
                else {
                    toggleAlert("error", "Failed", data.message);
                    setFetching(false);
                }
            }).catch((err) => {
                toggleAlert("error", "Failed", "Something went wrong. Please try again.");
                setFetching(false);
            });
        }
    }

    const handleMysqlRedeploy = (dataSources) => {
        let payload = {};
        if (dataSources.type === "AWS Managed MySQL" || dataSources.type === "AWS Aurora Managed MySQL") {
            payload = {
                "name": dataSources.displayName,//"mysql",
                "service_id": dataSources.serviceSelectedId,//40,
                "config": {
                    "db_type": dataSources.type === "AWS Managed MySQL" ? "aws-rds-managed-mysql" : "aws-aurora-managed-mysql",
                    "TF_VAR_mysql_instance_class": dataSources.cloudManaged.instanceType,//"db.t3.micro" , 
                    "Instance_family": dataSources.cloudManaged.instanceFamily,
                    "TF_VAR_mysql_engine_version": dataSources.version,
                    "tags": dataSources.cloudManaged.tags.length === 1 ? (dataSources.cloudManaged.tags[0].name === "" || dataSources.cloudManaged.tags[0].value === "") ? [] : dataSources.cloudManaged.tags : dataSources.cloudManaged.tags,
                    "TF_VAR_database_name": dataSources.cloudManaged.databaseName,//"nodeapp",        
                    "TF_VAR_enable_multi_az": dataSources.cloudManaged.dbType === "Single DB Instance" ? "false" : "true", //"false",
                    "TF_VAR_master_username": dataSources.cloudManaged.username,//"abhi",
                    "TF_VAR_storage_size": parseInt(dataSources.cloudManaged.storageSize),//"30",       
                    "TF_VAR_enable_public_access": dataSources.cloudManaged.enablePublicAccess ? "true" : "false",//"true",
                    "TF_VAR_skip_final_snapshot": "true",
                    "TF_VAR_backup_window": (dataSources.cloudManaged.backupTimingStart === "" || dataSources.cloudManaged.backupTimingEnd === "") ? "" : dataSources.cloudManaged.backupTimingStart + '-' + dataSources.cloudManaged.backupTimingEnd,//"03:00-06:00",
                    "TF_VAR_backup_region": dataSources.cloudManaged.backupRegion,
                    "TF_VAR_maintenance_window": (dataSources.cloudManaged.maintenanceWindowStart === "" || dataSources.cloudManaged.maintenanceWindowEnd === "") ? "" : dataSources.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + dataSources.cloudManaged.maintenanceWindowStart + '-' + dataSources.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + dataSources.cloudManaged.maintenanceWindowEnd,//"Mon:00:00-Mon:03:00",
                    "TF_VAR_enable_deletion_protection": dataSources.cloudManaged.deletionProtection ? "true" : "false",//"false",
                    "TF_VAR_backup_retention_period": dataSources.cloudManaged.backupRetention === "" ? "0" : dataSources.cloudManaged.backupRetention,//"7",
                    "TF_VAR_enable_general_log": dataSources.cloudManaged.enableGeneralLogs ? "true" : "false",//"true",
                    "TF_VAR_enable_slow_query_log": dataSources.cloudManaged.enableSlowLogs ? "true" : "false",//"true",
                    "TF_VAR_create_random_password": dataSources.cloudManaged.autoGenPassword ? "true" : "false",//"true"
                    "TF_VAR_master_password": dataSources.cloudManaged.password,
                    "TF_VAR_log_retention_period": dataSources.cloudManaged.logRetention,
                    //"TF_VAR_enable_backup": dataSources.cloudManaged.enableBackup ? "true" : 'false'
                    "private_subnet_ids": dataSources.cloudManaged.subnetIds.privateSubnets,
                    "public_subnet_ids": dataSources.cloudManaged.subnetIds.publicSubnets,
                }
            }
        }
        else {
            let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            payload = {
                "name": dataSources.displayName,
                "service_id": dataSources.serviceSelectedId,//3,
                "config": {
                    "db_type": "self-managed-mysql",
                    //"TF_VAR_architecture": val.selfManaged.dbType,//"standalone",
                    "TF_VAR_storage_class_name": dataSources.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : dataSources.selfManaged.storageType.toLowerCase(),//"gp2",
                    "TF_VAR_username": dataSources.selfManaged.username,//"admin",
                    "TF_VAR_master_db_volume_size": dataSources.selfManaged.storageSize === "" ? "" : parseInt(dataSources.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                    "TF_VAR_secondary_db_volume_size": dataSources.selfManaged.storageSize === "" ? "" : parseInt(dataSources.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                    "TF_VAR_secondary_db_replica_count": dataSources.selfManaged.dbType,//"1",
                    "TF_VAR_backup_enabled": dataSources.selfManaged.enableBackup ? "true" : "false",//"false",
                    "TF_VAR_bucket_uri": "",
                    "enable_cross_region_backup": dataSources.selfManaged.enableCrossRegionBackup.toString(),
                    "TF_VAR_bucket_region": dataSources.selfManaged.crossRegionBackup,//"",
                    //"TF_VAR_backup_cron": "* * * * *",
                    "TF_VAR_restore_db": "false",
                    "TF_VAR_exporter_enable": dataSources.selfManaged.enableMetrics ? "true" : "false",//"true",
                    "TF_VAR_app_version": dataSources.version,
                    "TF_VAR_custom_credentials_enabled": !dataSources.selfManaged.autoGenPassword ? "true" : "false",//"true",
                    "TF_VAR_store_password_to_secret_manager": "false",
                    "TF_VAR_custom_user_password": dataSources.selfManaged.password,//"password",
                    "backup_frequency": dataSources.selfManaged.backupFrequency.toLowerCase(),//"hourly",
                    "backup_interval": dataSources.selfManaged.backupFrequency === "Weekly" ? days.indexOf(dataSources.selfManaged.backupDay) : dataSources.selfManaged.backupFrequency !== "Hourly" ? 1 : dataSources.selfManaged.backupInterval,//3,
                    "backup_time": dataSources.selfManaged.backupTiming,
                    "TF_VAR_database_name": dataSources.selfManaged.databaseName,
                    "TF_VAR_restore_file_name": "",
                    "list_of_nodegroups": dataSources.selfManaged.listOfNodegroups,
                    "TF_VAR_restore_db": dataSources.selfManaged.enableRestore ? "true" : "false",
                }
            };
        }
        let envId = location.pathname.split('/').slice(-1)[0];
        setFetching(true);
        api.post(`/api/project_env/update_service/${envId}/${dataSources.serviceSelectedId}`, payload).then((res) => {
            let data = res.data;
            if (data.status) {
                setFetching(false);
                toggleAlert("success", "Success", data.message);
                // setUpdatedData({});
                // setUpdateDep(false);
                // localStorage.removeItem('updateDep');
                // localStorage.removeItem('updatedData');
                redirectToNext();
                //navigate(`/environments/environment/${envId}`);
            }
            else {
                toggleAlert("error", "Failed", data.message);
                setFetching(false);
            }
        }).catch((err) => {
            toggleAlert("error", "Failed", "Something went wrong. Please try again.");
            setFetching(false);
        });
    }

    const handleMongoRedeploy = (dataSources) => {
        let payload = {};
        if (dataSources.type === "Self Managed") {
            let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            payload = {
                "name": dataSources.displayName,//"dskldss",
                "service_id": dataSources.serviceSelectedId,//6,
                "type": "self-managed-mongodb",
                "config": {
                    "db_type": "self-managed-mongodb",
                    //"TF_VAR_architecture": val.selfManaged.dbType.toLowerCase(),//"standalone",
                    "TF_VAR_storage_class_name": dataSources.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : dataSources.selfManaged.storageType.toLowerCase(),                            //"TF_VAR_username": val.selfManaged.username,//"admin",
                    "TF_VAR_volume_size": dataSources.selfManaged.storageSize === "" ? "" : parseInt(dataSources.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                    //"TF_VAR_secondary_db_volume_size": val.selfManaged.storageSize === "" ? "" : val.selfManaged.storageSize + 'Gi',//"10Gi",
                    "TF_VAR_replica_count": dataSources.selfManaged.dbType === "" ? "" : dataSources.selfManaged.dbType,
                    //"TF_VAR_replica_count": "1",
                    "TF_VAR_backup_enabled": dataSources.selfManaged.enableBackup ? "true" : "false",//"false", //TF_VAR_backup_enable
                    "TF_VAR_bucket_uri": "",
                    "enable_cross_region_backup": dataSources.selfManaged.enableCrossRegionBackup.toString(),
                    "TF_VAR_bucket_region": dataSources.selfManaged.crossRegionBackup,//"", //TF_VAR_bucket_region
                    "TF_VAR_restore_db": "false",
                    "TF_VAR_exporter_enable": dataSources.selfManaged.enableMetrics ? "true" : "false",//"true",
                    //"TF_VAR_mongodb_version": "",//"5.0.8-debian-10-r9", //later
                    "TF_VAR_app_version": dataSources.version,
                    "TF_VAR_custom_credentials_enabled": !dataSources.selfManaged.autoGenPassword ? "true" : "false",//"true",
                    "TF_VAR_store_password_to_secret_manager": "false",
                    //"TF_VAR_root_password": val.selfManaged.password,//"password",
                    "backup_frequency": dataSources.selfManaged.backupFrequency.toLowerCase(),//"hourly",
                    "backup_interval": dataSources.selfManaged.backupFrequency === "Weekly" ? days.indexOf(dataSources.selfManaged.backupDay) : dataSources.selfManaged.backupFrequency !== "Hourly" ? 1 : dataSources.selfManaged.backupInterval,//3,
                    "backup_time": dataSources.selfManaged.backupTiming,
                    "custom_database_name": dataSources.selfManaged.databaseName,
                    "custom_database_password": dataSources.selfManaged.password,
                    "custom_database_username": dataSources.selfManaged.username,//"abhi"
                    "list_of_nodegroups": dataSources.selfManaged.listOfNodegroups,
                    "TF_VAR_restore_db": dataSources.selfManaged.enableRestore ? "true" : "false",
                }
            }
        }
        else {

        }
        let envId = location.pathname.split('/').slice(-1)[0];
        setFetching(true);
        api.post(`/api/project_env/update_service/${envId}/${dataSources.serviceSelectedId}`, payload).then((res) => {
            let data = res.data;
            setFetching(false);
            if (data.status) {
                toggleAlert("success", "Success", data.message);
                // setUpdatedData({});
                // setUpdateDep(false);
                // localStorage.removeItem('updateDep');
                // localStorage.removeItem('updatedData');
                //navigate(`/environments/environment/${envId}`);
                redirectToNext();
            }
            else {
                toggleAlert("error", "Failed", data.message);
                setFetching(false);
            }
        }).catch((err) => {
            toggleAlert("error", "Failed", "Something went wrong. Please try again.");
            setFetching(false);
        });
    }

    const handleRedisRedeploy = (redisServiceData) => {
        let payload = {};
        if (redisServiceData.type === "AWS Managed Redis") {
            payload = {
                "name": redisServiceData.displayName,
                "service_id": redisServiceData.serviceSelectedId,
                //"type": "elasticache-redis",
                "config": {
                    "db_type": "elasticache-redis",
                    "TF_VAR_node_type": redisServiceData.cloudManaged.instanceType,
                    "Instance_family": redisServiceData.cloudManaged.instanceFamily,
                    //"TF_VAR_family": "redis6.x",//will append the version to redis
                    "TF_VAR_num_cache_nodes": redisServiceData.cloudManaged.noOfReplica,//"",
                    "TF_VAR_multi_az_enabled": redisServiceData.cloudManaged.enableMultiAz ? "true" : "false",
                    "TF_VAR_automatic_failover_enabled": redisServiceData.cloudManaged.enableAutomaticFailover ? "true" : "false",
                    "TF_VAR_at_rest_encryption_enabled": redisServiceData.cloudManaged.enableRestEncryption ? "true" : "false",
                    "TF_VAR_snapshot_retention_limit": redisServiceData.cloudManaged.backupRetention === "" ? "0" : redisServiceData.cloudManaged.backupRetention,//val.cloudManaged.backupRetention ? "true" : "false",
                    "TF_VAR_transit_encryption_enabled": redisServiceData.cloudManaged.enableTransitEncryption ? "true" : "false",
                    "TF_VAR_snapshot_window": (redisServiceData.cloudManaged.backupTimingStart === "" || redisServiceData.cloudManaged.backupTimingEnd === "") ? "" : redisServiceData.cloudManaged.backupTimingStart + '-' + redisServiceData.cloudManaged.backupTimingEnd,
                    "TF_VAR_maintenance_window": (redisServiceData.cloudManaged.maintenanceWindowStart === "" || redisServiceData.cloudManaged.maintenanceWindowEnd === "") ? "" : redisServiceData.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + redisServiceData.cloudManaged.maintenanceWindowStart + '-' + redisServiceData.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + redisServiceData.cloudManaged.maintenanceWindowEnd,
                    "TF_VAR_engine_version": redisServiceData.version,
                    "private_subnet_ids": redisServiceData.cloudManaged.subnetIds.privateSubnets,
                    "public_subnet_ids": redisServiceData.cloudManaged.subnetIds.publicSubnets,
                }
            }
        }
        else {
            payload = {
                "name": redisServiceData.displayName,//"mks3",
                "service_id": redisServiceData.serviceSelectedId,//5,
                //"type": "self-managed-redis",
                "config": {
                    "db_type": "self-managed-redis",
                    //"TF_VAR_architecture": "replication", //will add later
                    //"TF_VAR_replicacount": "1", //will add later
                    "TF_VAR_storage_class_name": redisServiceData.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : redisServiceData.selfManaged.storageType.toLowerCase(),//"gp2",
                    //"TF_VAR_enable_metrics": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                    "TF_VAR_slave_volume_size": redisServiceData.selfManaged.storageSize === "" ? "" : parseInt(redisServiceData.selfManaged.storageSize), //+ "Gi",//"10Gi",
                    "TF_VAR_master_volume_size": redisServiceData.selfManaged.storageSize === "" ? "" : parseInt(redisServiceData.selfManaged.storageSize), //+ "Gi",//"10Gi",
                    "TF_VAR_slave_replica_count": redisServiceData.selfManaged.noOfReplica,//"2", //will add later
                    //"TF_VAR_monitoring_enabled": "true", //will be added later
                    "TF_VAR_app_version": redisServiceData.version,//"6.2.7-debian-11-r11", //version list update required
                    "TF_VAR_store_password_to_secret_manager": "false",
                    "TF_VAR_custom_credentials_enabled": redisServiceData.selfManaged.authEnabled ? redisServiceData.selfManaged.autoGenPassword ? "false" : "true" : undefined,
                    "TF_VAR_password": redisServiceData.selfManaged.authEnabled ? redisServiceData.selfManaged.password : undefined,
                    "TF_VAR_grafana_monitoring_enabled": redisServiceData.selfManaged.enableMetrics ? "true" : "false",
                    "list_of_nodegroups": redisServiceData.selfManaged.listOfNodegroups,
                    "auth_enabled": redisServiceData.selfManaged.authEnabled ? redisServiceData.selfManaged.authEnabled.toString() : "false"
                }
            };
        }
        let envId = location.pathname.split('/').slice(-1)[0];
        setFetching(true);
        api.post(`/api/project_env/update_service/${envId}/${redisServiceData.serviceSelectedId}`, payload).then((res) => {
            let data = res.data;
            if (data.status) {
                setFetching(false);
                toggleAlert("success", "Success", data.message);
                // setUpdatedData({});
                // setUpdateDep(false);
                // localStorage.removeItem('updateDep');
                // localStorage.removeItem('updatedData');
                redirectToNext();
                //navigate(`/environments/environment/${envId}`);
            }
            else {
                toggleAlert("error", "Failed", data.message);
                setFetching(false);
            }
        }).catch((err) => {
            toggleAlert("error", "Failed", "Something went wrong. Please try again.");
            setFetching(false);
        });
    }

    const handlePostgresRedeploy = (dataSources) => {
        let payload = {};
        if (dataSources.type === "Self Managed") {
            let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            payload = {
                "name": dataSources.displayName,//"mks2",
                "service_id": dataSources.serviceSelectedId,//69,
                "config": {
                    "db_type": "self-managed-postgresql",
                    "TF_VAR_postgresql_exporter_enabled": dataSources.selfManaged.enableExporter ? "true" : "false",//"false",
                    "TF_VAR_replicacount": dataSources.selfManaged.dbType,//"1",
                    "TF_VAR_storage_class_name": dataSources.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : dataSources.selfManaged.storageType.toLowerCase(),//"gp2",
                    "TF_VAR_custom_credentials_enabled": dataSources.selfManaged.autoGenPassword ? "false" : "true",//"false",
                    "TF_VAR_postgresql_password": dataSources.selfManaged.password, //"",
                    "TF_VAR_app_version": dataSources.version,//"16.0.0",
                    "TF_VAR_database_name": dataSources.selfManaged.databaseName,//"atmosly",
                    "TF_VAR_storage_size": !dataSources.selfManaged.storageSize ? "" : parseInt(dataSources.selfManaged.storageSize),//"10Gi"
                    "TF_VAR_postgresql_username": dataSources.selfManaged.username,
                    "TF_VAR_backup_enabled": dataSources.selfManaged.enableBackup ? "true" : "false",
                    "backup_frequency": dataSources.selfManaged.backupFrequency.toLowerCase(),
                    "backup_interval": dataSources.selfManaged.backupFrequency === "Weekly" ? days.indexOf(dataSources.selfManaged.backupDay) : dataSources.selfManaged.backupFrequency !== "Hourly" ? 1 : dataSources.selfManaged.backupInterval,
                    "backup_time": dataSources.selfManaged.backupTiming,
                    "enable_cross_region_backup": dataSources.selfManaged.enableCrossRegionBackup.toString(),
                    "TF_VAR_bucket_region": dataSources.selfManaged.crossRegionBackup,
                    "list_of_nodegroups": dataSources.selfManaged.listOfNodegroups,
                    "TF_VAR_restore_db": dataSources.selfManaged.enableRestore ? "true" : "false",
                }
            };
        }
        else {
            payload = {
                "name": dataSources.displayName,//"mysql",
                "service_id": dataSources.serviceSelectedId,//40,
                "config": {
                    "db_type": dataSources.type === "AWS Managed Postgres" ? "aws-rds-managed-postgres" : "aws-aurora-managed-postgres",
                    "TF_VAR_instance_type": dataSources.cloudManaged.instanceType,//"db.t3.micro" , 
                    "Instance_family": dataSources.cloudManaged.instanceFamily,
                    "TF_VAR_engine_version": dataSources.version,
                    "tags": dataSources.cloudManaged.tags.length === 1 ? (dataSources.cloudManaged.tags[0].name === "" || dataSources.cloudManaged.tags[0].value === "") ? [] : dataSources.cloudManaged.tags : dataSources.cloudManaged.tags,
                    "TF_VAR_database_name": dataSources.cloudManaged.databaseName,//"nodeapp",        
                    "TF_VAR_enable_multi_az": dataSources.cloudManaged.dbType === "Single DB Instance" ? "false" : "true", //"false",
                    "TF_VAR_master_username": dataSources.cloudManaged.username,//"abhi",
                    "TF_VAR_storage_size": parseInt(dataSources.cloudManaged.storageSize),//"30",       
                    "TF_VAR_enable_public_access": dataSources.cloudManaged.enablePublicAccess ? "true" : "false",//"true",
                    "TF_VAR_skip_final_snapshot": "true",
                    "TF_VAR_backup_window": dataSources.type === "AWS Aurora Managed Postgres" || dataSources.type === "AWS Managed Postgres" ? (dataSources.cloudManaged.backupTimingStart === "" || dataSources.cloudManaged.backupTimingEnd === "") ? "" : dataSources.cloudManaged.backupTimingStart + '-' + dataSources.cloudManaged.backupTimingEnd:
                    (dataSources.cloudManaged.backupTimingStart === "" || dataSources.cloudManaged.backupTimingEnd === "") ? "" : dataSources.cloudManaged.backupTimingStart + '-' + dataSources.cloudManaged.backupTimingEnd,//"03:00-06:00",
                    "TF_VAR_maintenance_window": (dataSources.cloudManaged.maintenanceWindowStart === "" || dataSources.cloudManaged.maintenanceWindowEnd === "") ? "" : dataSources.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + dataSources.cloudManaged.maintenanceWindowStart + '-' + dataSources.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + dataSources.cloudManaged.maintenanceWindowEnd,//"Mon:00:00-Mon:03:00",
                    "TF_VAR_enable_deletion_protection": dataSources.cloudManaged.deletionProtection ? "true" : "false",//"false",
                    "TF_VAR_backup_retention_period": dataSources.cloudManaged.backupRetention === "" ? "0" : dataSources.cloudManaged.backupRetention,//"7",
                    "TF_VAR_enable_general_log": dataSources.cloudManaged.enableGeneralLogs ? "true" : "false",//"true",
                    "TF_VAR_enable_slow_query_log": dataSources.cloudManaged.enableSlowLogs ? "true" : "false",//"true",
                    "TF_VAR_create_random_password": dataSources.cloudManaged.autoGenPassword ? "true" : "false",//"true"
                    "TF_VAR_master_password": dataSources.cloudManaged.password,
                    "TF_VAR_log_retention_period": dataSources.cloudManaged.logRetention,
                    //"TF_VAR_enable_backup": dataSources.cloudManaged.enableBackup ? "true" : 'false'
                    "private_subnet_ids": dataSources.cloudManaged.subnetIds.privateSubnets,
                    "public_subnet_ids": dataSources.cloudManaged.subnetIds.publicSubnets,
                }
            }
        }
        let envId = location.pathname.split('/').slice(-1)[0];
        setFetching(true);
        api.post(`/api/project_env/update_service/${envId}/${dataSources.serviceSelectedId}`, payload).then((res) => {
            setFetching(false);
            let data = res.data;
            if (data.status) {
                toggleAlert("success", "Success", data.message);
                // setUpdatedData({});
                // setUpdateDep(false);
                // localStorage.removeItem('updateDep');
                // localStorage.removeItem('updatedData');
                //navigate(`/environments/environment/${envId}`);
                redirectToNext();
            }
            else {
                toggleAlert("error", "Failed", data.message);
            }
        }).catch((err) => {
            toggleAlert("error", "Failed", "Something went wrong. Please try again.");
            setFetching(false);
        });
    }

    return { handleApplicationRedeploy, handleMysqlRedeploy, handleMongoRedeploy, handleRedisRedeploy, handlePostgresRedeploy, toggleAlert };
}