import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Input, Spin } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import parse from "html-react-parser";
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import blueTriangleWorkflow from '@iso/assets/images/blueTriangleWorkflow.svg';
import awsLogo from '../../../assets/images/aws.svg';
import { formatErrors, marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import dragIcon from '@iso/assets/images/drag-icon.svg';
import deleteIcon from '@iso/assets/images/deleteIcon.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import ScrollableFeed from 'react-scrollable-feed';
import CodeViewGeneral from '../../Clusters/Task/CodeViewGeneral';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import {
    SelectOption as Option,
} from '@iso/components/uielements/select';

const noDataTitle = {
    color: "#A6A6A6",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
};

const noDataSubTitle = {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "5px"
};

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let innerSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
}

const blueTriangleStyle = {
    position: "absolute",
    right: "2.5%",
    top: "40%",
}

const widgetStyle = {
    margin: "40px 20px",
    width: "inherit",
};

const inputStyle = {
    height: "44px"
}

let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

let failureButtonsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 9px",
    padding: "10px 57px",
    borderRadius: "8px",
    border: "solid 1px #132f44",
}


export default ({
    data,
    openDrawer,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, workflowNodeData, setWorkflowNodeData, workflowEdgesData, setWorkflowEdgesData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();

    const [toolsData, setToolsData] = useState({
        toolName: "",
        confName: "",
        img: "",
        env: "",
        envId: "",
        buildType: "",
        description: "Click to configure",
        onTop: true,
        order: 0,
    });
    const [toolSelected, setToolSelected] = useState(0);
    const [envDropdown, setEnvDropdown] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [envList, setEnvList] = useState([]);
    const [autoTriggerOptions, setAutoTriggerOptions] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(true);
    // const [fetching, setFetching] = useState(true);
    const [allTools, setAllTools] = useState([
        {
            toolName: "Deploy",
            confName: "Environment",
            img: "",
            env: "",
            envId: "",
            buildType: "",
            description: "Click to configure",
            onTop: true,
            order: 0
        },
        // { 
        //     toolName: "Trivy",
        //     img: "",
        //     description: "Trivy is a comprehensive security scanner for vulnerabilities in container images, file systems, and Git repositories. It identifies potential security risks by detecting known vulnerabilities and misconfigurations, ensuring that the deployed application is secure and compliant with industry standards.",
        //     maxCriticalVulnerability: "",
        //     maxHighVulnerability: "",
        //     onTop: true,
        //     order: 0
        // },
    ]);


    useEffect(() => {
        console.log("currentWorkflowData in DeployToolsRightDrawer :", currentWorkflowData);
        let query = `?service_id=${currentWorkflowData?.serviceId}`;
        if (currentWorkflowData?.deployConfig?.config?.env_unique_id){
            query = query + `&env_unique_id=${currentWorkflowData.deployConfig.config.env_unique_id}`
        }
        if ([true, false].includes(currentWorkflowData?.pass_env_vars_at_build_time)){
            query = query + `&env_var_at_build_time=${currentWorkflowData?.pass_env_vars_at_build_time}`
        }

        if(!currentWorkflowData?.fromPipeline){
            // api.get(process.env.REACT_APP_API_URI + '/api/project_env/env/create/')
            api.get(process.env.REACT_APP_API_URI + `/api/project_env/env/create/${query}`)
                .then((res) => {
                    let data = res.data;
                    console.log("env list :", data);
                    if (data.status) {
                        let myEnvs = [];
    
                        let arr = data.data.sort((a, b) => (a.name.localeCompare(b.name)));
    
                        let listOfEnvs = currentWorkflowData.deployConfig.tools.map((item) => item.envId);
    
                        if (currentWorkflowData?.deployConfig?.config?.env_unique_id) {
                            listOfEnvs.push(currentWorkflowData.deployConfig.config.env_unique_id);
                        }
                        console.log("listOfEnvs :", listOfEnvs);
    
                        arr.map((temp, idx) => {
                            // if ((temp.phase === 1 || temp.phase === 2)) {
                            if ([0, 1, 2, 3, 8].includes(temp.phase) && !listOfEnvs.includes(temp.env_unique_id)) {
                                myEnvs.push(<Option key={idx} name="env" data-id={temp.env_unique_id} value={temp.name}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                        <div>
                                            <img src={temp.account_logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="icon" />
                                            <span>{temp.name}</span>
                                        </div>
                                        {/* <span>{temp.cluster_type}</span> */}
                                    </div>
                                </Option>)
                            }
                        });
    
                        setEnvDropdown(myEnvs);
                        setEnvList(data.data);
                        setFetching(false);
                    }
                    else if (!data.status) {
                        let err = formatErrors(data.error_details);
                        console.log("err in project_env/create :", err);
                        setFetching(false);
                    }
                }).catch((err) => {
                    console.log("err :", err);
                    setFetching(false);
                });
        }
    }, []);

    useEffect(() => {
        console.log("toolsData in deployToolRightDrawer :", toolsData);
        console.log("selectedIndex :", selectedIndex);
        console.log("currentWorkflowData :", currentWorkflowData);
        // allTools[selectedIndex].onTop = true;
        // setAllTools([...allTools]);

        if (currentWorkflowData && currentWorkflowData.deployConfig.tools.length > 0 && currentWorkflowData.deployConfig.tools.length < 2) {
            let tempAllTools = [];
            currentWorkflowData.deployConfig.tools.map((temp, ind) => {
                allTools.map((item, idx) => {
                    if (selectedIndex === idx) {
                        if (item.toolName === temp.toolName) {
                            tempAllTools.push({ ...temp });
                        }
                        else {
                            item.onTop = true;
                            tempAllTools.push({ ...item });
                        }
                    }
                    else {
                        if (item.toolName === temp.toolName) {
                            tempAllTools.push({ ...temp });
                        }
                        else {
                            tempAllTools.push({ ...item });
                        }
                    }

                    // if(item.toolName != temp.toolName){
                    //     if(selectedIndex === idx){
                    //         item.onTop = true;
                    //         tempAllTools.push(item);
                    //     }
                    // }
                    // else{
                    //     tempAllTools.push(temp);
                    // }
                });
            });
            console.log("tempAllTools :", tempAllTools);
            setAllTools([...tempAllTools]);
            form.setFieldsValue({
                toolName: "",
                confName: "",
                img: "",
                env: "",
                envId: "",
                buildType: "",
                description: "Click to configure",
                onTop: true,
                order: 0,
            });
        }
        else if (currentWorkflowData && currentWorkflowData.deployConfig.tools.length === 0) {
            allTools[selectedIndex].onTop = true;
            // currentWorkflowData.deployConfig.tools = allTools;
            // allTools[selectedIndex].env = currentWorkflowData.deployConfig.config.env_name;
            // allTools[selectedIndex].envId = currentWorkflowData.deployConfig.config.env_unique_id;
            // allTools[selectedIndex].buildType = currentWorkflowData.deployConfig.config.build_type;

            setAllTools([...allTools]);
            form.setFieldsValue({
                ...allTools[selectedIndex]
            });
        }
        else {
            // setAllTools([]);
        }
    }, []);

    // creating Build Type options
    useEffect(() => {
        let tempAutoTriggerDropdown = [];

        ["Automatic", "Manual"].forEach((child, ind) => {
            tempAutoTriggerDropdown.push(
                <Option key={child} name="buildType" data-id="" data-region={null} value={child}>
                    <div style={{ width: "100%", display: "flex" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });
        
        setAutoTriggerOptions(tempAutoTriggerDropdown);
    }, []);


    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }

    const handleSaveDetails = async (e) => {
        console.log("clicked handleSaveDetails :", allTools);
        console.log("clicked handleSaveDetails toolsData :", toolsData);
        try {
            const values = await form.validateFields();
            console.log('Success:', values);

            let tempSelectedTools = allTools.filter((temp) => temp.onTop);

            // set env in tempSelectedTools
            tempSelectedTools[0]["env"] = toolsData.env;
            tempSelectedTools[0]["envId"] = toolsData.envId;
            tempSelectedTools[0]["buildType"] = toolsData.buildType ? toolsData.buildType : "";
            console.log("tempSelectedTools in handleSaveDetails :", tempSelectedTools);


            setWorkflowData((prevData) => ({
                ...prevData,
                postDeployToolsData: tempSelectedTools,
            }));

            currentWorkflowData.deployConfig.tools = [...currentWorkflowData.deployConfig.tools, ...tempSelectedTools];
            setCurrentWorkflowData({ ...currentWorkflowData });
            localStorage.setItem("Workflow", JSON.stringify({ ...currentWorkflowData }));

            // setWorkflowNodeData((prevData) => ({
            //     ...prevData,
            //     postDeployToolsData: tempSelectedTools,
            // }));

            handelClick();

        }
        catch (err) {
            console.log("Found error :", err);
        }
    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelClick();
    }

    const handelInputChange = (e) => {
        console.log("clicked handelInputChange :", e.target.value);
        // set value
        allTools[toolSelected][e.target.name] = e.target.value;
        console.log("inside handleChecked allTools :", allTools);

        let tempSelectedTools = allTools.filter((temp) => temp.onTop);
        console.log("inside handleChecked tempSelectedTools :", tempSelectedTools);

        setToolsData((prev) => ({
            ...prev,
            postDeployToolsData: tempSelectedTools,
        }));
        setAllTools([...allTools]);
    }

    const handleSelectChange = (value, event) => {
        console.log("event :", event);
        console.log("toolsData :", toolsData);
        
        if (event.name === "env-redirect") {
            navigate(`/environments/create`);
            return;
        }
        else if (event.name === "buildType") {
            setToolsData({ ...toolsData, [event.name]: value });
        }
        else{
            setToolsData({ ...toolsData, [event.name]: value, envId: event["data-id"] });
        }
    }

    const handleUnselectedTools = (e) => {
        console.log("clicked handleUnselectedTools :", e.target);
        console.log("clicked handleUnselectedTools e.target.id :", e.target.id);

        if (e.target?.id) {
            allTools[e.target.id].onTop = true;
            setAllTools([...allTools]);
        }
    }

    const handleSelectedTools = (e) => {
        console.log("clicked handleSelectedTools :", e.target);
        console.log("clicked handleSelectedTools e.target.id :", e.target.id);

        if (e.target?.id) {
            allTools[e.target.id].onTop = false;
            setAllTools([...allTools]);
        }
    }

    const showSelectTool = (e) => {
        console.log("clicked showSelectTool :", e.target);
        console.log("clicked showSelectTool e.target.id :", e.target.id);

        if (e.target?.id) {
            setToolSelected(parseInt(e.target.id));
            setAllTools([...allTools]);
        }
    }

    const handleDragTools = (e) => {
        console.log("clicked handleDragTools :", e.target);
        console.log("clicked handleDragTools e.target.id :", e.target.id);

        if (e.target?.id) {
            // setToolSelected(parseInt(e.target.id));
            // setAllTools([...allTools]);
        }
    }



    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0", height: "100%" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelClick} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <Col lg={8} md={8} sm={8} xs={8} style={{ borderRight: "1px solid #ebebeb", height: "inherit" }}>
                <Box
                    title="Configure tools for pipeline"
                    subtitle="Drag and drop to add tools in the flow"
                    titleStyle={{ ...innerTitleStyle, lineHeight: "20px" }}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ border: 0, padding: "0px 20px", margin: 0, marginTop: "40px", height: "fit-content" }}
                />

                {allTools.map((temp, idx) => (temp.onTop &&
                    <div key={idx} id={idx} style={{ position: "relative" }} onClick={showSelectTool}>
                        <StickerWidgetWrapper id={idx} className="isoStickerWidget" style={{ marginTop: "16px", marginLeft: "22px", width: "250px", position: "relative" }}>
                            <Box id={idx} style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative" }}>
                                <ContentHolder
                                    id={idx}
                                    style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px" }}
                                >
                                    <Box
                                        title={
                                            <div id={idx} style={{ display: "flex", justifyContent: "start", alignItems: "center", position: "relative", paddingRight: "55px" }}>
                                                <span id={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                                    <img id={idx} src={customScriptIcon} />
                                                </span>
                                                <div>
                                                    <p id={idx} style={{ color: "#1F1F1F", marginLeft: "10px", fontSize: "12px", lineHeight: "20px", fontFamily: "Poppins" }}>{temp.confName}</p>
                                                    <p id={idx} style={{ marginLeft: "10px", ...innerSubtitleStyle }}>Click to configure</p>
                                                </div>
                                            </div>
                                        }
                                        // subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Click to configuration</p>}
                                        style={{ border: "transparent", padding: "0", marginBottom: "0", position: "relative" }}
                                        titleStyle={{ ...innerTitleStyle, marginBottom: "0", position: "relative" }}
                                        subTitleStyle={innerSubtitleStyle}
                                        id={idx}
                                    />
                                </ContentHolder>
                            </Box>
                        </StickerWidgetWrapper>
                        {idx === toolSelected && <img src={blueTriangleWorkflow} style={blueTriangleStyle} />}
                        {/* <img id={idx} src={deleteIcon} alt="delete" onClick={handleSelectedTools} style={{ position: "absolute", top: "6px", right: "33px", width: "18px", height: "22px", objectFit: "cover", cursor: "pointer" }} /> */}
                        <img id={idx} src={dragIcon} alt="drag" onClick={handleDragTools} style={{ position: "absolute", top: "29px", left: "13px", width: "18px", height: "22px", objectFit: "cover", cursor: "pointer" }} />
                    </div>
                ))}

                {/* next section */}
                {/* {allTools.filter((item) => !item.onTop).length > 0 && <Box
                    title="Select tools for pipeline"
                    subtitle="Click plus icon to add more tools in the flow."
                    titleStyle={{ ...innerTitleStyle, lineHeight: "20px" }}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ border: 0, padding: "0px 20px", margin: 0, marginTop: "40px", height: "fit-content" }}
                />} */}

                {/* {allTools.map((temp, idx) => (
                    (!temp.onTop && <div key={idx} style={{ position: "relative", marginTop: "20px" }} id={idx} onClick={handleUnselectedTools}>
                        <StickerWidgetWrapper id={idx} className="isoStickerWidget" style={{ marginLeft: "22px", width: "250px", position: "relative" }}>
                            <Box id={idx} style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative" }}>
                                <ContentHolder
                                    id={idx}
                                    style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px" }}
                                >
                                    <Box
                                        id={idx}
                                        title={
                                            <div id={idx} style={{ display: "flex", justifyContent: "start", alignItems: "center", position: "relative", paddingRight: "55px" }}>
                                                <span id={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                                    <img id={idx} src={customScriptIcon} />
                                                </span>
                                                <div>
                                                    <p id={idx} style={{ color: "#1F1F1F", marginLeft: "10px", fontSize: "12px", lineHeight: "20px", fontFamily: "Poppins" }}>{temp.name}</p>
                                                    <p id={idx} style={{ marginLeft: "10px", ...innerSubtitleStyle }}>Click to configure</p>
                                                </div>
                                            </div>
                                        }
                                        // subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Click to configuration</p>}
                                        style={{ border: "transparent", padding: "0", marginBottom: "0", position: "relative" }}
                                        titleStyle={{ ...innerTitleStyle, marginBottom: "0", position: "relative" }}
                                        subTitleStyle={innerSubtitleStyle}
                                    />
                                </ContentHolder>
                            </Box>
                        </StickerWidgetWrapper>
                        <img src={plusIcon} alt="add" id={idx} onClick={handleUnselectedTools} style={{ position: "absolute", top: "10px", right: "35px", width: "16px", height: "16px", objectFit: "cover", cursor: "pointer" }} />
                    </div>)
                ))} */}

            </Col>

            {/* Right Column */}
            <Col lg={16} md={16} sm={16} xs={16} style={{ marginBottom: "100px" }}>
                <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle }}>
                    <Form
                        form={form}
                        initialValues={{ ...toolsData }}
                        preserve={false}
                        name="dynamic_rule"
                        style={{
                            width: "100%",
                            height: "fit-content"
                        }}
                    >
                        {allTools?.[toolSelected]?.toolName === "Deploy" && <>
                            <Box
                                title={"Environment*"}
                                subtitle={"Dropdown contains list of environments which have same service plus environment variables at run time as true."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="env"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide Environment.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            value={toolsData?.postDeployToolsData?.[toolSelected]?.toolName}
                                            placeholder="Select Environment"
                                            onChange={handleSelectChange}
                                            style={{ width: "100%" }}
                                        >
                                            {fetching ?
                                                    <Option>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                            <Spin size="small" />
                                                        </div>
                                                    </Option>
                                                : envDropdown.length > 0
                                                ? envDropdown
                                                : <Option key="0" name="env-redirect" data-alias="" type="integration" style={{ background: "white" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                                                        <img src={noDataImg} />
                                                        <span style={noDataTitle}>No Environments available.</span>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <img src={noDataPlusIcon} />
                                                            <span style={noDataSubTitle}>Create one</span>
                                                        </div>
                                                    </div>
                                                </Option>
                                            }
                                        </Select>
                                    </Form.Item>
                                </ContentHolder>
                            </Box>

                            <Box
                                title={"Build Type*"}
                                subtitle={"Select build type from the following."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", marginBottom: "150px" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="buildType"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide build type.',
                                            },
                                        ]}
                                    >
                                        <Select
                                            value={toolsData?.postDeployToolsData?.[toolSelected]?.buildType ? toolsData.postDeployToolsData[toolSelected].buildType : undefined}
                                            placeholder="Select"
                                            onChange={handleSelectChange}
                                            style={{ width: "100%" }}
                                        >
                                            {autoTriggerOptions}
                                        </Select>
                                    </Form.Item>
                                </ContentHolder>
                            </Box>
                        </>}

                        {allTools?.[toolSelected]?.toolName === "Trivy" && <>
                            <Box
                                title={"Max critical vulnerability*"}
                                subtitle={"Mention your max critical vulnerability."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="maxCriticalVulnerability"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide max critical vulnerability.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="maxCriticalVulnerability"
                                            value={toolsData?.postDeployToolsData?.[toolSelected]?.maxCriticalVulnerability}
                                            onChange={handelInputChange}
                                            placeholder="2"
                                            style={inputStyle}
                                        // onBlur={getPublicBranches}
                                        />
                                    </Form.Item>
                                </ContentHolder>
                            </Box>

                            <Box
                                title={"Max high vulnerability*"}
                                subtitle={"Mention your max high vulnerability."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="maxHighVulnerability"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide max high vulnerability.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="maxHighVulnerability"
                                            value={toolsData?.postDeployToolsData?.[toolSelected]?.maxHighVulnerability}
                                            onChange={handelInputChange}
                                            placeholder="5"
                                            style={inputStyle}
                                        // onBlur={getPublicBranches}
                                        />
                                    </Form.Item>
                                </ContentHolder>
                            </Box>
                        </>}
                    </Form>

                    {/* <Box
                        title={"Failure Strategy"}
                        subtitle={"Please define a failure strategy if the tool fails to run or encounters an error while running."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0" }}
                    >
                        <ContentHolder style={{width: "100%", marginTop: "6px"}}>
                            <button style={failureButtonsStyle}>
                                <img src={} alt="abort" />
                            </button>
                        </ContentHolder>
                    </Box> */}
                </StickerWidgetWrapper>
            </Col>

            <CreateTaskFooter buttonContent={"Custom Script"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />

        </LayoutWrapper>
    );
};