import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Spin } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
// import awsLogo from '../../../assets/images/aws.svg';
// import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
// import secretDetectionIcon from '@iso/assets/images/secret-detection-icon.svg';
// import sonarcubeIcon from '@iso/assets/images/sonarcube-icon.svg';
// import checkmarkIcon from '@iso/assets/images/checkmark-icon.svg';
import { formatErrors, marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';

const Option = SelectOption;

const noDataSubTitle = {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "5px"
};
let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const noDataTitle = {
    color: "#A6A6A6",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
};

const titleStyle = {
    color: "#7b7b7b",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const subTitleStyle = {
    color: "#1F1F1F",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "15px"
};

let innerSubtitleStyle = {
    fontSize: "13px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};


const widgetStyle = {
    margin: "20px",
    width: "100%",
    position: "relative",
};

export default ({
    workflowNodeData,
    workflowEdgesData,
    // serviceId,
    columnId,
}) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, setWorkflowNodeData, setWorkflowEdgesData, currentWorkflowData, workflowTypeExecution, setWorkflowTypeExecution, setCurrentWorkflowData, setOpenModel } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const [deployData, setDeployData] = useState({
        envName: currentWorkflowData?.deployConfig?.config?.env_name ? currentWorkflowData.deployConfig.config.env_name : "",
        envId: currentWorkflowData?.deployConfig?.config?.env_unique_id ? currentWorkflowData.deployConfig.config.env_unique_id : "",
        error: null,
        buildType: currentWorkflowData?.deployConfig?.config?.build_type ? currentWorkflowData.deployConfig.config.build_type : "Automatic",
    });

    const [envDropdown, setEnvDropdown] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(null);
    const [autoTriggerOptions, setAutoTriggerOptions] = useState([]);

    // const navigate = useNavigate();
    // const location = useLocation();

    const handelCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    // creating Build Type options
    useEffect(() => {
        let tempAutoTriggerDropdown = [];

        ["Automatic", "Manual"].forEach((child, ind) => {
            tempAutoTriggerDropdown.push(
                <Option key={child} name="buildType" data-id="" data-region={null} value={child}>
                    <div style={{ width: "100%", display: "flex" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });

        setAutoTriggerOptions(tempAutoTriggerDropdown);
    }, []);

    // get env list having service_id
    useEffect(() => {
        console.log("currentWorkflowData in deployClickRightDrawer :", currentWorkflowData);
        let query = `?service_id=${currentWorkflowData?.serviceId}`;     // &account_id=${currentWorkflowData?.accountId}
        if (currentWorkflowData?.deployConfig?.config?.env_unique_id) {
            query = query + `&env_unique_id=${currentWorkflowData.deployConfig.config.env_unique_id}&env_var_at_build_time=true`
        }
        else{
            query = query + `&env_var_at_build_time=true`
        }


        // if ([true, false].includes(currentWorkflowData.pass_env_vars_at_build_time)) {
        //     query = query + `&env_var_at_build_time=${currentWorkflowData.pass_env_vars_at_build_time}`
        // }

        api.get(process.env.REACT_APP_API_URI + `/api/project_env/env/create/${query}`)
            .then((res) => {
                let data = res.data;
                console.log("env list :", data);
                if (data.status) {
                    let myEnvs = [];

                    let arr = data.data.sort((a, b) => (a.name.localeCompare(b.name)));

                    let listOfEnvsAlreadySelected = currentWorkflowData.deployConfig.tools.map((item) => item.envId);
                    console.log("listOfEnvsAlreadySelected :", listOfEnvsAlreadySelected);

                    arr.map((temp, idx) => {
                        // Only active, updating and error phase env's
                        if ([0, 1, 2, 3, 8].includes(temp.phase) && !listOfEnvsAlreadySelected.includes(temp.env_unique_id)) {
                            console.log("temp :", temp);
                            myEnvs.push(<Option key={idx} name="envName" data-id={temp.env_unique_id} value={temp.name}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div>
                                        <img src={temp.account_logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="icon" />
                                        <span>{temp.name}</span>
                                    </div>
                                    {/* <span>{temp.cluster_type}</span> */}
                                </div>
                            </Option>)

                            // when user opens deploy drawer first time - on update
                            if (currentWorkflowData.deployConfig.enabled === true && currentWorkflowData.deployConfig.config.environments && temp.env_unique_id === currentWorkflowData.deployConfig.config.environments[0].env_unique_id) {
                                console.log("Found :", temp);
                                setDeployData((prev) => ({ envName: temp.env_name, envId: temp.env_unique_id, error: null, buildType: currentWorkflowData.deployConfig.config.build_type }));
                                form.setFieldsValue({ envName: temp.env_name, envId: temp.env_unique_id, error: null, buildType: currentWorkflowData.deployConfig.config.build_type });
                            }
                        }
                    });

                    // If env is selected by user then below
                    if (currentWorkflowData.deployConfig.enabled === true && currentWorkflowData.deployConfig.config.env_unique_id) {
                        console.log("changing again :", currentWorkflowData.deployConfig.config);
                        setDeployData((prev) => ({ envName: currentWorkflowData.deployConfig.config.env_name, envId: currentWorkflowData.deployConfig.config.env_unique_id, error: null, buildType: currentWorkflowData.deployConfig.config.build_type }));
                        form.setFieldsValue({ envName: currentWorkflowData.deployConfig.config.env_name, envId: currentWorkflowData.deployConfig.config.env_unique_id, error: null, buildType: currentWorkflowData.deployConfig.config.build_type });
                    }

                    setEnvDropdown(myEnvs);
                    // setEnvList(data.data);
                    setFetching(false);
                }
                else if (!data.status) {
                    let err = formatErrors(data.error_details);
                    console.log("err in project_env/create :", err);
                    setFetching(false);
                }
            }).catch((err) => {
                console.log("err :", err);
                setFetching(false);
            });
    }, []);

    // Check if env is valid
    useEffect(() => {
        console.log("deployData in validate_env :", deployData);
        console.log("currentWorkflowData.deployConfig.config in validate_env :", currentWorkflowData?.deployConfig?.config);
        if (currentWorkflowData && !currentWorkflowData?.fromPipeline && deployData.envId && deployData.envId !== currentWorkflowData.deployConfig.config.env_unique_id) {
            api.get(process.env.REACT_APP_API_URI + `/api/pipelines/workflows/validate_env/${deployData.envId}/${currentWorkflowData.serviceId}`)
                .then(async (res) => {
                    let data = res.data;
                    console.log("workflows/validate_env data :", data);
                    if (data.status) {
                        if (data.data.exists) {
                            // let msg = data.data.message + ` If you save or execute the changes your workflow(${data.data.workflow_name}) will be replaced by the current workflow(${currentWorkflowData.workflowName}).`;
                            let msg = data.data.message;
                            setDeployData((prev) => ({ ...deployData, error: msg }));
                            setError(msg);
                            form.setFieldsValue({
                                ...deployData,
                                error: msg,
                            });
                        }
                        else {
                            setDeployData((prev) => ({ ...deployData, error: null }));
                            form.setFieldsValue({
                                ...deployData,
                                error: null,
                            });
                            setError(null);
                        }
                    }
                    else if (!data.status) {
                        let err = formatErrors(data.error_details);
                        setDeployData((prev) => ({ ...deployData, error: null }));
                        console.log("err in env check api :", err);
                    }
                }).catch((err) => {
                    setDeployData((prev) => ({ ...deployData, error: null }));
                    console.log("err :", err);
                });
        }
    }, [deployData.envId]);

    useEffect(() => {
        const test = async () => {
            if (deployData.error) {
                try {
                    const values = await form.validateFields();
                    console.log('Success:', values);
                }
                catch (err) {
                    console.log("Found error :", err);
                }
            }
        }

        test();
    }, [deployData.error]);


    const handleClick = (type) => {
        console.log("clicked handleClick :", type);
        setOpenRightDrawer({
            drawerType: type,
            drawerProps: {
                data: workflowData,
                columnId: columnId,
                type: "source",
                className: "expend",
            },
        });
    }

    const handelAdd = () => {
        let tempNodeData = [];
        let tempEdgesData = [];
        let targetIndex = 0;  // as this is sourceNode
        let idOrders = [];
        let toolsCount = 0;
        let sourceToolsCount = 0;
        let buildToolsCount = 0;
        let deployToolsCount = 0;

        console.log("workflowNodeData at beginning :", JSON.parse(JSON.stringify(workflowNodeData)));
        console.log("workflowEdgesData at beginning :", JSON.parse(JSON.stringify(workflowEdgesData)));
        console.log("currentWorkflowData at beginning :", JSON.parse(JSON.stringify(currentWorkflowData)));

        let workflowTempNodeData = [];
        // remove any build tool node if there is any previously
        workflowNodeData.map((item, idx) => {
            console.log("checking ids order list :", item);

            if (item.type === "sourceToolNode") {
                sourceToolsCount = sourceToolsCount + 1;
            }
            else if (item.type === "buildToolNode") {
                buildToolsCount = buildToolsCount + 1;
            }
            else if (item.type === "deployToolNode") {
                deployToolsCount = deployToolsCount + 1;
            }

            console.log("item.type :", item.type);
            console.log("item.type :", item.type !== "deployToolNode");
            // remove deployTool if already exist
            if (item.type !== "deployToolNode") {
                workflowTempNodeData.push(item);
            }
            else {
                console.log("idx :", idx);
                workflowEdgesData.splice(idx - 1, 1, null);
            }
        });

        // set temp data for workflowEdgesData
        let workflowTempEdgesData = workflowEdgesData.filter((temp) => temp);

        let deployToolStartFrom = sourceToolsCount + buildToolsCount + deployToolsCount + 3   // sourceToolsNode + buildToolsNode + deployToolsCount + (Source node + build node + deploy node)
        // console.log("workflowData in add new :", JSON.parse(JSON.stringify(workflowData)));
        // console.log("sourceToolsCount :", sourceToolsCount);
        // console.log("buildToolsCount :", buildToolsCount);
        // console.log("deployToolStartFrom :", deployToolStartFrom);
        // console.log("workflowTempNodeData in middle :", JSON.parse(JSON.stringify(workflowTempNodeData)));
        // console.log("workflowTempEdgesData in middle :", JSON.parse(JSON.stringify(workflowTempEdgesData)));

        currentWorkflowData.deployConfig.tools.map((temp, idx) => {
            console.log("workflowData.postDeployToolsData -> temp :", temp);

            let myNodeObj = {
                id: "",
                type: "deployToolNode",
                data: { label: "Deploy", id: idx + deployToolStartFrom, indx: idx, type: "deployToolNode" },
                position: {
                    x: currentWorkflowData?.fromPipeline ? 401 : 800,
                    y: currentWorkflowData?.fromPipeline ? 80 * (idx + 1) : 200 * (idx + 1)
                },   // 2nd node should be below first node
                style: { borderRadius: "20px" },
            }

            let myEdgeObj = {
                id: "",
                source: "",
                target: "",
                animated: false,
                // animated: false,
                style: { stroke: "#F7F6FA", strokeWidth: 3 },
            }

            workflowTempNodeData.splice(idx + deployToolStartFrom, 0, myNodeObj);
            workflowTempEdgesData.splice(idx + deployToolStartFrom, 0, myEdgeObj);
        });

        workflowTempNodeData.push({
            id: "",
            type: "deployNode",
            data: { label: "Deploy", toolsOrder: [0, 1, 2] },
            position: { x: 800, y: 50 },
            style: { borderRadius: "20px" },
            // sourcePosition: "left",
        });

        workflowTempEdgesData.push({
            id: "",
            source: (workflowTempNodeData.length - 2).toString(),
            target: (workflowTempNodeData.length - 1).toString(),
            animated: false,
            style: { stroke: "#ecf4fb", strokeWidth: 3 },
        });


        // position: let x, y = 0, 0;
        workflowTempNodeData.map((temp, idx) => {
            // Node
            // {
            //     id: "1",
            //     type: "sourceNode",
            //     data: { label: "Source" },
            //     position: { x: 0, y: 50 },
            //     style: { borderRadius: "20px" },
            //     sourcePosition: "right",
            //   },
            // let position = idx === targetIndex+1 ? { x: 0, y: 50 } : temp.position;
            console.log("idx :", idx);
            console.log("temp :", temp);

            workflowTempNodeData[idx] = {
                ...workflowTempNodeData[idx],
                id: `${idx + 1}`,   // should start from 1 index
                type: temp.type,
                data: temp.data,
                // position: { x: 450, y: 200 },
                position: temp.position,
            }

            // tempNodeData.push();
        });

        // For Edges
        workflowTempEdgesData.map((temp, idx) => {
            // Edge
            // {
            //     id: "e1-2",
            //     source: "1",
            //     target: "2",
            //     animated: false,
            //     style: { stroke: "#fff" },
            //   },

            // source will always remain at it's position
            if (idx != 0) {
                workflowTempEdgesData[idx] = {
                    ...workflowTempEdgesData[idx],
                    id: `e${idx + 1}-${idx + 2}`,
                    source: `${idx + 1}`,
                    target: `${idx + 2}`,
                }
            }

            // tempEdgesData.push();
        });

        console.log("workflowTempNodeData :", workflowTempNodeData);
        console.log("workflowTempEdgesData :", workflowTempEdgesData);

        let filteredEdgeData = workflowTempEdgesData.filter((item, idx) => item.source && parseInt(item.source) < workflowTempNodeData.length)
        console.log("filteredEdgeData :", filteredEdgeData);

        setWorkflowNodeData([...workflowTempNodeData]);
        setWorkflowEdgesData([...filteredEdgeData]);
        // we can't perform below because it will impact build tool add
        // setWorkflowTypeExecution({
        //     source: true,
        //     build: false,
        //     deploy: currentWorkflowData.withoutCI ? true : false
        // });
    }

    const saveEnvChanges = () => {
        console.log("inside saveEnvChanges...")

        if (!currentWorkflowData.deployConfig.enabled) {
            handelAdd();
        }

        currentWorkflowData.deployConfig.enabled = true;

        currentWorkflowData.deployConfig.config = {
            ...currentWorkflowData.deployConfig.config,
            env_unique_id: deployData.envId,
            env_name: deployData.envName,
            build_type: deployData.buildType,
        };

        setCurrentWorkflowData({ ...currentWorkflowData, deployConfig: { ...currentWorkflowData.deployConfig } });
        setWorkflowTypeExecution(JSON.parse(JSON.stringify({ ...workflowTypeExecution, deploy: true })));
        localStorage.setItem("Workflow", JSON.stringify({ ...currentWorkflowData, deployConfig: { ...currentWorkflowData.deployConfig } }));
        handelCancel();

        setOpenModel({
            modalType: false,
            modalProps: {
                data: null,
            },
        });
    }

    const handleSaveDetails = async (e) => {
        console.log("clicked handleSaveDetails...",);
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            saveEnvChanges();
        }
        catch (err) {
            console.log("Found error :", err);
            if (err.errorFields[0].errors[0] === deployData.error) {
                // "CONFIRM_ACTION"
                setOpenModel({
                    modalType: 'CONFIRM_ACTION',
                    modalProps: {
                        data: "",
                        title: "Save Workflow?",
                        subTitle: <span style={{ display: "block" }}>
                            <p>Are you sure you want to save this environment?</p>
                            {deployData.error && <p style={{ color: "#CB444A", fontSize: "12px", marginTop: "15px" }}> Note: {deployData.error}</p>}
                        </span>,
                        cancelButton: "Cancel",
                        saveButton: "Save",
                        onConfirm: () => { console.log("onConfirm"); saveEnvChanges() },
                        onCancel: () => { console.log("onCancel") },
                        columnId: null,
                        className: "large-Box"
                    },
                });
            }
        }
    }

    const handleSelectChange = (value, event) => {
        console.log("clicked handleSelectChange...");
        if (event.name === "env-redirect") {
            navigate(`/environments/create`, {
                state: {
                    data: null,
                    from: "workflow-deploy"
                }
            });
        }
        else if (event.name === "buildType") {
            setDeployData({ ...deployData, buildType: value });
        }
        else {
            setDeployData({ ...deployData, envName: value, envId: event["data-id"], error: null });
        }
    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelCancel();
    }


    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "20px" }}>
                <Form
                    form={form}
                    initialValues={{ ...deployData }}
                    preserve={false}
                    name="dynamic_rule"
                    style={{
                        width: "100%",
                        height: "fit-content"
                    }}
                >
                    <Box
                        title={`Environment${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                        subtitle={"Dropdown contains list of environments which have same service."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", marginBottom: "0px" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="envName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Environment.',
                                    },
                                    {
                                        // Custom validator
                                        validator: (_, value) => {
                                            console.log("deployData in validator :", deployData);
                                            if (value && deployData.error) {
                                                return Promise.reject(new Error(error));
                                            }
                                            else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Select
                                    value={deployData?.envName ? deployData?.envName : undefined}
                                    placeholder={"Select Environment"}
                                    onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                    disabled={currentWorkflowData?.fromPipeline}
                                >
                                    {fetching ?
                                        <Option>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                <Spin size="small" />
                                            </div>
                                        </Option>
                                        : envDropdown.length > 0
                                            ? envDropdown
                                            : <Option key="0" name="env-redirect" data-alias="" type="integration" style={{ background: "white" }}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                                                    <img src={noDataImg} />
                                                    <span style={noDataTitle}>No Environments available.</span>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <img src={noDataPlusIcon} />
                                                        <span style={noDataSubTitle}>Create one</span>
                                                    </div>
                                                </div>
                                            </Option>
                                    }
                                </Select>
                            </Form.Item>
                        </ContentHolder>
                    </Box>

                    <Box
                        title={`Build Type${currentWorkflowData?.fromPipeline ? "" : "*"}`}
                        subtitle={"Select build type from the following."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", marginBottom: "150px" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="buildType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please provide build type.',
                                    },
                                ]}
                            >
                                <Select
                                    value={deployData?.buildType ? deployData.buildType : undefined}
                                    placeholder="Select"
                                    onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                    disabled={currentWorkflowData?.fromPipeline}
                                >
                                    {autoTriggerOptions}
                                </Select>
                            </Form.Item>
                        </ContentHolder>
                    </Box>
                </Form>
            </StickerWidgetWrapper>

            {!currentWorkflowData?.fromPipeline && <CreateTaskFooter buttonContent={"Source Config"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} />}
        </LayoutWrapper>
    );
};