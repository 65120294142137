import { React, useState } from 'react';
// import IntlMessages from '@iso/components/utility/intlMessages';
// import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
// import Button from '@iso/components/uielements/button';
import { Input, Spin, Form } from 'antd';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select from '../../Deployments/Deployment.styles';
import {
    SelectOption as Option,
} from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { borderRadius } from '../../../library/helpers/style_utils';
import {
    PlusOutlined
} from '@ant-design/icons';
import plusIcon from '../../../assets/images/plusIcon.svg';
import { Link } from 'react-router-dom';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import deleteIcon from '../../../assets/images/tagsDelete.svg';
import { Switch } from 'antd';
import downIcon from '../../../assets/images/downArrow.svg';
import upIcon from '../../../assets/images/upArrow.svg';
import api from '../../../api';
import InputWithSuffix from '../../../components/uielements/input';
import { LoadingOutlined } from '@ant-design/icons';


const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

var optionAzs = [];

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px"
};

let innerSubtitleStyle = {
    fontSize: "12px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const inputStyle = {
    height: "44px"
}

export default function ArgoFlowDrawer({
    // handleSubmit,
    data,
    setData,
    values,
    submitCount,
    onClose,
    onCancel,
    onDelete,
    openDrawer,
    refresh,
    setRefresh,
    onEditCancel,
    tabSelected,
    openAddOns
}) {

    const { openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, setCurrentCluster, updateCluster, setUpdateCluster, oneClickCluster, setOneClickCluster, clusterTabSelected, fetchingClusterPrerequisites, setFetchingClusterPrerequisites, isOneClick, isUpdate } = useContext(AppContext);

    const navigate = useNavigate();

    const [argoflowData, setArgoflowData] = useState(isOneClick ? { ...oneClickCluster[clusterTabSelected].argoFlow } : isUpdate ? { ...updateCluster[clusterTabSelected].argoFlow } : { ...currentCluster[clusterTabSelected].argoFlow });

    const [hostedZones, setHostedZones] = useState([]);
    const [validateDomainStatus, setValidateDomainStatus] = useState("");
    // const [refreshIntervalList, setRefreshIntervalList] = useState([]);
    // const [objExpirationList, setObjExpirationList] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [form] = Form.useForm();

    const [error, setError] = useState({
        status: false,
        msg: ""
    });

    const [dnsMsg, setDnsMsg] = useState({
        error: false,
        msg: ""
    });

    useEffect(() => {
        setFetching(true);
        let arr = [];
        let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
        selectedCluster[clusterTabSelected].hostedZones && Object.keys(selectedCluster[clusterTabSelected].hostedZones).map((val, id) => {
            selectedCluster[clusterTabSelected].hostedZones[val].hosted_zones.map((zone, idx) => {
                arr.push(
                    // <Option key={idx} name="hostname" data-alias={val} data-type={currentCluster[clusterTabSelected].hostedZones[val].type} value={zone}>{zone}</Option>
                    <Option key={`${idx}-${id}`} name="hostname" data-alias={val} data-type={selectedCluster[clusterTabSelected].hostedZones[val].type} value={zone}>
                        <div key={`${idx}-a`} style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <span key={`${idx}-b`} title={zone} style={{ flex: "-1", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                {zone}
                            </span>
                        </div>
                    </Option>
                );
            })
        });

        setHostedZones(arr);
        setFetching(false);
    }, [currentCluster[clusterTabSelected].hostedZones, updateCluster[clusterTabSelected].hostedZones, oneClickCluster[clusterTabSelected].hostedZones]);

    useEffect(() => {
        if(!isUpdate) {
            let myTimeout = setTimeout(() => {
                if (argoflowData.recordPrefix && argoflowData.recordPrefix !== "" && !isUpdate) {
                    let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
                    let checkFor = selectedCluster[clusterTabSelected].selectedAddons.find(val => val.name === "ArgoFlow").id;
                    dnsCheck(argoflowData.recordPrefix, argoflowData.hostname, argoflowData.zoneId, argoflowData.dnsRecordType, checkFor);
                }
            }, 2000);
    
            return () => clearTimeout(myTimeout);    
        }
    }, [argoflowData.recordPrefix, argoflowData.hostname]);

    const handleSelectChange = (value, event) => {
        console.log("event :", event);
        console.log("value :", value);

        if (event.name === "hostname") {
            argoflowData.zoneId = event["data-alias"];
            argoflowData.dnsRecordType = event["data-type"];
            argoflowData[event.name] = value;

            setDnsMsg({
                error: false,
                msg: ""
            });
        }
        else {
            argoflowData[event.name] = value;
        }

        console.log("argoflowData :", argoflowData);
        setArgoflowData({ ...argoflowData });
        setError({
            status: false,
            msg: ""
        });
    };

    const handelInputChange = (e) => {
        console.log("Setting argoflow data: ", argoflowData);
        setArgoflowData({ ...argoflowData, [e.target.name]: e.target.value });
        if (e.target.name === "recordPrefix") {
            setDnsMsg({
                error: false,
                msg: ""
            });
            // setTimeout(() => {
            //     if(e.target.value !== ""){
            //         console.log("My value: ", e.target.value, argoflowData);
            //         dnsCheck(e.target.value, argoflowData.hostname, argoflowData.zoneId, argoflowData.dnsRecordType);
            //     }
            // }, 2000);
        }
        setError({
            status: false,
            msg: ""
        });
    };

    const handleCancel = (e) => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                argoflowData: "",
            },
        });

        // onClose();
    };

    const handleSaveDetails = async (e) => {
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            console.log('argoflowData :', argoflowData);
            let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;

            selectedCluster[clusterTabSelected].argoFlow = argoflowData;
            for (let i = 0; i < selectedCluster[clusterTabSelected].selectedAddons.length; i++) {
                if (selectedCluster[clusterTabSelected].selectedAddons[i].name === "ArgoFlow") {
                    if (dnsMsg.error) {
                        selectedCluster[clusterTabSelected].selectedAddons[i] = {
                            ...selectedCluster[clusterTabSelected].selectedAddons[i],
                            message: dnsMsg.msg,
                            borderColor: "red"
                        };
                        break;
                    }
                    else {
                        selectedCluster[clusterTabSelected].selectedAddons[i] = {
                            ...selectedCluster[clusterTabSelected].selectedAddons[i],
                            message: "",
                            borderColor: "#f5f5f5"
                        };
                        break;
                    }
                }
            }
            console.log("After setting: ", selectedCluster[clusterTabSelected].argoFlow);
            if (isUpdate) {
                setUpdateCluster({
                    ...updateCluster,
                    clusterTabSelected: selectedCluster[clusterTabSelected]
                });
                localStorage.setItem('updateCluster', JSON.stringify(({
                    ...updateCluster,
                    clusterTabSelected: selectedCluster[clusterTabSelected]
                })));
            }
            else if (isOneClick) {
                setOneClickCluster({
                    ...oneClickCluster,
                    clusterTabSelected: selectedCluster[clusterTabSelected]
                });
                localStorage.setItem('oneClickCluster', JSON.stringify({
                    ...oneClickCluster,
                    clusterTabSelected: selectedCluster[clusterTabSelected]
                }));
            }
            else {
                setCurrentCluster({
                    ...currentCluster,
                    clusterTabSelected: selectedCluster[clusterTabSelected]
                });
                localStorage.setItem('Cluster', JSON.stringify({
                    ...currentCluster,
                    clusterTabSelected: selectedCluster[clusterTabSelected]
                }));
            }
            setRefresh(refresh => !refresh);

            setOpenRightDrawer({
                drawerType: false,
                drawerProps: {
                    argoflowData: "",
                },
            });
        }
        catch (err) {
            console.log("Error :", err);
        }
    };

    //   const filterClusterLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

    //   const filterVPCLogOption = (input, option) => (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());

    const dnsCheck = (recordPrefix, zoneName, zoneId, dnsRecordType, checkFor) => {
        setValidateDomainStatus("validating");
        api.post('/api/cluster/check-dns/', [{
            "record_name": recordPrefix,
            "zone_id": zoneId,
            "dns_record_type": dnsRecordType,
            "zone_name": zoneName,
            "check_for": checkFor
        }]).then((res) => {
            let data = res.data;
            if (data.status) {
                setDnsMsg({
                    error: false,
                    msg: typeof data.data === "object" ? Object.values(data.data)[0] : "Something went wrong. Please try again."
                });
                setValidateDomainStatus("success");
            }
            else {
                setDnsMsg({
                    error: true,
                    msg: typeof data.error_details === "object" ? Object.values(data.error_details)[0] : "Something went wrong. Please try again."
                });
                setValidateDomainStatus("error");
            }
        }).catch((err) => {
            console.log("Error");
            setValidateDomainStatus("error");
        });
    }


    return (
        <ProdContentHold>
            <CreateTaskHeader
                buttonContent="Argoflow"
                data={argoflowData}
                // values={{editing: true}}
                onCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            argoflowData: "",
                        },
                    });
                    // onClose();
                }}
                onDelete={onDelete}
                onEditCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            argoflowData: "",
                        },
                    });
                    // onClose();
                }}
            />

            <Form
                form={form}
                initialValues={{ ...argoflowData }}
                preserve={false}
                name="dynamic_rule"
                disabled={isUpdate ? (updateCluster.provisioner === 'imported'? false: true ): false}
            >

                <Box
                    title={"Hostname*"}
                    subtitle={"Provide hostname here"}
                    titleStyle={innerTitleStyle}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ marginTop: "80px" }}
                >
                    <ContentHolder>
                        <Form.Item
                            name="recordPrefix"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide hostname prefix.',
                                },
                                {
                                    // Custom validator for special characters
                                    validator: (_, value) => {
                                        const specialCharPattern = /^([a-z0-9]+\.*-*)+$/; // This regex allows only letters, numbers, and spaces
                                        let selectedCluster = isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster;
                                        if (value === selectedCluster[clusterTabSelected].pglStack.recordPrefix) {
                                            return Promise.reject(new Error('Hostname should not be same as Pgl Stack hostname.'));
                                        }
                                        else if (!value || specialCharPattern.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Hostname should start with alphanumeric char and can contain numbers, lowercase letters, hyphens or full stops.'));
                                    }
                                }
                            ]}
                        >
                            <InputWithSuffix
                                name="recordPrefix"
                                className='inputAddonAfter'
                                value={argoflowData.recordPrefix}
                                // styles={{...inputStyle}}
                                style={{ height: "44px !important", zIndex: 1 }}
                                onChange={handelInputChange}
                                placeholder="hostname"
                                suffix={validateDomainStatus === "validating" ? <LoadingOutlined /> : <></>}
                                addonAfter={
                                    <Select
                                        defaultValue={argoflowData.hostname}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        onChange={handleSelectChange}
                                        border={0}
                                        className="cusIntSelectDrop"
                                        style={{ padding: "0", borderRadius: "6px !important", zIndex: 1 }}
                                    >
                                        {fetching
                                            ?
                                            <Option>
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                    <Spin size="small" />
                                                </div>
                                            </Option>
                                            :
                                            hostedZones
                                        }
                                    </Select>
                                }
                            />
                        </Form.Item>
                        <p style={{ color: dnsMsg.error ? "red" : "green" }}> {dnsMsg.msg} </p>
                    </ContentHolder>
                </Box>

                <Box style={{ height: "150px" }}>
                    <Box>
                    </Box>
                </Box>
            </Form>
            <CreateTaskFooter buttonContent={"PGL Stack"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />
        </ProdContentHold>
    );
};
