import React, { useEffect, useState } from 'react';
import api from '../api';
import { useLocation, useNavigate } from 'react-router-dom';
import { RemoveCloneEnvironmentFromLocal, RemoveEnvironmentFromLocal, formatErrors, validateYaml } from './helper';
import { Button, message } from 'antd';
import { useContext } from 'react';
import { AlertContext } from './alertContext';
import rightIcon from '../assets/images/rightIcon.svg';
import impIcon from '../assets/images/impIcon.svg';
import { yamlContent } from './yamlContent';
const DeploymentContext = React.createContext();

const DeploymentProvider = ({ children }) => {

    let navigate = useNavigate();

    let initialDeploymentData = {
        envName: "",
        clusterName: "",
        clusterAccount: "",
        clusterIntegrationId: 0,
        clusterId: "",
        clusterDbId: "",
        clusterNamespace: "",
        clusterRegion: "",
        azList: [],
        ingressAlbEnabled: false,
        ingressNginxEnabled: false,
        dns: "",
        hostedAccount: "",
        recordType: "",
        projectName: "",
        projectId: "",
        description: "",
        enableIstio: false,
        provisioner: "",
        vpc_id: "",
        tags: [{
            name: "",
            value: ""
        }],
    };

    const initialProject = {
        id: "",
        name: "",
        title: "",
        created_at: new Date(),
        progress: "",
        selectedServiceId: "",
        tasks: [],
        columns: {
            'app': {
                id: 'app',
                board_id: 'board-1',
                task_orders: [],
                title: 'APPLICATIONS',
                editing: false,
            },
            'dataSource': {
                id: 'dataSource',
                board_id: 'board-1',
                task_orders: [],
                title: 'DATA SOURCES',
                editing: false,
            },
            // 'queues': {
            //     id: 'queues',
            //     board_id: 'board-2',
            //     task_orders: [],
            //     title: 'QUEUES',
            //     editing: false,
            // },
            // 'thirdParties': {
            //   id: 'thirdParties',
            //   board_id: 'board-1',
            //   task_orders: [],
            //   title: 'THIRD PARTIES',
            //   editing: false,
            // },
        },
    }

    let initialApplicationData = {
        basicDetails: {
            appName: "",
            serviceName: "",
            url: "",
            sourceType: "",
            imageRepo: "",
            imageTag: "",
            containerRegistryType: 1,
            appSource: {},
            branch: "",
            dockerFilePath: "Dockerfile",
            buildContext: "",
            containerRegistryId: {},
            enableAutoTrigger: false,
        },
        environments: [],
        volumes: [{
            volumeType: "",
            config: {
                configEnabled: false,
                configName: "",
                createConfig: "",
                createMountPath: "",
                configFileName: "",
            },
            storage: {
                createStorage: false,
                storageDes: {
                    storageName: "",
                    size: "",
                    type: "EBS", //Fixed to EBS for now
                    path: ""
                }
            },
            secrets: {
                secretsEnabled: false,
                secretsName: "",
                createSecrets: "",
                createSecretsMountPath: "",
                secretsFileName: "",
            }
        }],
        deployments: {
            deploymentOption: "visual",
            "Deployment": {
                yamlString: yamlContent["Deployment"],
            },
            "Job & CronJob": {
                yamlString: yamlContent["Job & CronJob"]
            },
            "StatefulSet": {
                yamlString: yamlContent["StatefulSet"]
            },
            yamlType: "Deployment",
            yamlData: [],
            port: "",
            customDomain: false,
            domainHostUrl: "",
            domainHostName: "",
            endpointType: "",
            serviceType: "",
            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
            gatewayHttps: {
                gPath: "",
                gPort: "",
                https: false
            },
            loadBalancer: {
                tcp: "",
                udp: "",
                lHttps: ""
            },
            tags: [{
                name: "",
                value: ""
            }],
            resources: {
                cpu: {
                    requestCpu: "",
                    limitCpu: ""
                },
                memory: {
                    requestMem: "",
                    limitMem: ""
                }
            },
            serverPreference: "",

            healthCheckPath: "",
            instanceCapacityType: {
                spot: "",
                onDemand: "",
                //minimumReplica: "",
            },
            // serverPreference: {
            //     compute: "",
            //     serverMemory: "",
            //     gpu: ""
            // },
            taskSchedulers: {
                crons: false,
                queuesAndWorkers: false,
                entryPointCommandCrons: "",
                entryPointCommandQueues: ""
            },
            autoScaling: {
                enabled: false,
                autoScalingData: {
                    minimumReplica: "",
                    maximumReplica: "",
                    autoScalingParameter: {
                        cpuAuto: "",
                        memoryAuto: ""
                    }
                }
            }
        },
        source: "",
        serviceEnabled: true,
        serviceSelectedId: "",

        //description: "",


        dockerRepo: "",
        // envPlainVars: [],
        // envSecretVars: [],
        advancedSettings: {

        }
    };

    let initialMongoDataSourcesData = {
        serviceName: "",
        displayName: "",
        version: "",
        type: "",
        serviceSelectedId: "",
        enbleMultiAzs: false,

        serviceEnabled: true,
        selfManaged: {
            storageType: "EBS",//Fixed to EBS for now
            serviceSelf: false,
            dbType: "",
            username: "",
            password: "",
            autoGenPassword: true,
            enableMetrics: false,
            databaseName: "",
            storageSize: "",
            //enableLogs: "",
            //logRetention: "",
            //typeOfLogs: "",
            enableBackup: "",
            backupRetention: "",
            backupFrequency: "",
            backupDay: "",
            // backupTimingStart: "",
            // backupTimingEnd: "",
            backupInterval: "",
            backupTiming: "",
            enableCrossRegionBackup: false,
            crossRegionBackup: "",
            enableRestore: false,

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        },
        // cloudManaged: {
        //     serviceAws: false,
        //     instanceType: "",
        //     instanceFamily: "",
        //     storageSize: "",
        //     dbType: "",
        //     enablePublicAccess: false,
        //     username: "",
        //     autoGenPassword: true,
        //     password: "",
        //     deletionProtection: false,
        //     databaseName: "",
        //     enableLogs: false,
        //     enableGeneralLogs: false,
        //     enableSlowLogs: false,
        //     logRetention: "",
        //     typeOfLogs: "",
        //     enableBackup: false,
        //     backupRetention: "",
        //     backupTimingStart: "",
        //     backupTimingEnd: "",
        //     backupLocation: "",
        //     kmsEncryption: "",
        //     tlsEncryption: false,
        //     tags: [{ name: "", value: "" }],
        //     maintainanceWindowStart: "",
        //     maintainanceWindowEnd: ""
        // }
    }

    let initialDataSourcesData = {
        serviceName: "",
        displayName: "",
        version: "",
        type: "",
        serviceSelectedId: "",
        enbleMultiAzs: false,

        serviceEnabled: true,
        selfManaged: {
            storageType: "EBS", //Fixed to EBS for now
            serviceSelf: false,
            dbType: "",
            username: "",
            password: "",
            autoGenPassword: true,
            enableMetrics: false,
            databaseName: "",
            storageSize: "",
            masterStorageSize: "",
            replicaStorageSize: "",
            //enableLogs: "",
            //logRetention: "",
            //typeOfLogs: "",
            enableBackup: "",
            backupRetention: "",
            backupFrequency: "",
            backupDay: "",
            // backupTimingStart: "",
            // backupTimingEnd: "",
            backupInterval: "",
            backupTiming: "",
            enableCrossRegionBackup: false,
            crossRegionBackup: "",
            enableRestore: false,

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        },
        cloudManaged: {
            serviceAws: false,
            instanceFamily: "",
            instanceType: "",
            storageSize: "",
            subnetIds: {
                publicSubnets: [],
                privateSubnets: [],
            },
            dbType: "",
            enablePublicAccess: false,
            username: "",
            autoGenPassword: true,
            password: "",
            deletionProtection: false,
            databaseName: "",
            enableLogs: false,
            enableGeneralLogs: false,
            enableSlowLogs: false,
            logRetention: "",
            typeOfLogs: "",
            enableBackup: false,
            backupRetention: "",
            backupTimingStart: "",
            backupTimingEnd: "",
            backupLocation: "",
            backupRegion: "",
            kmsEncryption: "",
            tlsEncryption: false,
            tags: [{ name: "", value: "" }],
            maintenanceWindowStart: "",
            maintenanceWindowEnd: "",
            maintenanceWindowDay: "",

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        }
    };

    const initialRedisData = {
        serviceName: "",
        displayName: "",
        type: "",
        version: "",
        serviceSelectedId: "",
        serviceEnabled: true,
        cloudManaged: {
            instanceFamily: "",
            instanceType: "",
            noOfReplica: "",
            subnetIds: {
                publicSubnets: [],
                privateSubnets: [],
            },
            enableMultiAz: false,
            enableAutomaticFailover: false,
            username: "",
            autoGenPassword: true,
            password: "",
            enableBackup: false,
            backupTimingStart: "",
            backupTimingEnd: "",
            backupRetention: "",
            maintenanceWindowDay: "",
            maintenanceWindowStart: "",
            maintenanceWindowEnd: "",
            serviceCloud: false,
            enableMetrics: false,
            enableAutomaticFailover: false,
            enableRestEncryption: false,
            enableTransitEncryption: false,

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        },
        selfManaged: {
            storageType: "EBS",//Fixed to EBS for now
            serviceSelf: false,
            noOfReplica: "",//db type
            storageSize: "",
            username: "default",
            autoGenPassword: true,
            password: "",
            enableMetrics: false,
            authEnabled: false,

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        }
    }

    const initialPostgresData = {
        serviceName: "",
        displayName: "",
        version: "",
        type: "",
        serviceSelectedId: "",
        serviceEnabled: true,
        selfManaged: {
            storageType: "EBS",//Fixed to EBS for now
            serviceSelf: false,
            dbType: "",
            username: "",
            password: "",
            autoGenPassword: true,
            //enableMetrics: false,
            databaseName: "",
            storageSize: "",
            enableExporter: false,
            enableBackup: "",
            backupRetention: "",
            backupFrequency: "",
            backupDay: "",
            // backupTimingStart: "",
            // backupTimingEnd: "",
            backupInterval: "",
            backupTiming: "",
            enableCrossRegionBackup: false,
            crossRegionBackup: "",
            enableRestore: false,

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        },
        cloudManaged: {
            serviceAws: false,
            instanceFamily: "",
            instanceType: "",
            storageSize: "",
            subnetIds: {
                publicSubnets: [],
                privateSubnets: [],
            },
            dbType: "",
            enablePublicAccess: false,
            username: "",
            autoGenPassword: true,
            password: "",
            deletionProtection: false,
            databaseName: "",
            enableLogs: false,
            enableGeneralLogs: false,
            enableSlowLogs: false,
            logRetention: "",
            typeOfLogs: "",
            enableBackup: false,
            backupRetention: "",
            backupTimingStart: "",
            backupTimingEnd: "",
            backupLocation: "",
            kmsEncryption: "",
            tlsEncryption: false,
            tags: [{ name: "", value: "" }],
            maintenanceWindowStart: "",
            maintenanceWindowEnd: "",
            maintenanceWindowDay: "",

            nodeGroupName: "",
            nodeGroupType: "",
            multiselectNodeGroupList: "",
            listOfNodegroups: [],
        }
    };

    const snakeToCamel = {
        name: "displayName",
        record_prefix: "domainHostUrl",
        yaml_content: "yamlData"
    };
    let impFieldConvert = {
        "password": "password",
        "record_prefix": "domainHostUrl",
        "name": "displayName",
        "nodegroup_name": "nodeGroupName",
        "namespace": "clusterNamespace",
        "TF_VAR_custom_user_password": "password",
        "TF_VAR_master_password": "password",
        "custom_database_password": "password",
        "TF_VAR_password": "password",
        "TF_VAR_postgresql_password": "password",
        "container_registry_id": "containerRegistryId",
        "service_type": "endpointType"
    };

    // const handleImportantFields = async (isImportant, formSelected, isPrev) => {
    //     console.log("isImportant and formSelected: ", formSelected, warningsObj, currentForm, currentImportantField, prevImportantField);
    //     /*This section is for the case when we change the service manually. We have to remove all the previously highlighted important fields*/
    //     let myForms = deploymentForms;
    //     if (Object.key[<div style={{ display: "flex", justifyContent: "space-between" }}><div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><img src={impIcon} style={{ height: "12px", width: "12px", marginRight: "5px" }} />This field is required.</div>s(warningsObj).length > 0 && warningsObj[formSelected]) {
    //         let arr = Object.entries(warningsObj[formSelected]).map(([field, err]) => ({
    //             name: field,//snakeToCamel[field],
    //             warnings: []
    //         }));
    //         console.log("My form: ", myForms[formSelected]);
    //         myForms[formSelected].setFields(arr);
    //         //setWarningsObj({});
    //     }
    //     let allForms = [];
    //     // Object.keys(data.error_details.services).map((val) => {
    //     //     if (Object.keys(data.error_details.services[val]).length > 0) {
    //     //         myErrors = {
    //     //             ...myErrors,
    //     //             [val]: data.error_details.services[val]
    //     //         }
    //     //     }
    //     // });
    //     console.log("Deployment forms: ", deploymentForms);

    //     let applicationServicesList = !cloneDep ? Object.values(applicationData) : Object.values(cloneApplicationData);
    //     console.log("App Services: ", applicationServicesList);
    //     applicationServicesList.map((val) => {
    //         if (val.serviceEnabled === true) {
    //             const form = deploymentForms[val.basicDetails.serviceName];
    //             allForms.push({
    //                 serviceSelected: val.basicDetails.serviceName,
    //                 form: form
    //             });
    //         }
    //     });

    //     console.log("All forms 1: ", allForms);

    //     let dataSourcesMySqlList = !cloneDep ? Object.values(dataSourcesData) : Object.values(cloneDataSourcesData);
    //     dataSourcesMySqlList.map((val) => {
    //         if (val.serviceEnabled) {
    //             console.log("val.displayName", val);
    //             const form = deploymentForms[val.serviceName];
    //             allForms.push({
    //                 serviceSelected: val.serviceName,
    //                 form: form
    //             });
    //         }
    //     });

    //     console.log("Mysql values: ", allForms);

    //     let dataSourcesMongoList = !cloneDep ? Object.values(mongoDataSourcesData) : Object.values(cloneMongoDataSourcesData);
    //     dataSourcesMongoList.map((val) => {
    //         if (val.serviceEnabled) {
    //             console.log("val.displayName", val);
    //             const form = deploymentForms[val.serviceName];
    //             allForms.push({
    //                 serviceSelected: val.serviceName,
    //                 form: form
    //             });
    //         }
    //     });

    //     let redisList = !cloneDep ? Object.values(redisData) : Object.values(cloneRedisData);
    //     console.log("Redis data: ", cloneRedisData);
    //     redisList.map((val) => {
    //         if (val.serviceEnabled) {
    //             console.log("Val.data: ", val.cloudManaged.maintenanceWindowEnd);
    //             const form = deploymentForms[val.serviceName];
    //             allForms.push({
    //                 serviceSelected: val.serviceName,
    //                 form: form
    //             });
    //         }
    //     });

    //     console.log("Entered here");

    //     let postgresList = !cloneDep ? Object.values(postgresDataSourcesData) : Object.values(clonePostgresDataSourcesData);
    //     postgresList.map((val) => {
    //         if (val.serviceEnabled) {
    //             const form = deploymentForms[val.serviceName];
    //             allForms.push({
    //                 serviceSelected: val.serviceName,
    //                 form: form
    //             });
    //         }
    //     });
    //     console.log("first form: ", location.pathname.split('/').slice(-1)[0]);
    //     const form = deploymentForms[location.pathname.split('/').slice(-1)[0]];
    //     allForms.push({
    //         serviceSelected: location.pathname.split('/').slice(-1)[0],
    //         form: form
    //     });

    //     let fields = importantFields;
    //     let myErrors = {};
    //     let initialErrors = {};
    //     console.log("entering loop", fields, allForms);

    //     if (!isPrev) {
    //         Object.keys(fields).map((val, myInd) => {
    //             console.log("Loop entered", val, allForms);
    //             let myFields = Object.keys(fields).splice(0, 1);
    //             let ele = fields[val];
    //             if (formSelected !== location.pathname.split('/').slice(-1)[0]) {
    //                 if (val === formSelected) {
    //                     let formFind = allForms.find((form) => form.serviceSelected === val);
    //                     console.log("Setting the form");
    //                     setCurrentForm(formSelected);
    //                     if (impFieldConvert[ele[0]] === "containerRegistryId" && selectedEnv.cluster_unique_id !== cloneDeploymentData.clusterId) {
    //                         setCurrentImportantField(impFieldConvert[ele[0]]);
    //                         setPrevImportantField("");
    //                         setNextImportantField("");
    //                         //setNextImportantField(impFieldConvert[ele[1]]);
    //                     }
    //                     else if (impFieldConvert[ele[0]] === "containerRegistryId" && selectedEnv.cluster_unique_id === cloneDeploymentData.clusterId) {
    //                         if (impFieldConvert[ele[1]] in formFind.form.getFieldsValue()) {
    //                             setCurrentImportantField(impFieldConvert[ele[1]]);
    //                             setPrevImportantField("");
    //                             setNextImportantField("");
    //                             console.log("Moving to the first one: ", impFieldConvert[ele[1]] in formFind.form.getFieldsValue());
    //                             formFind.form.scrollToField(impFieldConvert[ele[1]], {
    //                                 behavior: 'smooth',
    //                                 block: 'center',
    //                                 inline: 'center',
    //                             });
    //                         }
    //                         else {
    //                             //return;
    //                             return;
    //                         }
    //                     }
    //                     else {
    //                         setCurrentImportantField(impFieldConvert[ele[0]]);
    //                         setPrevImportantField("");
    //                         setNextImportantField("");
    //                     }
    //                 }
    //             }
    //             // else {
    //             //     console.log("Ele: ", ele[1]);
    //             //     if (val === formSelected) {
    //             //         setCurrentImportantField(impFieldConvert[ele[1]]);
    //             //     }
    //             // }
    //             //ele.forEach((x, ind) => {
    //             for (let ind = 0; ind < ele.length; ind++) {
    //                 let formFind = allForms.find((form) => form.serviceSelected === val);
    //                 console.log("Form find: ", formFind);
    //                 if (formFind) {
    //                     console.log("Form find: ", formFind, location.pathname.split('/').slice(-1)[0]);
    //                     myErrors = {
    //                         ...myErrors,
    //                         [val]: {
    //                             ...myErrors[val],
    //                             [val === location.pathname.split('/').slice(-1)[0] ? (ele[ind] === "name" ? "envName" : impFieldConvert[ele[ind]]) : impFieldConvert[ele[ind]]]: [<div style={{ display: "flex", justifyContent: "space-between" }}><div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><img src={impIcon} style={{ height: "12px", width: "12px", marginRight: "5px" }} />This field is required.</div>
    //                                 <div>
    //                                     {(ind !== 0 || (ind === 0 && val !== location.pathname.split('/').slice(-1)[0])) && <Button style={{ marginTop: "5px", marginRight: "10px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, initialErrors);
    //                                         if (ind !== 0) {
    //                                             let prevSetter = ele[ind - 1] === "name" && formSelected === location.pathname.split("/").slice(-1)[0] ? "envName" : impFieldConvert[ele[ind - 1]];
    //                                             console.log("Imp field prev: ", prevSetter, formFind.form.getFieldsValue());
    //                                             if ((prevSetter in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(prevSetter?.input?.disabled && val === formSelected)) {
    //                                                 console.log("Element: ", formSelected, prevSetter);
    //                                                 formFind.form.scrollToField(prevSetter, {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors, initialErrors);
    //                                                 if (prevSetter === "envName") {
    //                                                     setNextImportantField("clusterNamespace");
    //                                                     myErrors[formSelected]["clusterNamespace"] = [];
    //                                                     console.log("Form selected errors: ", initialErrors);
    //                                                     myErrors[formSelected]["envName"] = initialErrors[formSelected]["envName"];
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && prevSetter === "containerRegistryId" && selectedEnv.cluster_unique_id === cloneDeploymentData.clusterId) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else {
    //                                                     setNextImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][prevSetter] = initialErrors[formSelected][prevSetter];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected, myErrors);
    //                                                 setCurrentImportantField(prevSetter);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, prevSetter, warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind--;
    //                                                 if (ind === 0) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind - 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(prevSetter, {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setNextImportantField(impField);
    //                                                     setCurrentImportantField(prevSetter);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                             // for(let i = ind + 1; i < ele.length; i++){
    //                                             //     console.log("Field exists: ", ele[i], impFieldConvert[ele[i]] in formFind.form.getFieldsValue());
    //                                             //     if(impFieldConvert[ele[i]] in formFind.form.getFieldsValue()){
    //                                             //         ind = i;
    //                                             //         formFind.form.scrollToField(impFieldConvert[ele[i]], {
    //                                             //             behavior: 'smooth',
    //                                             //             block: 'center',
    //                                             //             inline: 'center',
    //                                             //         });
    //                                             //         return;
    //                                             //     }
    //                                             //     else {
    //                                             //         console.log("i value: ", i)
    //                                             //         ind = i;
    //                                             //     }
    //                                             // }
    //                                         }
    //                                         else {
    //                                             //console.log("last Element: ", prevSetter);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setPrevBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}><img src={rightIcon} style={{ marginRight: "5px", height: "5px", width: "5px", transform: "rotate(180deg)" }} />Previous </div></Button>}
    //                                     <Button style={{ marginTop: "5px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, myErrors, initialErrors);
    //                                         if (ind !== ele.length - 1) {
    //                                             console.log("Imp field: ", impFieldConvert[ele[ind + 1]]);
    //                                             if ((impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                 console.log("Element: ", formFind.form.getFieldsValue(), impFieldConvert[ele[ind + 1]]);
    //                                                 formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors);
    //                                                 if (impFieldConvert[ele[ind + 1]] === "clusterNamespace") {
    //                                                     setPrevImportantField("envName");
    //                                                     myErrors[formSelected]["envName"] = [];
    //                                                     myErrors[formSelected]["clusterNamespace"] = initialErrors[formSelected]["clusterNamespace"];
    //                                                     console.log("Setting namespace: ", initialErrors[formSelected]);
    //                                                 }
    //                                                 else {
    //                                                     setPrevImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind + 1]]] = initialErrors[formSelected][impFieldConvert[ele[ind + 1]]];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected);
    //                                                 setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, impFieldConvert[ele[ind + 1]], warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind++;
    //                                                 if (ind === ele.length - 1) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setNextBtn(true);
    //                                                 }
    //                                                 else if (impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setPrevImportantField(impField);
    //                                                     setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                         }
    //                                         else {
    //                                             console.log("last Element: ", impFieldConvert[ele[ind + 1]]);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setWarningsObj({
    //                                                 ...myErrors,
    //                                                 [formSelected]: {
    //                                                     ...myErrors[formSelected],
    //                                                     [impFieldConvert[ele[ind]]]: []
    //                                                 }
    //                                             });
    //                                             setNextBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}>Next <img src={rightIcon} style={{ marginLeft: "5px", height: "5px", width: "5px" }} /></div></Button>
    //                                 </div>
    //                             </div>],
    //                         }
    //                     };

    //                     initialErrors = {
    //                         ...initialErrors,
    //                         [val]: {
    //                             ...initialErrors[val],
    //                             [val === location.pathname.split('/').slice(-1)[0] ? (ele[ind] === "name" ? "envName" : impFieldConvert[ele[ind]]) : impFieldConvert[ele[ind]]]: [<div style={{ display: "flex", justifyContent: "space-between" }}><div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><img src={impIcon} style={{ height: "12px", width: "12px", marginRight: "5px" }} />This field is required.</div>
    //                                 <div>
    //                                     {(ind !== 0 /*|| (ind === 0 && val !== location.pathname.split('/').slice(-1)[0])*/) && <Button style={{ marginTop: "5px", marginRight: "10px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, initialErrors);
    //                                         if (ind !== 0) {
    //                                             let prevSetter = ele[ind - 1] === "name" && formSelected === location.pathname.split("/").slice(-1)[0] ? "envName" : impFieldConvert[ele[ind - 1]];
    //                                             console.log("Imp field prev: ", prevSetter, formFind.form.getFieldsValue());
    //                                             if ((prevSetter in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(prevSetter?.input?.disabled && val === formSelected)) {
    //                                                 console.log("Element: ", formSelected, prevSetter);
    //                                                 formFind.form.scrollToField(prevSetter, {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors, initialErrors);
    //                                                 if (prevSetter === "envName") {
    //                                                     setNextImportantField("clusterNamespace");
    //                                                     myErrors[formSelected]["clusterNamespace"] = [];
    //                                                     console.log("Form selected errors: ", initialErrors);
    //                                                     myErrors[formSelected]["envName"] = initialErrors[formSelected]["envName"];
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && prevSetter === "containerRegistryId" && selectedEnv.cluster_unique_id === cloneDeploymentData.clusterId) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else {
    //                                                     setNextImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][prevSetter] = initialErrors[formSelected][prevSetter];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected, myErrors);
    //                                                 setCurrentImportantField(prevSetter);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, prevSetter, warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind--;
    //                                                 if (ind === 0) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind - 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(prevSetter, {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setNextImportantField(impField);
    //                                                     setCurrentImportantField(prevSetter);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                         }
    //                                         else {
    //                                             //console.log("last Element: ", prevSetter);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setPrevBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}><img src={rightIcon} style={{ marginRight: "5px", height: "5px", width: "5px", transform: "rotate(180deg)" }} />Previous </div></Button>}
    //                                     <Button style={{ marginTop: "5px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, myErrors, initialErrors);
    //                                         if (ind !== ele.length - 1) {
    //                                             console.log("Imp field: ", impFieldConvert[ele[ind + 1]]);
    //                                             if ((impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                 console.log("Element: ", formFind.form.getFieldsValue(), impFieldConvert[ele[ind + 1]]);
    //                                                 formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors);
    //                                                 if (impFieldConvert[ele[ind + 1]] === "clusterNamespace") {
    //                                                     setPrevImportantField("envName");
    //                                                     myErrors[formSelected]["envName"] = [];
    //                                                     myErrors[formSelected]["clusterNamespace"] = initialErrors[formSelected]["clusterNamespace"];
    //                                                     console.log("Setting namespace: ", initialErrors[formSelected]);
    //                                                 }
    //                                                 else {
    //                                                     setPrevImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind + 1]]] = initialErrors[formSelected][impFieldConvert[ele[ind + 1]]];
    //                                                     //myErrors[formSelected][impFieldConvert[ele[ind + 1]]] = initialErrors[formSelected][impFieldConvert[ele[ind + 1]]];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected);
    //                                                 setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, impFieldConvert[ele[ind + 1]], warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind++;
    //                                                 if (ind === ele.length - 1) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setNextBtn(true);
    //                                                 }
    //                                                 else if (impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setPrevImportantField(impField);
    //                                                     setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                         }
    //                                         else {
    //                                             console.log("last Element: ", impFieldConvert[ele[ind + 1]]);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setWarningsObj({
    //                                                 ...myErrors,
    //                                                 [formSelected]: {
    //                                                     ...myErrors[formSelected],
    //                                                     [impFieldConvert[ele[ind]]]: []
    //                                                 }
    //                                             });
    //                                             setNextBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}>Next <img src={rightIcon} style={{ marginLeft: "5px", height: "5px", width: "5px" }} /></div></Button>
    //                                 </div>
    //                             </div>],
    //                         }
    //                     };
    //                 }
    //             }//);
    //         });
    //     }
    //     else {
    //         Object.keys(fields).map((val, myInd) => {
    //             console.log("Loop entered", val, allForms);
    //             let ele = fields[val];

    //             if (formSelected !== location.pathname.split('/').slice(-1)[0]) {
    //                 if (val === formSelected) {
    //                     let formFind = allForms.find((form) => form.serviceSelected === val);
    //                     console.log("Setting the form");
    //                     setCurrentForm(formSelected);
    //                     if (impFieldConvert[ele[1]] === "domainHostUrl" && impFieldConvert[ele[1]] in formFind.form.getFieldsValue()) {
    //                         setCurrentImportantField(impFieldConvert[ele[1]]);
    //                         setPrevImportantField("");
    //                         setNextImportantField("");
    //                         //setNextImportantField(impFieldConvert[ele[1]]);
    //                     }
    //                     else if (impFieldConvert[ele[1]] === "domainHostUrl" && !(impFieldConvert[ele[1]] in formFind.form.getFieldsValue())) {
    //                         if (impFieldConvert[ele[0]] in formFind.form.getFieldsValue() && selectedEnv.cluster_unique_id !== cloneDeploymentData.clusterId) {
    //                             setCurrentImportantField(impFieldConvert[ele[0]]);
    //                             setPrevImportantField("");
    //                             setNextImportantField("");
    //                             console.log("Moving to the first one: ", impFieldConvert[ele[1]] in formFind.form.getFieldsValue());
    //                             formFind.form.scrollToField(impFieldConvert[ele[0]], {
    //                                 behavior: 'smooth',
    //                                 block: 'center',
    //                                 inline: 'center',
    //                             });
    //                         }
    //                         else {
    //                             //return;
    //                             return;
    //                         }
    //                     }
    //                     else {
    //                         if (impFieldConvert[ele[0]] in formFind.form.getFieldsValue() && selectedEnv.cluster_unique_id !== cloneDeploymentData.clusterId) {
    //                             setCurrentImportantField(impFieldConvert[ele[0]]);
    //                             setPrevImportantField("");
    //                             setNextImportantField("");
    //                         }
    //                         else {
    //                             return;
    //                         }
    //                     }
    //                 }
    //             }

    //             for (let ind = ele[ele.length - 1]; ind >= 0; ind--) {
    //                 let formFind = allForms.find((form) => form.serviceSelected === val);
    //                 console.log("Form find: ", formFind);
    //                 if (formFind) {
    //                     console.log("Form find: ", formFind, location.pathname.split('/').slice(-1)[0]);
    //                     myErrors = {
    //                         ...myErrors,
    //                         [val]: {
    //                             ...myErrors[val],
    //                             [val === location.pathname.split('/').slice(-1)[0] ? (ele[ind] === "name" ? "envName" : impFieldConvert[ele[ind]]) : impFieldConvert[ele[ind]]]: [<div style={{ display: "flex", justifyContent: "space-between" }}><div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><img src={impIcon} style={{ height: "12px", width: "12px", marginRight: "5px" }} />This field is required.</div>
    //                                 <div>
    //                                     {(ind !== 0 || (ind === 0 && val !== location.pathname.split('/').slice(-1)[0])) && <Button style={{ marginTop: "5px", marginRight: "10px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, initialErrors);
    //                                         if (ind !== 0) {
    //                                             let prevSetter = ele[ind - 1] === "name" && formSelected === location.pathname.split("/").slice(-1)[0] ? "envName" : impFieldConvert[ele[ind - 1]];
    //                                             console.log("Imp field prev: ", prevSetter, formFind.form.getFieldsValue());
    //                                             if ((prevSetter in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(prevSetter?.input?.disabled && val === formSelected)) {
    //                                                 console.log("Element: ", formSelected, prevSetter);
    //                                                 formFind.form.scrollToField(prevSetter, {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors, initialErrors);
    //                                                 if (prevSetter === "envName") {
    //                                                     setNextImportantField("clusterNamespace");
    //                                                     myErrors[formSelected]["clusterNamespace"] = [];
    //                                                     console.log("Form selected errors: ", initialErrors);
    //                                                     myErrors[formSelected]["envName"] = initialErrors[formSelected]["envName"];
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && prevSetter === "containerRegistryId" && selectedEnv.cluster_unique_id === cloneDeploymentData.clusterId) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else {
    //                                                     setNextImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][prevSetter] = initialErrors[formSelected][prevSetter];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected, myErrors);
    //                                                 setCurrentImportantField(prevSetter);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, prevSetter, warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind--;
    //                                                 if (ind === 0) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind - 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(prevSetter, {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setNextImportantField(impField);
    //                                                     setCurrentImportantField(prevSetter);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                             // for(let i = ind + 1; i < ele.length; i++){
    //                                             //     console.log("Field exists: ", ele[i], impFieldConvert[ele[i]] in formFind.form.getFieldsValue());
    //                                             //     if(impFieldConvert[ele[i]] in formFind.form.getFieldsValue()){
    //                                             //         ind = i;
    //                                             //         formFind.form.scrollToField(impFieldConvert[ele[i]], {
    //                                             //             behavior: 'smooth',
    //                                             //             block: 'center',
    //                                             //             inline: 'center',
    //                                             //         });
    //                                             //         return;
    //                                             //     }
    //                                             //     else {
    //                                             //         console.log("i value: ", i)
    //                                             //         ind = i;
    //                                             //     }
    //                                             // }
    //                                         }
    //                                         else {
    //                                             //console.log("last Element: ", prevSetter);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setPrevBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}><img src={rightIcon} style={{ marginRight: "5px", height: "5px", width: "5px", transform: "rotate(180deg)" }} />Previous </div></Button>}
    //                                     <Button style={{ marginTop: "5px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, myErrors, initialErrors);
    //                                         if (ind !== ele.length - 1) {
    //                                             console.log("Imp field: ", impFieldConvert[ele[ind + 1]]);
    //                                             if ((impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                 console.log("Element: ", formFind.form.getFieldsValue(), impFieldConvert[ele[ind + 1]]);
    //                                                 formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors);
    //                                                 if (impFieldConvert[ele[ind + 1]] === "clusterNamespace") {
    //                                                     setPrevImportantField("envName");
    //                                                     myErrors[formSelected]["envName"] = [];
    //                                                     myErrors[formSelected]["clusterNamespace"] = initialErrors[formSelected]["clusterNamespace"];
    //                                                     console.log("Setting namespace: ", initialErrors[formSelected]);
    //                                                 }
    //                                                 else {
    //                                                     setPrevImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind + 1]]] = initialErrors[formSelected][impFieldConvert[ele[ind + 1]]];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected);
    //                                                 setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, impFieldConvert[ele[ind + 1]], warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind++;
    //                                                 if (ind === ele.length - 1) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setNextBtn(true);
    //                                                 }
    //                                                 else if (impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setPrevImportantField(impField);
    //                                                     setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                         }
    //                                         else {
    //                                             console.log("last Element: ", impFieldConvert[ele[ind + 1]]);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setWarningsObj({
    //                                                 ...myErrors,
    //                                                 [formSelected]: {
    //                                                     ...myErrors[formSelected],
    //                                                     [impFieldConvert[ele[ind]]]: []
    //                                                 }
    //                                             });
    //                                             setNextBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}>Next <img src={rightIcon} style={{ marginLeft: "5px", height: "5px", width: "5px" }} /></div></Button>
    //                                 </div>
    //                             </div>],
    //                         }
    //                     };

    //                     initialErrors = {
    //                         ...initialErrors,
    //                         [val]: {
    //                             ...initialErrors[val],
    //                             [val === location.pathname.split('/').slice(-1)[0] ? (ele[ind] === "name" ? "envName" : impFieldConvert[ele[ind]]) : impFieldConvert[ele[ind]]]: [<div style={{ display: "flex", justifyContent: "space-between" }}><div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><img src={impIcon} style={{ height: "12px", width: "12px", marginRight: "5px" }} />This field is required.</div>
    //                                 <div>
    //                                     {(ind !== 0 || (ind === 0 && val !== location.pathname.split('/').slice(-1)[0])) && <Button style={{ marginTop: "5px", marginRight: "10px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, initialErrors);
    //                                         if (ind !== 0) {
    //                                             let prevSetter = ele[ind - 1] === "name" && formSelected === location.pathname.split("/").slice(-1)[0] ? "envName" : impFieldConvert[ele[ind - 1]];
    //                                             console.log("Imp field prev: ", prevSetter, formFind.form.getFieldsValue());
    //                                             if ((prevSetter in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(prevSetter?.input?.disabled && val === formSelected)) {
    //                                                 console.log("Element: ", formSelected, prevSetter);
    //                                                 formFind.form.scrollToField(prevSetter, {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors, initialErrors);
    //                                                 if (prevSetter === "envName") {
    //                                                     setNextImportantField("clusterNamespace");
    //                                                     myErrors[formSelected]["clusterNamespace"] = [];
    //                                                     console.log("Form selected errors: ", initialErrors);
    //                                                     myErrors[formSelected]["envName"] = initialErrors[formSelected]["envName"];
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && prevSetter === "containerRegistryId" && selectedEnv.cluster_unique_id === cloneDeploymentData.clusterId) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else {
    //                                                     setNextImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][prevSetter] = initialErrors[formSelected][prevSetter];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected, myErrors);
    //                                                 setCurrentImportantField(prevSetter);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, prevSetter, warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind--;
    //                                                 if (ind === 0) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setPrevBtn(true);
    //                                                 }
    //                                                 else if (prevSetter in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind - 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(prevSetter, {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setNextImportantField(impField);
    //                                                     setCurrentImportantField(prevSetter);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                         }
    //                                         else {
    //                                             //console.log("last Element: ", prevSetter);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setPrevBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}><img src={rightIcon} style={{ marginRight: "5px", height: "5px", width: "5px", transform: "rotate(180deg)" }} />Previous </div></Button>}
    //                                     <Button style={{ marginTop: "5px", display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
    //                                         console.log("service pos: ", ind, formSelected, warningsObj, myErrors, initialErrors);
    //                                         if (ind !== ele.length - 1) {
    //                                             console.log("Imp field: ", impFieldConvert[ele[ind + 1]]);
    //                                             if ((impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue()) && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                 console.log("Element: ", formFind.form.getFieldsValue(), impFieldConvert[ele[ind + 1]]);
    //                                                 formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                     behavior: 'smooth',
    //                                                     block: 'center',
    //                                                     inline: 'center',
    //                                                 });
    //                                                 console.log("My values: ", warningsObj, formSelected, impFieldConvert[ele[ind]], myErrors);
    //                                                 if (impFieldConvert[ele[ind + 1]] === "clusterNamespace") {
    //                                                     setPrevImportantField("envName");
    //                                                     myErrors[formSelected]["envName"] = [];
    //                                                     myErrors[formSelected]["clusterNamespace"] = initialErrors[formSelected]["clusterNamespace"];
    //                                                     console.log("Setting namespace: ", initialErrors[formSelected]);
    //                                                 }
    //                                                 else {
    //                                                     setPrevImportantField(impFieldConvert[ele[ind]]);
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind]]] = [];
    //                                                     myErrors[formSelected][impFieldConvert[ele[ind + 1]]] = initialErrors[formSelected][impFieldConvert[ele[ind + 1]]];
    //                                                     //myErrors[formSelected][impFieldConvert[ele[ind + 1]]] = initialErrors[formSelected][impFieldConvert[ele[ind + 1]]];
    //                                                 }
    //                                                 setWarningsObj({ ...myErrors });
    //                                                 console.log("Updated formSel: ", formSelected);
    //                                                 setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                 setCurrentForm(formFind.serviceSelected);
    //                                                 //}
    //                                             }
    //                                             else {
    //                                                 console.log("enters Element: ", formSelected, impFieldConvert[ele[ind + 1]], warningsObj);
    //                                                 let impField = impFieldConvert[ele[ind]];
    //                                                 console.log("Index and element: ", ind, ele[ind], impField);
    //                                                 ind++;
    //                                                 if (ind === ele.length - 1) {
    //                                                     setCurrentImportantField("");
    //                                                     setPrevImportantField("");
    //                                                     setNextImportantField("");
    //                                                     setNextBtn(true);
    //                                                 }
    //                                                 else if (impFieldConvert[ele[ind + 1]] in formFind.form.getFieldsValue() && !formFind.form.getFieldInstance(impFieldConvert[ele[ind + 1]])?.input?.disabled && val === formSelected) {
    //                                                     formFind.form.scrollToField(impFieldConvert[ele[ind + 1]], {
    //                                                         behavior: 'smooth',
    //                                                         block: 'center',
    //                                                         inline: 'center',
    //                                                     });
    //                                                     console.log("Scrolling to this section...", formSelected, warningsObj);
    //                                                     setWarningsObj({
    //                                                         ...myErrors,
    //                                                         [formSelected]: {
    //                                                             ...myErrors[formSelected],
    //                                                             [impField]: []
    //                                                         }
    //                                                     });
    //                                                     setPrevImportantField(impField);
    //                                                     setCurrentImportantField(impFieldConvert[ele[ind + 1]]);
    //                                                     setCurrentForm(formFind.serviceSelected);
    //                                                 }
    //                                             }
    //                                         }
    //                                         else {
    //                                             console.log("last Element: ", impFieldConvert[ele[ind + 1]]);
    //                                             setCurrentImportantField("");
    //                                             setPrevImportantField("");
    //                                             setNextImportantField("");
    //                                             setWarningsObj({
    //                                                 ...myErrors,
    //                                                 [formSelected]: {
    //                                                     ...myErrors[formSelected],
    //                                                     [impFieldConvert[ele[ind]]]: []
    //                                                 }
    //                                             });
    //                                             setNextBtn(true);
    //                                         }
    //                                     }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}>Next <img src={rightIcon} style={{ marginLeft: "5px", height: "5px", width: "5px" }} /></div></Button>
    //                                 </div>
    //                             </div>],
    //                         }
    //                     };
    //                 }
    //             }//);
    //         });
    //     }
    //     console.log("My ERR Final: ", myErrors);
    //     setWarningsObj(myErrors);
    //     setNextImportantField("");
    //     setPrevImportantField("");
    //     //initialErrors = JSON.parse(JSON.stringify({...myErrors}));
    //     console.log("All Forms: ", allForms, myErrors, initialErrors);
    //     //setFormErrors(myErrors);
    // }

    let warnings = (removePrev, removeNext, importantFields, handlePrevious, handleNext, currentIdx) => {
        return [<div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                <img src={impIcon} style={{ height: "12px", width: "12px", marginRight: "5px" }} />This field is required.</div>
            <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                {!removePrev && <Button style={{ display: "end", marginRight: "10px", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
                    handlePrevious(importantFields, currentIdx);
                }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}><img src={rightIcon} style={{ marginRight: "5px", height: "5px", width: "5px", transform: "rotate(180deg)" }} />Previous </div></Button>}
                {!removeNext && <Button style={{ display: "end", padding: "0 5px", alignItems: "center", border: "0.5px solid #132f44", borderRadius: "5px", height: "fit-content" }} onClick={() => {
                    handleNext(importantFields, currentIdx);
                }}><div style={{ display: "flex", alignItems: "center", padding: "0", fontSize: "12px" }}>Next <img src={rightIcon} style={{ marginLeft: "5px", height: "5px", width: "5px" }} /></div></Button>}
            </div>
        </div>];
    };
    const validateYamls = async (appList) => {
        console.log("My app list: ", appList);
        const promises = appList.map((val, idx) => val.deployments.deploymentOption === "yaml" && validateYaml(val.deployments[val.deployments.yamlType].yamlString)
            .then((doc) => ({ idx, serviceName: val.basicDetails.serviceName, data: doc }))
            .catch((error) => ({ idx, serviceName: val.basicDetails.serviceName, error: error })
            ));

        const results = await Promise.all(promises);
        console.log("My results: ", results);
        return results;
    }
    const handleSubmit = async () => {
        //let servicePayload = [];
        if (!addDep) {
            let allForms = [], allErrors = [];
            let myErrors = {};
            let checked = true;
            let applicationServicesList = !cloneDep ? Object.values(applicationData) : Object.values(cloneApplicationData);
            let servicePayload = [];


            applicationServicesList.map((val) => {
                if (val.serviceEnabled === true) {
                    const form = deploymentForms[val.basicDetails.serviceName];
                    allForms.push({
                        serviceSelected: val.basicDetails.serviceName,
                        form: form
                    });
                }
            });

            let yamls = await validateYamls(applicationServicesList);

            let yamlError = false;

            yamls.forEach((val, idx) => {
                if (val && val.data && idx === val.idx) {
                    applicationServicesList[idx].deployments.yamlData = [...val.data];
                }
                else if (val && applicationServicesList[idx].serviceEnabled) {
                    myErrors = {
                        ...myErrors,
                        [val.serviceName]: "Your YAML code provided is invalid."
                    };
                    allErrors = [...allErrors, {
                        serviceSelected: val.serviceName,
                        error: "Your YAML code provided is invalid."
                    }];
                    yamlError = true;
                }
            });

            console.log("MY YAMLS: ", yamls);

            let dataSourcesMySqlList = !cloneDep ? Object.values(dataSourcesData) : Object.values(cloneDataSourcesData);
            dataSourcesMySqlList.map((val) => {
                if (val.serviceEnabled) {
                    console.log("val.displayName", val);
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            console.log("Mysql values: ", allForms);

            let dataSourcesMongoList = !cloneDep ? Object.values(mongoDataSourcesData) : Object.values(cloneMongoDataSourcesData);
            dataSourcesMongoList.map((val) => {
                if (val.serviceEnabled) {
                    console.log("val.displayName", val);
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            let redisList = !cloneDep ? Object.values(redisData) : Object.values(cloneRedisData);
            console.log("Redis data: ",);
            redisList.map((val) => {
                if (val.serviceEnabled) {
                    console.log("Val.data: ", val.cloudManaged.maintenanceWindowEnd);
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            console.log("Entered here");

            let postgresList = !cloneDep ? Object.values(postgresDataSourcesData) : Object.values(clonePostgresDataSourcesData);
            postgresList.map((val) => {
                if (val.serviceEnabled) {
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            console.log("All forms: ", allForms);

            for (const val of allForms) {
                try {
                    console.log("Try entered", val.form.getFieldsValue());
                    let variable = await val.form.validateFields();
                    console.log("ending validation...", variable);
                }
                catch (error) {
                    console.log("Catch entered");
                    allErrors = [...allErrors, {
                        serviceSelected: val.serviceSelected,
                        error: error
                    }];
                    myErrors = {
                        ...myErrors,
                        [val.serviceSelected]: {
                            error: error
                        }
                    }
                }
            }

            console.log("My errors: ", myErrors);
            setFormErrors(myErrors);

            if (allErrors.length > 0) {
                if (yamlError) {
                    set_alertOptions(alertOptions => ({
                        status: true,
                        type: "error",
                        message: "Environment Creation failed",
                        description: "Please provide correct yaml file.",
                    }));

                    // opacity=0 after 10sec
                    setTimeout(() => {
                        set_alertOptions(alertOptions => ({
                            status: false,
                            type: "error",
                            message: "Environment Creation failed",
                            description: "Please provide correct yaml file.",
                        }));
                    }, 5 * 1000);
                }
                else {
                    console.error('Validation failed: ', allErrors);
                    set_alertOptions(alertOptions => ({
                        status: true,
                        type: "error",
                        message: "Environment Creation failed",
                        description: "Please fill all the fields for the enabled services.",
                    }));

                    // opacity=0 after 10sec
                    setTimeout(() => {
                        set_alertOptions(alertOptions => ({
                            status: false,
                            type: "error",
                            message: "Environment Creation failed",
                            description: "Please fill all the fields for the enabled services.",
                        }));
                    }, 5 * 1000);
                }
            }
            else {
                let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                let myDepData = !cloneDep ? JSON.parse(localStorage.getItem("deploymentData")) : JSON.parse(localStorage.getItem("cloneDeploymentData"));
                if (myDepData && myDepData.projectName !== "" && myDepData.projectId !== 0) {
                    console.log("Validation succeeded");
                    //let servicePayload = [];
                    let applicationServicesList = !cloneDep ? Object.values(applicationData) : Object.values(cloneApplicationData);
                    console.log("My yamls: ", yamls);
                    yamls.forEach((val, idx) => {
                        if (val && val.data && idx === val.idx) {
                            applicationServicesList[idx].deployments.yamlData = [...val.data];
                        }    
                    });
                    console.log("My application service: ", applicationServicesList);
                    let servicePayload = [];
                    applicationServicesList.map((val) => {
                        if (val.serviceEnabled === true) {
                            let myConfigVol = [];
                            let mySecretVol = [];
                            let myStorageVol = [];
                            let myEnvs = val.environments.length > 0 ? (val.environments.length === 1 ? (val.environments[0].name === "" && val.environments[0].value === "") ? [] : val.environments : val.environments) : val.environments;
                            let labels = {};
                            val.deployments.tags.map((val, idx) => {
                                if (val.name !== "" && val.value !== "") {
                                    labels[val.name] = val.value;
                                }
                            })
                            val.volumes.map((ele) => {
                                if (ele.volumeType === "Configmap") {
                                    let rawStr = ele.config.createMountPath.split('/')
                                    let tempFilename = rawStr.pop()  // removing filename from rawStr
                                    let tempMountPath = rawStr.join("/")   // join for mountPath

                                    console.log("tempFilename :", tempFilename)
                                    console.log("tempMountPath :", tempMountPath)

                                    myConfigVol.push({
                                        "name": ele.config.configName,//"test-cm",
                                        "filename": tempFilename,//"index.yaml",
                                        "mountPath": tempMountPath,//"/app",
                                        "data": ele.config.createConfig//"this the configmap data...."
                                    });
                                }
                                else if (ele.volumeType === "Secrets") {
                                    let rawStrSecrets = ele.secrets.createSecretsMountPath.split('/')
                                    let tempFilenameSecrets = rawStrSecrets.pop()  // removing filename from rawStrSecrets
                                    let tempMountPathSecrets = rawStrSecrets.join("/")   // join for mountPath

                                    mySecretVol.push({
                                        "name": ele.secrets.secretsName,//"test-secretqs",
                                        "filename": tempFilenameSecrets,//"index.yaml",
                                        "mountPath": tempMountPathSecrets,//"/app",
                                        "data": ele.secrets.createSecrets//"this the configmap data...."
                                    });
                                }
                                else if (ele.volumeType === "Storage") {
                                    myStorageVol.push({
                                        "name": ele.storage.storageDes.storageName,//"test-secrets",
                                        "size": ele.storage.storageDes.size !== "" ? parseInt(ele.storage.storageDes.size) : 0,//+ 'Gi' : "",//"10Gi",
                                        "mountPath": ele.storage.storageDes.path,//"/app",
                                        "storageClass": ele.storage.storageDes.type.toLowerCase() === "efs" ? 'efs-sc' : ele.storage.storageDes.type.toLowerCase(),//"gp2",
                                        "accessMode": "ReadWriteOnce"
                                    });
                                }
                            });
                            console.log("App details: ", val, (val.deployments.endpointType === "Cluster IP" || val.deployments.serviceType === "Existing Load Balancer") ? "ClusterIP" : !val.deployments.endpointType ? "" : "LoadBalancer");
                            console.log("currentProjectDep: ", currentProjectDep);
                            servicePayload.push({
                                "name": val.basicDetails.appName,
                                "service_id": val.serviceSelectedId,//serviceSelected.id,
                                "cd_values_in_yaml": val.deployments.deploymentOption === "yaml",
                                "config": {
                                    //"container_registry_id": val.basicDetails.containerRegistryId.id,
                                    "docker_file": val.basicDetails.sourceType === "Container Registry" ? undefined : './' + val.basicDetails.dockerFilePath,
                                    "build_context": val.basicDetails.sourceType === "Container Registry" ? undefined : val.basicDetails.buildContext && val.basicDetails.buildContext !== "" ? "./" + val.basicDetails.buildContext : null,
                                    "app_source_type": val.basicDetails.sourceType === "Container Registry" ? 0 : 1,
                                    "container_registry_id": val.basicDetails.appSource === "PUBLIC" ? null : val.basicDetails.sourceType === "Container Registry" ? val.basicDetails.appSource?.id : val.basicDetails.containerRegistryId.id,
                                    "container_registry_type": val.basicDetails.sourceType === "Container Registry" ? val.basicDetails.appSource === "PUBLIC" ? 0 : 1 : undefined,
                                    "repository_name": val.basicDetails.imageRepo,
                                    "image_tag": val.basicDetails.imageTag && val.basicDetails.imageTag !== "" ? val.basicDetails.imageTag : undefined,
                                    "port": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.port) : undefined,
                                    "create_storage": val.deployments.deploymentOption === "visual" ? myStorageVol.length > 0 ? "true" : "false" : undefined,
                                    "create_secret": val.deployments.deploymentOption === "visual" ? mySecretVol.length > 0 ? "true" : "false" : undefined,
                                    "ingress_enabled": val.deployments.deploymentOption === "visual" ? val.deployments.serviceType.includes("Existing Load Balancer") ? "true" : "false" : undefined,//val.deployments.customDomain === true ? "true" : "false",
                                    "ingress_https_enabled": val.deployments.deploymentOption === "visual" ? "true" : undefined,
                                    "ingress_host": val.deployments.deploymentOption === "visual" ? val.deployments.domainHostUrl === "" ? "" : val.deployments.domainHostUrl + '.' + !cloneDep ? deploymentData.dns : cloneDeploymentData.dns : undefined,
                                    "ingress_path": val.deployments.deploymentOption === "visual" ? val.deployments.gatewayHttps.gPath === "" ? "/" : val.deployments.gatewayHttps.gPath : undefined,
                                    "env_variables": myEnvs,
                                    "record_prefix": val.deployments.deploymentOption === "visual" ? val.deployments.serviceType.includes("Existing Load Balancer") ? val.deployments.domainHostUrl : "" : undefined,
                                    "service_type": val.deployments.deploymentOption === "visual" ? (val.deployments.endpointType === "Cluster IP" || val.deployments.serviceType.includes("Existing Load Balancer")) ? "ClusterIP" : !val.deployments.endpointType ? "" : "LoadBalancer" : undefined,//serviceSelected.type,
                                    "service_protocol": val.deployments.deploymentOption === "visual" ? "TCP" : undefined,
                                    "enable_plaintext_env": myEnvs.length > 0 ? "true" : "false",
                                    "enable_secret_env": myEnvs.length > 0 ? "true" : "false",
                                    "vcs_branch": val.basicDetails.branch === "" ? null : val.basicDetails.branch,
                                    "secretfile_enabled": val.deployments.deploymentOption === "visual" ? mySecretVol.length > 0 ? "true" : "false" : undefined,
                                    "create_configmap": val.deployments.deploymentOption === "visual" ? myConfigVol.length > 0 ? "true" : "false" : undefined,
                                    "cpu_request": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.cpu.requestCpu) : undefined, //+ 'm',
                                    "cpu_limit": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.cpu.limitCpu) : undefined, //+ 'm',
                                    "memory_request": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.memory.requestMem) : undefined, //+ 'Mi',
                                    "memory_limit": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.memory.limitMem) : undefined, //+ 'Mi',
                                    "target_cpu_utilization": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto === "" ? 0 : parseInt(val.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto) : undefined, //+ '%',
                                    "target_memory_utilization": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto === "" ? 0 : parseInt(val.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto) : undefined, //+ '%',
                                    "enable_autoscaling": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.enabled === false ? "false" : "true" : undefined,
                                    "maximum_replicas": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.maximumReplica : undefined,
                                    "minimum_replicas": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.minimumReplica : undefined,
                                    "config_files_config": val.deployments.deploymentOption === "visual" ? myConfigVol === 1 ? myConfigVol[0].name === "" ? [] : myConfigVol : myConfigVol : undefined,
                                    "storages_config": val.deployments.deploymentOption === "visual" ? myStorageVol.length === 1 ? myStorageVol[0].name === "" ? [] : myStorageVol : myStorageVol : undefined,
                                    "secret_files_config": val.deployments.deploymentOption === "visual" ? mySecretVol.length === 1 ? mySecretVol[0].name === "" ? [] : mySecretVol : mySecretVol : undefined,
                                    "labels": labels,
                                    "health_check_path": val.deployments.deploymentOption === "visual" ? val.deployments.healthCheckPath : undefined,
                                    "auto_trigger": val.basicDetails.enableAutoTrigger.toString(),
                                    "create_cron": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.crons ? "true" : "false" : undefined,
                                    "create_queue_worker": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.queuesAndWorkers ? "true" : "false" : undefined,
                                    "cron_command": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.entryPointCommandCrons : undefined,
                                    "queue_worker_command": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.entryPointCommandQueues : undefined,
                                    "pass_env_vars_at_build_time": currentProjectDep.tasks.find(x => x.serviceId === val.serviceSelectedId) ? (currentProjectDep.tasks.find(x => x.serviceId === val.serviceSelectedId).envs.some(x => x.injectAt.includes("Build Time") && x.lastAction !== "Delete").toString()) : false,
                                    "nodegroup_name": val.deployments.deploymentOption === "visual" ? val.deployments.nodeGroupName : undefined,
                                    "nodegroup_type": val.deployments.deploymentOption === "visual" ? val.deployments.nodeGroupType : undefined,
                                    "list_of_nodegroups": val.deployments.deploymentOption === "visual" ? val.deployments.listOfNodegroups : undefined,
                                    "ingress_alb_enabled": val.deployments.deploymentOption === "visual" ? val.deployments.serviceType === "Existing Load Balancer(ALB Ingress)" ? "true" : "false" : undefined,//deploymentData.ingressAlbEnabled.toString(),
                                    "yaml_content": val.deployments.deploymentOption === "yaml" ? val.deployments.yamlData : undefined,
                                    "yaml_kind": val.deployments.deploymentOption === "yaml" ? val.deployments.yamlType : undefined
                                },
                                //"context": val.basicDetails.context,
                                // "environments.plaintext.values": appData.envPlainVars,
                                // "environments.secret.values": appData.envSecretVars,
                                // "configmap_file_name": val.volumes[0].config.configFileName,
                                // "configmap_content": val.volumes[0].config.createConfig,
                                // "configmap_file_mountpath": val.volumes[0].config.createMountPath,
                                // "storage_size": val.volumes[0].storage.storageDes.size,
                                // "VolumemountPath": val.volumes[0].storage.storageDes.path,
                                // "storage_class": val.volumes[0].storage.storageDes.type //will be changed later
                            });
                        }
                    });

                    let dataSourcesMySqlAws = !cloneDep ? Object.values(dataSourcesData) : Object.values(cloneDataSourcesData);
                    console.log("data sources: ", dataSourcesMySqlAws);

                    dataSourcesMySqlAws.map((val) => {
                        if (val.serviceEnabled && val.cloudManaged.serviceAws) {
                            servicePayload.push({
                                "name": val.displayName,//"mysql",
                                "service_id": val.serviceSelectedId,//40,
                                "config": {
                                    "db_type": val.type === "AWS Managed MySQL" ? "aws-rds-managed-mysql" : "aws-aurora-managed-mysql",
                                    "TF_VAR_mysql_instance_class": val.cloudManaged.instanceType,//"db.t3.micro" , 
                                    "Instance_family": val.cloudManaged.instanceFamily,
                                    "TF_VAR_mysql_engine_version": val.version,
                                    "tags": val.cloudManaged.tags.length === 1 ? (val.cloudManaged.tags[0].name === "" || val.cloudManaged.tags[0].value === "") ? [] : val.cloudManaged.tags : val.cloudManaged.tags,
                                    "TF_VAR_database_name": val.cloudManaged.databaseName,//"nodeapp",        
                                    "TF_VAR_enable_multi_az": val.cloudManaged.dbType === "Single DB Instance" ? "false" : "true", //"false",
                                    "TF_VAR_master_username": val.cloudManaged.username,//"abhi",
                                    "TF_VAR_storage_size": parseInt(val.cloudManaged.storageSize),//"30",       
                                    "TF_VAR_enable_public_access": val.cloudManaged.enablePublicAccess ? "true" : "false",//"true",
                                    "TF_VAR_skip_final_snapshot": "true",
                                    "TF_VAR_backup_window": (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd,//"03:00-06:00",
                                    "TF_VAR_backup_region": val.cloudManaged.backupRegion,
                                    "TF_VAR_maintenance_window": (val.cloudManaged.maintenanceWindowStart === "" || val.cloudManaged.maintenanceWindowEnd === "") ? "" : val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowStart + '-' + val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowEnd,//"Mon:00:00-Mon:03:00",
                                    "TF_VAR_enable_deletion_protection": val.cloudManaged.deletionProtection ? "true" : "false",//"false",
                                    "TF_VAR_backup_retention_period": val.cloudManaged.backupRetention === "" ? "0" : val.cloudManaged.backupRetention,//"7",
                                    "TF_VAR_enable_general_log": val.cloudManaged.enableGeneralLogs ? "true" : "false",//"true",
                                    "TF_VAR_enable_slow_query_log": val.cloudManaged.enableSlowLogs ? "true" : "false",//"true",
                                    "TF_VAR_create_random_password": val.cloudManaged.autoGenPassword ? "true" : "false",//"true"
                                    "TF_VAR_master_password": val.cloudManaged.password,
                                    "TF_VAR_log_retention_period": val.cloudManaged.logRetention,
                                    //"TF_VAR_enable_backup": val.cloudManaged.enableBackup ? "true" : 'false',
                                    "private_subnet_ids": val.cloudManaged.subnetIds.privateSubnets,
                                    "public_subnet_ids": val.cloudManaged.subnetIds.publicSubnets,
                                }
                            });
                        }
                    });

                    let dataSourcesMySqlSelf = !cloneDep ? Object.values(dataSourcesData) : Object.values(cloneDataSourcesData);
                    console.log("Mysql self: ", dataSourcesMySqlSelf);
                    dataSourcesMySqlSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            console.log("Executes");
                            servicePayload.push({
                                "name": val.displayName,
                                "service_id": val.serviceSelectedId,//3,
                                "config": {
                                    "db_type": "self-managed-mysql",
                                    //"TF_VAR_architecture": val.selfManaged.dbType,//"standalone",
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),//"gp2",
                                    "TF_VAR_username": val.selfManaged.username,//"admin",
                                    "TF_VAR_master_db_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                                    "TF_VAR_secondary_db_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                                    "TF_VAR_secondary_db_replica_count": val.selfManaged.dbType,//"1",
                                    "TF_VAR_backup_enabled": val.selfManaged.enableBackup ? "true" : "false",//"false",
                                    "TF_VAR_bucket_uri": "",
                                    "enable_cross_region_backup": val.selfManaged.enableCrossRegionBackup.toString(),
                                    "TF_VAR_bucket_region": val.selfManaged.crossRegionBackup,//"",
                                    //"TF_VAR_backup_cron": "* * * * *",
                                    "TF_VAR_restore_db": "false",
                                    "TF_VAR_exporter_enable": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                                    "TF_VAR_app_version": val.version,
                                    "TF_VAR_custom_credentials_enabled": !val.selfManaged.autoGenPassword ? "true" : "false",//"true",
                                    "TF_VAR_store_password_to_secret_manager": "false",
                                    "TF_VAR_custom_user_password": val.selfManaged.password,//"password",
                                    "backup_frequency": val.selfManaged.backupFrequency.toLowerCase(),//"hourly",
                                    "backup_interval": val.selfManaged.backupFrequency === "Weekly" ? days.indexOf(val.selfManaged.backupDay) : val.selfManaged.backupFrequency !== "Hourly" ? 1 : val.selfManaged.backupInterval,//3,
                                    "backup_time": val.selfManaged.backupTiming,
                                    "TF_VAR_database_name": val.selfManaged.databaseName,
                                    "TF_VAR_restore_file_name": "",
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "TF_VAR_restore_db": val.selfManaged.enableRestore ? "true" : "false",
                                }
                            });
                        }
                    });

                    let dataSourcesMongoSelf = !cloneDep ? Object.values(mongoDataSourcesData) : Object.values(cloneMongoDataSourcesData);
                    console.log("Mysql self: ", dataSourcesMongoSelf);
                    dataSourcesMongoSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            servicePayload.push({
                                "name": val.displayName,//"dskldss",
                                "service_id": val.serviceSelectedId,//6,
                                "type": "self-managed-mongodb",
                                "config": {
                                    "db_type": "self-managed-mongodb",
                                    //"TF_VAR_architecture": val.selfManaged.dbType.toLowerCase(),//"standalone",
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),                            //"TF_VAR_username": val.selfManaged.username,//"admin",
                                    "TF_VAR_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                                    //"TF_VAR_secondary_db_volume_size": val.selfManaged.storageSize === "" ? "" : val.selfManaged.storageSize + 'Gi',//"10Gi",
                                    "TF_VAR_replica_count": val.selfManaged.dbType === "" ? "" : val.selfManaged.dbType,
                                    //"TF_VAR_replica_count": "1",
                                    "TF_VAR_backup_enabled": val.selfManaged.enableBackup ? "true" : "false",//"false", //TF_VAR_backup_enable
                                    "TF_VAR_bucket_uri": "",
                                    "enable_cross_region_backup": val.selfManaged.enableCrossRegionBackup.toString(),
                                    "TF_VAR_bucket_region": val.selfManaged.crossRegionBackup,//"", //TF_VAR_bucket_region
                                    "TF_VAR_restore_db": "false",
                                    "TF_VAR_exporter_enable": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                                    //"TF_VAR_mongodb_version": "",//"5.0.8-debian-10-r9", //later
                                    "TF_VAR_app_version": val.version,
                                    "TF_VAR_custom_credentials_enabled": !val.selfManaged.autoGenPassword ? "true" : "false",//"true",
                                    "TF_VAR_store_password_to_secret_manager": "false",
                                    //"TF_VAR_root_password": val.selfManaged.password,//"password",
                                    "backup_frequency": val.selfManaged.backupFrequency.toLowerCase(),//"hourly",
                                    "backup_interval": val.selfManaged.backupFrequency === "Weekly" ? days.indexOf(val.selfManaged.backupDay) : val.selfManaged.backupFrequency !== "Hourly" ? 1 : val.selfManaged.backupInterval,//3,
                                    "backup_time": val.selfManaged.backupTiming,
                                    "custom_database_name": val.selfManaged.databaseName,
                                    "custom_database_password": val.selfManaged.password,
                                    "custom_database_username": val.selfManaged.username,//"abhi"
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "TF_VAR_restore_db": val.selfManaged.enableRestore ? "true" : "false",
                                }
                            });
                        }
                    });

                    let redisCloud = !cloneDep ? Object.values(redisData) : Object.values(cloneRedisData);
                    redisCloud.map((val) => {
                        if (val.serviceEnabled && val.cloudManaged.serviceCloud) {
                            servicePayload.push({
                                "name": val.displayName,
                                "service_id": val.serviceSelectedId,
                                //"type": "elasticache-redis",
                                "config": {
                                    "db_type": "elasticache-redis",
                                    "TF_VAR_node_type": val.cloudManaged.instanceType,
                                    "Instance_family": val.cloudManaged.instanceFamily,
                                    //"TF_VAR_family": "redis6.x",//will append the version to redis
                                    "TF_VAR_num_cache_nodes": val.cloudManaged.noOfReplica,//"",
                                    "TF_VAR_multi_az_enabled": val.cloudManaged.enableMultiAz ? "true" : "false",
                                    "TF_VAR_automatic_failover_enabled": val.cloudManaged.enableAutomaticFailover ? "true" : "false",
                                    "TF_VAR_at_rest_encryption_enabled": val.cloudManaged.enableRestEncryption ? "true" : "false",
                                    "TF_VAR_snapshot_retention_limit": val.cloudManaged.backupRetention === "" ? "0" : val.cloudManaged.backupRetention,//val.cloudManaged.backupRetention ? "true" : "false",
                                    "TF_VAR_transit_encryption_enabled": val.cloudManaged.enableTransitEncryption ? "true" : "false",
                                    "TF_VAR_snapshot_window": (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd,
                                    "TF_VAR_maintenance_window": (val.cloudManaged.maintenanceWindowStart === "" || val.cloudManaged.maintenanceWindowEnd === "") ? "" : val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowStart + '-' + val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowEnd,
                                    "TF_VAR_engine_version": val.version,
                                    "private_subnet_ids": val.cloudManaged.subnetIds.privateSubnets,
                                    "public_subnet_ids": val.cloudManaged.subnetIds.publicSubnets,
                                }
                            });
                        }
                    });

                    let redisSelf = !cloneDep ? Object.values(redisData) : Object.values(cloneRedisData);
                    redisSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            servicePayload.push({
                                "name": val.displayName,//"mks3",
                                "service_id": val.serviceSelectedId,//5,
                                //"type": "self-managed-redis",
                                "config": {
                                    "db_type": "self-managed-redis",
                                    //"TF_VAR_architecture": "replication", //will add later
                                    //"TF_VAR_replicacount": "1", //will add later
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),//"gp2",
                                    //"TF_VAR_enable_metrics": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                                    "TF_VAR_slave_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ "Gi",//"10Gi",
                                    "TF_VAR_master_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ "Gi",//"10Gi",
                                    "TF_VAR_slave_replica_count": val.selfManaged.noOfReplica,//"2", //will add later
                                    //"TF_VAR_monitoring_enabled": "true", //will be added later
                                    "TF_VAR_app_version": val.version,//"6.2.7-debian-11-r11", //version list update required
                                    "TF_VAR_store_password_to_secret_manager": "false",
                                    "TF_VAR_custom_credentials_enabled": val.selfManaged.authEnabled ? !val.selfManaged.autoGenPassword ? "true" : "false" : undefined,//"true",
                                    "TF_VAR_password": val.selfManaged.authEnabled ? val.selfManaged.password : undefined,
                                    "TF_VAR_grafana_monitoring_enabled": val.selfManaged.enableMetrics ? "true" : "false",
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "auth_enabled": val.selfManaged.authEnabled.toString()
                                }
                            });
                        }
                    });

                    let postgresAws = !cloneDep ? Object.values(postgresDataSourcesData) : Object.values(clonePostgresDataSourcesData);
                    postgresAws.map((val) => {
                        if (val.serviceEnabled && val.cloudManaged.serviceAws) {
                            servicePayload.push({
                                "name": val.displayName,//"mysql",
                                "service_id": val.serviceSelectedId,//40,
                                "config": {
                                    "db_type": val.type === "AWS Managed Postgres" ? "aws-rds-managed-postgres" : "aws-aurora-managed-postgres",
                                    "TF_VAR_instance_type": val.cloudManaged.instanceType,//"db.t3.micro" , 
                                    "Instance_family": val.cloudManaged.instanceFamily,
                                    "TF_VAR_engine_version": val.version,
                                    "tags": val.cloudManaged.tags.length === 1 ? (val.cloudManaged.tags[0].name === "" || val.cloudManaged.tags[0].value === "") ? [] : val.cloudManaged.tags : val.cloudManaged.tags,
                                    "TF_VAR_database_name": val.cloudManaged.databaseName,//"nodeapp",        
                                    "TF_VAR_enable_multi_az": val.cloudManaged.dbType === "Single DB Instance" ? "false" : "true", //"false",
                                    "TF_VAR_master_username": val.cloudManaged.username,//"abhi",
                                    "TF_VAR_storage_size": parseInt(val.cloudManaged.storageSize),//"30",       
                                    "TF_VAR_enable_public_access": val.cloudManaged.enablePublicAccess ? "true" : "false",//"true",
                                    "TF_VAR_skip_final_snapshot": "true",
                                    "TF_VAR_backup_window": val.type === "AWS Aurora Managed Postgres" ? (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd:
                                    (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd,//"03:00-06:00",
                                    "TF_VAR_maintenance_window": (val.cloudManaged.maintenanceWindowStart === "" || val.cloudManaged.maintenanceWindowEnd === "") ? "" : val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowStart + '-' + val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowEnd,//"Mon:00:00-Mon:03:00",
                                    "TF_VAR_enable_deletion_protection": val.cloudManaged.deletionProtection ? "true" : "false",//"false",
                                    "TF_VAR_backup_retention_period": val.cloudManaged.backupRetention === "" ? "0" : val.cloudManaged.backupRetention,//"7",
                                    "TF_VAR_enable_general_log": val.cloudManaged.enableGeneralLogs ? "true" : "false",//"true",
                                    "TF_VAR_enable_slow_query_log": val.cloudManaged.enableSlowLogs ? "true" : "false",//"true",
                                    "TF_VAR_create_random_password": val.cloudManaged.autoGenPassword ? "true" : "false",//"true"
                                    "TF_VAR_master_password": val.cloudManaged.password,
                                    "TF_VAR_log_retention_period": val.cloudManaged.logRetention,
                                    //"TF_VAR_enable_backup": val.cloudManaged.enableBackup ? "true" : 'false'
                                    "private_subnet_ids": val.cloudManaged.subnetIds.privateSubnets,
                                    "public_subnet_ids": val.cloudManaged.subnetIds.publicSubnets,
                                }
                            });
                        }    
                    })

                    let postgresSelf = !cloneDep ? Object.values(postgresDataSourcesData) : Object.values(clonePostgresDataSourcesData);
                    postgresSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            servicePayload.push({
                                "name": val.displayName,//"mks2",
                                "service_id": val.serviceSelectedId,//69,
                                "config": {
                                    "db_type": "self-managed-postgresql",
                                    "TF_VAR_postgresql_exporter_enabled": val.selfManaged.enableExporter ? "true" : "false",//"false",
                                    "TF_VAR_replicacount": val.selfManaged.dbType,//"1",
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),//"gp2",
                                    "TF_VAR_custom_credentials_enabled": !val.selfManaged.autoGenPassword ? "true" : "false",//"true",
                                    "TF_VAR_postgresql_password": val.selfManaged.password, //"",
                                    "TF_VAR_app_version": val.version,//"16.0.0",
                                    "TF_VAR_database_name": val.selfManaged.databaseName,//"atmosly",
                                    "TF_VAR_storage_size": !val.selfManaged.storageSize ? "" : parseInt(val.selfManaged.storageSize),//"10Gi"
                                    "TF_VAR_postgresql_username": val.selfManaged.username,
                                    "TF_VAR_backup_enabled": val.selfManaged.enableBackup ? "true" : "false",
                                    "backup_frequency": val.selfManaged.backupFrequency.toLowerCase(),
                                    "backup_interval": val.selfManaged.backupFrequency === "Weekly" ? days.indexOf(val.selfManaged.backupDay) : val.selfManaged.backupFrequency !== "Hourly" ? 1 : val.selfManaged.backupInterval,
                                    "backup_time": val.selfManaged.backupTiming,
                                    "enable_cross_region_backup": val.selfManaged.enableCrossRegionBackup.toString(),
                                    "TF_VAR_bucket_region": val.selfManaged.crossRegionBackup,
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "TF_VAR_restore_db": val.selfManaged.enableRestore ? "true" : "false",
                                }
                            });
                        }
                    });

                    console.log("Payload", servicePayload);

                    if (servicePayload.length === 0) {
                        set_alertOptions(alertOptions => ({
                            status: true,
                            type: "error",
                            message: "Environment Creation failed.",
                            description: "Please enable at least one service in order to proceed.",
                        }));

                        // opacity=0 after 10sec
                        setTimeout(() => {
                            set_alertOptions(alertOptions => ({
                                status: false,
                                type: "error",
                                message: "Environment Creation failed.",
                                description: "Please enable at least one service in order to proceed.",
                            }));
                        }, 5 * 1000);
                    }
                    else {
                        setFetching(true);
                        // console.log("Entered else");
                        console.log("gfcghjb", fetching);
                        let deploymentLabels = {};
                        !cloneDep ? deploymentData.tags.forEach((val, idx) => {
                            if (val.name !== "" && val.value !== "") {
                                deploymentLabels[val.name] = val.value;
                            }
                        }) : cloneDeploymentData.tags.forEach((val, idx) => {
                            if (val.name !== "" && val.value !== "") {
                                deploymentLabels[val.name] = val.value;
                            }
                        });
                        console.log("Final payload: ", servicePayload);
                        api.post(process.env.REACT_APP_API_URI + '/api/project_env/env/create/', {
                            "cluster_unique_id": !cloneDep ? deploymentData.clusterId : cloneDeploymentData.clusterId,
                            "project": !cloneDep ? deploymentData.projectId : cloneDeploymentData.projectId,
                            "name": !cloneDep ? deploymentData.envName : cloneDeploymentData.envName,//appData.name,
                            "namespace": !cloneDep ? deploymentData.clusterNamespace : cloneDeploymentData.clusterNamespace,
                            "zone_id": !cloneDep ? deploymentData.hostedAccount : cloneDeploymentData.hostedAccount,
                            "dns_record_type": !cloneDep ? deploymentData.recordType : cloneDeploymentData.recordType,
                            "hosted_zone": !cloneDep ? deploymentData.dns : cloneDeploymentData.dns,
                            "description": !cloneDep ? deploymentData.description === "" ? null : deploymentData.description : cloneDeploymentData.description === "" ? null : cloneDeploymentData.description,
                            "labels": deploymentLabels,
                            "services": servicePayload
                        }).then((res) => {
                            let data = res.data;
                            console.log("Enters");
                            if (data.status) {
                                console.log("Entered");
                                setFetching(false);
                                setAddDep(false);
                                setUpdateDep(false);
                                setCloneDep(false);
                                localStorage.setItem('isClone', JSON.stringify(false));
                                localStorage.setItem('updateDep', JSON.stringify(false));
                                localStorage.setItem('addDep', JSON.stringify(false));
                                localStorage.setItem('stopFetch', JSON.stringify(false));
                                navigate(`/environments/environment/${data.data.env_unique_id}/env-overview`, {
                                    state: {
                                        from: "/environments/services"
                                    }
                                });
                                if (!cloneDep) {
                                    setApplicationData({});
                                    setDataSourcesData({});
                                    setMongoDataSourcesData({});
                                    setRedisData({});
                                    setServicesEnabled({});
                                    setSelectedProject({});
                                    setPostgresDataSourcesData({});
                                    setContainerRegistries({});
                                    setCurrentProjectDep({});
                                    setDeploymentData({ ...initialDeploymentData });
                                    setServiceCategories({});
                                    RemoveEnvironmentFromLocal();
                                }
                                else {
                                    handleDeploymentLogout();
                                    RemoveCloneEnvironmentFromLocal();
                                }
                            }
                            else if (data.error_details && (data.error_details.cluster_unique_id || data.error_details.name || data.error_details.namespace || data.error_details.services[0]?.service_id)) {
                                setFetching(false);

                                set_alertOptions(alertOptions => ({
                                    status: true,
                                    type: "error",
                                    message: "Environment Creation failed.",
                                    description: data.error_details.cluster_unique_id ? data.error_details.cluster_unique_id : data.error_details.name ? data.error_details.name : data.error_details.namespace,
                                }));

                                // opacity=0 after 10sec
                                setTimeout(() => {
                                    set_alertOptions(alertOptions => ({
                                        status: false,
                                        type: "error",
                                        message: "Environment Creation failed.",
                                        description: data.error_details.cluster_unique_id ? data.error_details.cluster_unique_id : data.error_details.name ? data.error_details.name : data.error_details.namespace,
                                    }));
                                }, 5 * 1000);
                            }
                            else if (data.error_details.detail) {
                                setFetching(false);
                                set_alertOptions(alertOptions => ({
                                    status: true,
                                    type: "error",
                                    message: "Environment Creation failed.",
                                    description: formatErrors(data.error_details),
                                }));

                                // opacity=0 after 10sec
                                setTimeout(() => {
                                    set_alertOptions(alertOptions => ({
                                        status: false,
                                        type: "error",
                                        message: "Environment Creation failed.",
                                        description: formatErrors(data.error_details),
                                    }));
                                }, 5 * 1000);
                            }
                            else {
                                console.log("Error...");
                                setFetching(false);
                                let myErrors = {};
                                Object.keys(data.error_details.services).map((val) => {
                                    if (Object.keys(data.error_details.services[val]).length > 0) {
                                        myErrors = {
                                            ...myErrors,
                                            [val]: data.error_details.services[val]
                                        }
                                    }
                                });
                                console.log("MY errors: ", myErrors);
                                for (const val of allForms) {

                                    if (myErrors[val.serviceSelected]) {
                                        console.log("Val data source: ", val.form.getFieldWarning());

                                        val.form.setFields(
                                            Object.entries(myErrors[val.serviceSelected]).map(([field, err]) => ({
                                                name: field === "name" ? (snakeToCamel[field] in val.form.getFieldsValue()) ? snakeToCamel[field] : "appName" : snakeToCamel[field],
                                                //warnings: val.form.getFieldWarning()[snakeToCamel[field]],
                                                errors: err
                                            }))
                                        );

                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                        // // val.form.validateFields(['displayName']).then((res) => {
                                        // //     console.log("Validated", res);
                                        // // }).catch((err) => {
                                        // //     console.log("All errors info: ", err);
                                        // // });
                                        // // val.form.validateFields().then(() => {
                                        // //     console.log("Validated", val.form.getFieldsError());
                                        // // }).catch((error) => {

                                        // // });
                                        // //val.form.validateFields();
                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                    }
                                }
                                console.log("All Forms: ", allForms[0].form.getFieldsError());
                                setFormErrors(myErrors);
                                if (Object.keys(myErrors).length > 0) {
                                    set_alertOptions(alertOptions => ({
                                        status: true,
                                        type: "error",
                                        message: "Environment Creation failed",
                                        description: "Please enter correct information for the services in order to proceed."
                                    }));

                                    // opacity=0 after 10sec
                                    setTimeout(() => {
                                        set_alertOptions(alertOptions => ({
                                            status: false,
                                            type: "error",
                                            message: "Environment Creation failed",
                                            description: "Please enter correct information for the services in order to proceed.",
                                        }));
                                    }, 5 * 1000);
                                }
                            }
                        }).catch((err) => {
                            console.log("Error catch");
                            set_alertOptions(alertOptions => ({
                                status: true,
                                type: "error",
                                message: "Environment Creation failed",
                                description: "Something went wrong. Please try again."
                            }));

                            // opacity=0 after 10sec
                            setTimeout(() => {
                                set_alertOptions(alertOptions => ({
                                    status: false,
                                    type: "error",
                                    message: "Environment Creation failed",
                                    description: "Something went wrong. Please try again."
                                }));
                            }, 5 * 1000);
                            setFetching(false);
                        });
                    }
                }
                else {
                    set_alertOptions(alertOptions => ({
                        status: true,
                        type: "error",
                        message: "Environment Creation failed.",
                        description: "There were some changes to the selected project. Please fill the information again."
                    }));

                    // opacity=0 after 10sec
                    setTimeout(() => {
                        set_alertOptions(alertOptions => ({
                            status: false,
                            type: "error",
                            message: "Environment Creation failed",
                            description: "There were some changes to the selected project. Please fill the information again."
                        }));
                    }, 5 * 1000);
                    navigate('/environments/create');
                }
            }
        }
        else {
            console.log("Else entered while checking");
            let allForms = [], allErrors = [];
            let myErrors = {};
            let checked = true;
            let applicationServicesList = Object.values(addUpdatedData.applicationData);
            let servicePayload = [];


            applicationServicesList.map((val) => {
                if (val.serviceEnabled === true) {
                    const form = deploymentForms[val.basicDetails.serviceName];
                    allForms.push({
                        serviceSelected: val.basicDetails.serviceName,
                        form: form
                    });
                }
            });

            let yamls = await validateYamls(applicationServicesList);

            console.log("====My yamls====: ", yamls);

            let yamlError = false;

            yamls.forEach((val, idx) => {
                if (val && val.data && idx === val.idx) {
                    applicationServicesList[idx].deployments.yamlData = [...val.data];
                }
                else if (val && applicationServicesList[idx].serviceEnabled) {
                    myErrors = {
                        ...myErrors,
                        [val.serviceName]: "Your YAML code provided is invalid."
                    };
                    allErrors = [...allErrors, {
                        serviceSelected: val.serviceName,
                        error: "Your YAML code provided is invalid."
                    }];
                    yamlError = true;
                }
            });

            let dataSourcesMySqlList = Object.values(addUpdatedData.dataSourcesData);
            dataSourcesMySqlList.map((val) => {
                if (val.serviceEnabled) {
                    console.log("val.displayName", val);
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            console.log("Mysql values: ", allForms);

            let dataSourcesMongoList = Object.values(addUpdatedData.mongoDataSourcesData);
            dataSourcesMongoList.map((val) => {
                if (val.serviceEnabled) {
                    console.log("val.displayName", val);
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            let redisList = Object.values(addUpdatedData.redisData)
            console.log("Redis data: ",);
            redisList.map((val) => {
                if (val.serviceEnabled) {
                    console.log("Val.data: ", val.cloudManaged.maintenanceWindowEnd);
                    const form = deploymentForms[val.serviceName];
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            console.log("Entered here");

            let postgresList = Object.values(addUpdatedData.postgresDataSourcesData);
            postgresList.map((val) => {
                if (val.serviceEnabled) {
                    const form = deploymentForms[val.serviceName];
                    console.log("Form: ", form);
                    allForms.push({
                        serviceSelected: val.serviceName,
                        form: form
                    });
                }
            });

            console.log("All forms: ", allForms);

            for (const val of allForms) {
                try {
                    let variable = await val.form.validateFields();
                    console.log("ending validation...", variable);
                }
                catch (error) {
                    console.log("Catch entered");
                    allErrors = [...allErrors, {
                        serviceSelected: val.serviceSelected,
                        error: error
                    }];
                    myErrors = {
                        ...myErrors,
                        [val.serviceSelected]: {
                            error: error
                        }
                    }
                }
            }

            console.log("My errors: ", myErrors);
            setFormErrors(myErrors);

            if (allErrors.length > 0) {
                if (yamlError) {
                    console.error('Validation failed: ', allErrors);
                    set_alertOptions(alertOptions => ({
                        status: true,
                        type: "error",
                        message: "Services couldn't be added.",
                        description: "Please enter a correct YAML file.",
                    }));

                    // opacity=0 after 10sec
                    setTimeout(() => {
                        set_alertOptions(alertOptions => ({
                            status: false,
                            type: "error",
                            message: "Services couldn't be added.",
                            description: "Please enter a correct YAML file.",
                        }));
                    }, 5 * 1000);
                }
                else {
                    console.error('Validation failed: ', allErrors);
                    set_alertOptions(alertOptions => ({
                        status: true,
                        type: "error",
                        message: "Services couldn't be added.",
                        description: "Please fill all the fields for the enabled services.",
                    }));

                    // opacity=0 after 10sec
                    setTimeout(() => {
                        set_alertOptions(alertOptions => ({
                            status: false,
                            type: "error",
                            message: "Services couldn't be added.",
                            description: "Please fill all the fields for the enabled services.",
                        }));
                    }, 5 * 1000);
                }
            }
            else {
                let myDepData = Object.values(addUpdatedData.deploymentData);
                if (myDepData && myDepData.projectName !== "" && myDepData.projectId !== 0) {
                    console.log("Validation succeeded");
                    //let servicePayload = [];
                    let applicationServicesList = Object.values(addUpdatedData.applicationData);
                    yamls.forEach((val, idx) => {
                        if (val && val.data && val.idx === idx) {
                            applicationServicesList[idx].deployments.yamlData = [...val.data];
                        }                    
                    });
                    console.log("Getting application services list: ", applicationServicesList);
                    let servicePayload = [];
                    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    applicationServicesList.map((val) => {
                        if (val.serviceEnabled === true) {
                            let myConfigVol = [];
                            let mySecretVol = [];
                            let myStorageVol = [];
                            let myEnvs = val.environments.length > 0 ? (val.environments.length === 1 ? (val.environments[0].name === "" && val.environments[0].value === "") ? [] : val.environments : val.environments) : val.environments;
                            let labels = {};
                            val.deployments.tags.map((val, idx) => {
                                if (val.name !== "" && val.value !== "") {
                                    labels[val.name] = val.value;
                                }
                            })
                            val.volumes.map((ele) => {
                                if (ele.volumeType === "Configmap") {
                                    let rawStr = ele.config.createMountPath.split('/')
                                    let tempFilename = rawStr.pop()  // removing filename from rawStr
                                    let tempMountPath = rawStr.join("/")   // join for mountPath

                                    console.log("tempFilename :", tempFilename)
                                    console.log("tempMountPath :", tempMountPath)

                                    myConfigVol.push({
                                        "name": ele.config.configName,//"test-cm",
                                        "filename": tempFilename,//"index.yaml",
                                        "mountPath": tempMountPath,//"/app",
                                        "data": ele.config.createConfig//"this the configmap data...."
                                    });
                                }
                                else if (ele.volumeType === "Secrets") {
                                    let rawStrSecrets = ele.secrets.createSecretsMountPath.split('/')
                                    let tempFilenameSecrets = rawStrSecrets.pop()  // removing filename from rawStrSecrets
                                    let tempMountPathSecrets = rawStrSecrets.join("/")   // join for mountPath

                                    mySecretVol.push({
                                        "name": ele.secrets.secretsName,//"test-secretqs",
                                        "filename": tempFilenameSecrets,//"index.yaml",
                                        "mountPath": tempMountPathSecrets,//"/app",
                                        "data": ele.secrets.createSecrets//"this the configmap data...."
                                    });
                                }
                                else if (ele.volumeType === "Storage") {
                                    myStorageVol.push({
                                        "name": ele.storage.storageDes.storageName,//"test-secrets",
                                        "size": ele.storage.storageDes.size !== "" ? ele.storage.storageDes.size : 0,//+ 'Gi' : "",//"10Gi",
                                        "mountPath": ele.storage.storageDes.path,//"/app",
                                        "storageClass": ele.storage.storageDes.type.toLowerCase() === "efs" ? 'efs-sc' : ele.storage.storageDes.type.toLowerCase(),//"gp2",
                                        "accessMode": "ReadWriteOnce"
                                    });
                                }
                            });
                            console.log("App details: ", val, (val.deployments.endpointType === "Cluster IP" || val.deployments.serviceType === "Existing Load Balancer") ? "ClusterIP" : !val.deployments.endpointType ? "" : "LoadBalancer");
                            servicePayload.push({
                                "name": val.basicDetails.appName,
                                "service_id": val.serviceSelectedId,//serviceSelected.id,
                                "cd_values_in_yaml": val.deployments.deploymentOption === "yaml",
                                "config": {
                                    //"container_registry_id": val.basicDetails.containerRegistryId.id,
                                    "docker_file": val.basicDetails.sourceType === "Container Registry" ? undefined : './' + val.basicDetails.dockerFilePath,
                                    "build_context": val.basicDetails.sourceType === "Container Registry" ? undefined : val.basicDetails.buildContext && val.basicDetails.buildContext !== "" ? "./" + val.basicDetails.buildContext : null,
                                    "app_source_type": val.basicDetails.sourceType === "Container Registry" ? 0 : 1,
                                    "container_registry_id": val.basicDetails.appSource === "PUBLIC" ? null : val.basicDetails.sourceType === "Container Registry" ? val.basicDetails.appSource?.id : val.basicDetails.containerRegistryId.id,
                                    "container_registry_type": val.basicDetails.sourceType === "Container Registry" ? val.basicDetails.appSource === "PUBLIC" ? 0 : 1 : undefined,
                                    "repository_name": val.basicDetails.imageRepo,
                                    "image_tag": val.basicDetails.imageTag && val.basicDetails.imageTag !== "" ? val.basicDetails.imageTag : undefined,
                                    "port": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.port) : undefined,
                                    "create_storage": val.deployments.deploymentOption === "visual" ? myStorageVol.length > 0 ? "true" : "false" : undefined,
                                    "create_secret": val.deployments.deploymentOption === "visual" ? mySecretVol.length > 0 ? "true" : "false" : undefined,
                                    "ingress_enabled": val.deployments.deploymentOption === "visual" ? val.deployments.serviceType.includes("Existing Load Balancer") ? "true" : "false" : undefined,//val.deployments.customDomain === true ? "true" : "false",
                                    "ingress_https_enabled": val.deployments.deploymentOption === "visual" ? "true" : undefined,
                                    "ingress_host": val.deployments.deploymentOption === "visual" ? val.deployments.domainHostUrl === "" ? "" : val.deployments.domainHostUrl + '.' + !cloneDep ? deploymentData.dns : cloneDeploymentData.dns : undefined,
                                    "ingress_path": val.deployments.deploymentOption === "visual" ? val.deployments.gatewayHttps.gPath === "" ? "/" : val.deployments.gatewayHttps.gPath : undefined,
                                    "env_variables": myEnvs,
                                    "record_prefix": val.deployments.deploymentOption === "visual" ? val.deployments.serviceType.includes("Existing Load Balancer") ? val.deployments.domainHostUrl : "" : undefined,
                                    "service_type": val.deployments.deploymentOption === "visual" ? (val.deployments.endpointType === "Cluster IP" || val.deployments.serviceType.includes("Existing Load Balancer")) ? "ClusterIP" : !val.deployments.endpointType ? "" : "LoadBalancer" : undefined,//serviceSelected.type,
                                    "service_protocol": val.deployments.deploymentOption === "visual" ? "TCP" : undefined,
                                    "enable_plaintext_env": myEnvs.length > 0 ? "true" : "false",
                                    "enable_secret_env": myEnvs.length > 0 ? "true" : "false",
                                    "vcs_branch": val.basicDetails.branch === "" ? null : val.basicDetails.branch,
                                    "secretfile_enabled": val.deployments.deploymentOption === "visual" ? mySecretVol.length > 0 ? "true" : "false" : undefined,
                                    "create_configmap": val.deployments.deploymentOption === "visual" ? myConfigVol.length > 0 ? "true" : "false" : undefined,
                                    "cpu_request": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.cpu.requestCpu) : undefined, //+ 'm',
                                    "cpu_limit": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.cpu.limitCpu) : undefined, //+ 'm',
                                    "memory_request": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.memory.requestMem) : undefined, //+ 'Mi',
                                    "memory_limit": val.deployments.deploymentOption === "visual" ? parseInt(val.deployments.resources.memory.limitMem) : undefined, //+ 'Mi',
                                    "target_cpu_utilization": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto === "" ? 0 : parseInt(val.deployments.autoScaling.autoScalingData.autoScalingParameter.cpuAuto) : undefined, //+ '%',
                                    "target_memory_utilization": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto === "" ? 0 : parseInt(val.deployments.autoScaling.autoScalingData.autoScalingParameter.memoryAuto) : undefined, //+ '%',
                                    "enable_autoscaling": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.enabled === false ? "false" : "true" : undefined,
                                    "maximum_replicas": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.maximumReplica : undefined,
                                    "minimum_replicas": val.deployments.deploymentOption === "visual" ? val.deployments.autoScaling.autoScalingData.minimumReplica : undefined,
                                    "config_files_config": val.deployments.deploymentOption === "visual" ? myConfigVol === 1 ? myConfigVol[0].name === "" ? [] : myConfigVol : myConfigVol : undefined,
                                    "storages_config": val.deployments.deploymentOption === "visual" ? myStorageVol.length === 1 ? myStorageVol[0].name === "" ? [] : myStorageVol : myStorageVol : undefined,
                                    "secret_files_config": val.deployments.deploymentOption === "visual" ? mySecretVol.length === 1 ? mySecretVol[0].name === "" ? [] : mySecretVol : mySecretVol : undefined,
                                    "labels": labels,
                                    "health_check_path": val.deployments.deploymentOption === "visual" ? val.deployments.healthCheckPath : undefined,
                                    "auto_trigger": val.basicDetails.enableAutoTrigger.toString(),
                                    "create_cron": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.crons ? "true" : "false" : undefined,
                                    "create_queue_worker": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.queuesAndWorkers ? "true" : "false" : undefined,
                                    "cron_command": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.entryPointCommandCrons : undefined,
                                    "queue_worker_command": val.deployments.deploymentOption === "visual" ? val.deployments.taskSchedulers.entryPointCommandQueues : undefined,
                                    "pass_env_vars_at_build_time": currentProjectDep.tasks.find(x => x.serviceId === val.serviceSelectedId).envs.some(x => x.injectAt.includes("Build Time") && x.lastAction !== "Delete").toString(),
                                    "nodegroup_name": val.deployments.deploymentOption === "visual" ? val.deployments.nodeGroupName : undefined,
                                    "nodegroup_type": val.deployments.deploymentOption === "visual" ? val.deployments.nodeGroupType : undefined,
                                    "list_of_nodegroups": val.deployments.deploymentOption === "visual" ? val.deployments.listOfNodegroups : undefined,
                                    "ingress_alb_enabled": val.deployments.deploymentOption === "visual" ? val.deployments.serviceType === "Existing Load Balancer(ALB Ingress)" ? "true" : "false" : undefined,//deploymentData.ingressAlbEnabled.toString(),
                                    "yaml_content": val.deployments.deploymentOption === "yaml" ? val.deployments.yamlData : undefined,
                                    "yaml_kind": val.deployments.deploymentOption === "yaml" ? val.deployments.yamlType : undefined
                                },
                                //"context": val.basicDetails.context,
                                // "environments.plaintext.values": appData.envPlainVars,
                                // "environments.secret.values": appData.envSecretVars,
                                // "configmap_file_name": val.volumes[0].config.configFileName,
                                // "configmap_content": val.volumes[0].config.createConfig,
                                // "configmap_file_mountpath": val.volumes[0].config.createMountPath,
                                // "storage_size": val.volumes[0].storage.storageDes.size,
                                // "VolumemountPath": val.volumes[0].storage.storageDes.path,
                                // "storage_class": val.volumes[0].storage.storageDes.type //will be changed later
                            });
                        }
                    });

                    let dataSourcesMySqlAws = Object.values(addUpdatedData.dataSourcesData);
                    console.log("data sources: ", dataSourcesMySqlAws);

                    dataSourcesMySqlAws.map((val) => {
                        if (val.serviceEnabled && val.cloudManaged.serviceAws) {
                            servicePayload.push({
                                "name": val.displayName,//"mysql",
                                "service_id": val.serviceSelectedId,//40,
                                "config": {
                                    "db_type": val.type === "AWS Managed MySQL" ? "aws-rds-managed-mysql" : "aws-aurora-managed-mysql",
                                    "TF_VAR_mysql_instance_class": val.cloudManaged.instanceType,//"db.t3.micro" , 
                                    "Instance_family": val.cloudManaged.instanceFamily,
                                    "TF_VAR_mysql_engine_version": val.version,
                                    "tags": val.cloudManaged.tags.length === 1 ? (val.cloudManaged.tags[0].name === "" || val.cloudManaged.tags[0].value === "") ? [] : val.cloudManaged.tags : val.cloudManaged.tags,
                                    "TF_VAR_database_name": val.cloudManaged.databaseName,//"nodeapp",        
                                    "TF_VAR_enable_multi_az": val.cloudManaged.dbType === "Single DB Instance" ? "false" : "true", //"false",
                                    "TF_VAR_master_username": val.cloudManaged.username,//"abhi",
                                    "TF_VAR_storage_size": parseInt(val.cloudManaged.storageSize),//"30",       
                                    "TF_VAR_enable_public_access": val.cloudManaged.enablePublicAccess ? "true" : "false",//"true",
                                    "TF_VAR_skip_final_snapshot": "true",
                                    "TF_VAR_backup_window": (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd,//"03:00-06:00",
                                    "TF_VAR_backup_region": val.cloudManaged.backupRegion,
                                    "TF_VAR_maintenance_window": (val.cloudManaged.maintenanceWindowStart === "" || val.cloudManaged.maintenanceWindowEnd === "") ? "" : val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowStart + '-' + val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowEnd,//"Mon:00:00-Mon:03:00",
                                    "TF_VAR_enable_deletion_protection": val.cloudManaged.deletionProtection ? "true" : "false",//"false",
                                    "TF_VAR_backup_retention_period": val.cloudManaged.backupRetention === "" ? "0" : val.cloudManaged.backupRetention,//"7",
                                    "TF_VAR_enable_general_log": val.cloudManaged.enableGeneralLogs ? "true" : "false",//"true",
                                    "TF_VAR_enable_slow_query_log": val.cloudManaged.enableSlowLogs ? "true" : "false",//"true",
                                    "TF_VAR_create_random_password": val.cloudManaged.autoGenPassword ? "true" : "false",//"true"
                                    "TF_VAR_master_password": val.cloudManaged.password,
                                    "TF_VAR_log_retention_period": val.cloudManaged.logRetention,
                                    "private_subnet_ids": val.cloudManaged.subnetIds.privateSubnets,
                                    "public_subnet_ids": val.cloudManaged.subnetIds.publicSubnets
                                    //"TF_VAR_enable_backup": val.cloudManaged.enableBackup ? "true" : 'false'
                                }
                            });
                        }
                    });

                    let dataSourcesMySqlSelf = Object.values(addUpdatedData.dataSourcesData);
                    console.log("Mysql self: ", dataSourcesMySqlSelf);
                    dataSourcesMySqlSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            console.log("Executes");
                            servicePayload.push({
                                "name": val.displayName,
                                "service_id": val.serviceSelectedId,//3,
                                "config": {
                                    "db_type": "self-managed-mysql",
                                    //"TF_VAR_architecture": val.selfManaged.dbType,//"standalone",
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),//"gp2",
                                    "TF_VAR_username": val.selfManaged.username,//"admin",
                                    "TF_VAR_master_db_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                                    "TF_VAR_secondary_db_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                                    "TF_VAR_secondary_db_replica_count": val.selfManaged.dbType,//"1",
                                    "TF_VAR_backup_enabled": val.selfManaged.enableBackup ? "true" : "false",//"false",
                                    "TF_VAR_bucket_uri": "",
                                    "enable_cross_region_backup": val.selfManaged.enableCrossRegionBackup.toString(),
                                    "TF_VAR_bucket_region": val.selfManaged.crossRegionBackup,//"",
                                    //"TF_VAR_backup_cron": "* * * * *",
                                    "TF_VAR_restore_db": "false",
                                    "TF_VAR_exporter_enable": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                                    "TF_VAR_app_version": val.version,
                                    "TF_VAR_custom_credentials_enabled": !val.selfManaged.autoGenPassword ? "true" : "false",//"true",
                                    "TF_VAR_store_password_to_secret_manager": "false",
                                    "TF_VAR_custom_user_password": val.selfManaged.password,//"password",
                                    "backup_frequency": val.selfManaged.backupFrequency.toLowerCase(),//"hourly",
                                    "backup_interval": val.selfManaged.backupFrequency === "Weekly" ? days.indexOf(val.selfManaged.backupDay) : val.selfManaged.backupFrequency !== "Hourly" ? 1 : val.selfManaged.backupInterval,//3,
                                    "backup_time": val.selfManaged.backupTiming,
                                    "TF_VAR_database_name": val.selfManaged.databaseName,
                                    "TF_VAR_restore_file_name": "",
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "TF_VAR_restore_db": val.selfManaged.enableRestore ? "true" : "false",
                                }
                            });
                        }
                    });

                    let dataSourcesMongoSelf = Object.values(addUpdatedData.mongoDataSourcesData);
                    console.log("Mysql self: ", dataSourcesMongoSelf);
                    dataSourcesMongoSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            servicePayload.push({
                                "name": val.displayName,//"dskldss",
                                "service_id": val.serviceSelectedId,//6,
                                "type": "self-managed-mongodb",
                                "config": {
                                    "db_type": "self-managed-mongodb",
                                    //"TF_VAR_architecture": val.selfManaged.dbType.toLowerCase(),//"standalone",
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),                            //"TF_VAR_username": val.selfManaged.username,//"admin",
                                    "TF_VAR_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ 'Gi',//"10Gi",
                                    //"TF_VAR_secondary_db_volume_size": val.selfManaged.storageSize === "" ? "" : val.selfManaged.storageSize + 'Gi',//"10Gi",
                                    "TF_VAR_replica_count": val.selfManaged.dbType === "" ? "" : val.selfManaged.dbType,
                                    //"TF_VAR_replica_count": "1",
                                    "TF_VAR_backup_enabled": val.selfManaged.enableBackup ? "true" : "false",//"false", //TF_VAR_backup_enable
                                    "TF_VAR_bucket_uri": "",
                                    "enable_cross_region_backup": val.selfManaged.enableCrossRegionBackup.toString(),
                                    "TF_VAR_bucket_region": val.selfManaged.crossRegionBackup,//"", //TF_VAR_bucket_region
                                    "TF_VAR_restore_db": "false",
                                    "TF_VAR_exporter_enable": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                                    //"TF_VAR_mongodb_version": "",//"5.0.8-debian-10-r9", //later
                                    "TF_VAR_app_version": val.version,
                                    "TF_VAR_custom_credentials_enabled": !val.selfManaged.autoGenPassword ? "true" : "false",//"true",
                                    "TF_VAR_store_password_to_secret_manager": "false",
                                    //"TF_VAR_root_password": val.selfManaged.password,//"password",
                                    "backup_frequency": val.selfManaged.backupFrequency.toLowerCase(),//"hourly",
                                    "backup_interval": val.selfManaged.backupFrequency === "Weekly" ? days.indexOf(val.selfManaged.backupDay) : val.selfManaged.backupFrequency !== "Hourly" ? 1 : val.selfManaged.backupInterval,//3,
                                    "backup_time": val.selfManaged.backupTiming,
                                    "custom_database_name": val.selfManaged.databaseName,
                                    "custom_database_password": val.selfManaged.password,
                                    "custom_database_username": val.selfManaged.username,//"abhi"
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "TF_VAR_restore_db": val.selfManaged.enableRestore ? "true" : "false",
                                }
                            });
                        }
                    });

                    let redisCloud = Object.values(addUpdatedData.redisData);
                    redisCloud.map((val) => {
                        if (val.serviceEnabled && val.cloudManaged.serviceCloud) {
                            servicePayload.push({
                                "name": val.displayName,
                                "service_id": val.serviceSelectedId,
                                //"type": "elasticache-redis",
                                "config": {
                                    "db_type": "elasticache-redis",
                                    "TF_VAR_node_type": val.cloudManaged.instanceType,
                                    "Instance_family": val.cloudManaged.instanceFamily,
                                    //"TF_VAR_family": "redis6.x",//will append the version to redis
                                    "TF_VAR_num_cache_nodes": val.cloudManaged.noOfReplica,//"",
                                    "TF_VAR_multi_az_enabled": val.cloudManaged.enableMultiAz ? "true" : "false",
                                    "TF_VAR_automatic_failover_enabled": val.cloudManaged.enableAutomaticFailover ? "true" : "false",
                                    "TF_VAR_at_rest_encryption_enabled": val.cloudManaged.enableRestEncryption ? "true" : "false",
                                    "TF_VAR_snapshot_retention_limit": val.cloudManaged.backupRetention === "" ? "0" : val.cloudManaged.backupRetention,//val.cloudManaged.backupRetention ? "true" : "false",
                                    "TF_VAR_transit_encryption_enabled": val.cloudManaged.enableTransitEncryption ? "true" : "false",
                                    "TF_VAR_snapshot_window": (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd,
                                    "TF_VAR_maintenance_window": (val.cloudManaged.maintenanceWindowStart === "" || val.cloudManaged.maintenanceWindowEnd === "") ? "" : val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowStart + '-' + val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowEnd,
                                    "TF_VAR_engine_version": val.version,
                                    "private_subnet_ids": val.cloudManaged.subnetIds.privateSubnets,
                                    "public_subnet_ids": val.cloudManaged.subnetIds.publicSubnets
                                }
                            });
                        }
                    });

                    let redisSelf = Object.values(addUpdatedData.redisData);
                    redisSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            servicePayload.push({
                                "name": val.displayName,//"mks3",
                                "service_id": val.serviceSelectedId,//5,
                                //"type": "self-managed-redis",
                                "config": {
                                    "db_type": "self-managed-redis",
                                    //"TF_VAR_architecture": "replication", //will add later
                                    //"TF_VAR_replicacount": "1", //will add later
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),//"gp2",
                                    //"TF_VAR_enable_metrics": val.selfManaged.enableMetrics ? "true" : "false",//"true",
                                    "TF_VAR_slave_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ "Gi",//"10Gi",
                                    "TF_VAR_master_volume_size": val.selfManaged.storageSize === "" ? "" : parseInt(val.selfManaged.storageSize), //+ "Gi",//"10Gi",
                                    "TF_VAR_slave_replica_count": val.selfManaged.noOfReplica,//"2", //will add later
                                    //"TF_VAR_monitoring_enabled": "true", //will be added later
                                    "TF_VAR_app_version": val.version,//"6.2.7-debian-11-r11", //version list update required
                                    "TF_VAR_store_password_to_secret_manager": "false",
                                    "TF_VAR_custom_credentials_enabled": val.selfManaged.authEnabled ? !val.selfManaged.autoGenPassword ? "true" : "false" : undefined,//"true",
                                    "TF_VAR_password": val.selfManaged.authEnabled ? val.selfManaged.password : undefined,
                                    "TF_VAR_grafana_monitoring_enabled": val.selfManaged.enableMetrics ? "true" : "false",
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "auth_enabled": val.selfManaged.authEnabled.toString()
                                }
                            });
                        }
                    });

                    let postgresAws = Object.values(addUpdatedData.postgresDataSourcesData);
                    postgresAws.map((val) => {
                        if (val.serviceEnabled && val.cloudManaged.serviceAws) {
                            servicePayload.push({
                                "name": val.displayName,//"mysql",
                                "service_id": val.serviceSelectedId,//40,
                                "config": {
                                    "db_type": val.type === "AWS Managed Postgres" ? "aws-rds-managed-postgres" : "aws-aurora-managed-postgres",
                                    "TF_VAR_instance_type": val.cloudManaged.instanceType,//"db.t3.micro" , 
                                    "Instance_family": val.cloudManaged.instanceFamily,
                                    "TF_VAR_engine_version": val.version,
                                    "tags": val.cloudManaged.tags.length === 1 ? (val.cloudManaged.tags[0].name === "" || val.cloudManaged.tags[0].value === "") ? [] : val.cloudManaged.tags : val.cloudManaged.tags,
                                    "TF_VAR_database_name": val.cloudManaged.databaseName,//"nodeapp",        
                                    "TF_VAR_enable_multi_az": val.cloudManaged.dbType === "Single DB Instance" ? "false" : "true", //"false",
                                    "TF_VAR_master_username": val.cloudManaged.username,//"abhi",
                                    "TF_VAR_storage_size": parseInt(val.cloudManaged.storageSize),//"30",       
                                    "TF_VAR_enable_public_access": val.cloudManaged.enablePublicAccess ? "true" : "false",//"true",
                                    "TF_VAR_skip_final_snapshot": "true",
                                    "TF_VAR_backup_window": val.type === "AWS Aurora Managed Postgres" ? (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd: 
                                    (val.cloudManaged.backupTimingStart === "" || val.cloudManaged.backupTimingEnd === "") ? "" : val.cloudManaged.backupTimingStart + '-' + val.cloudManaged.backupTimingEnd,//"03:00-06:00",
                                    "TF_VAR_maintenance_window": (val.cloudManaged.maintenanceWindowStart === "" || val.cloudManaged.maintenanceWindowEnd === "") ? "" : val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowStart + '-' + val.cloudManaged.maintenanceWindowDay.substring(0, 3).toLowerCase() + ':' + val.cloudManaged.maintenanceWindowEnd,//"Mon:00:00-Mon:03:00",
                                    "TF_VAR_enable_deletion_protection": val.cloudManaged.deletionProtection ? "true" : "false",//"false",
                                    "TF_VAR_backup_retention_period": val.cloudManaged.backupRetention === "" ? "0" : val.cloudManaged.backupRetention,//"7",
                                    "TF_VAR_enable_general_log": val.cloudManaged.enableGeneralLogs ? "true" : "false",//"true",
                                    "TF_VAR_enable_slow_query_log": val.cloudManaged.enableSlowLogs ? "true" : "false",//"true",
                                    "TF_VAR_create_random_password": val.cloudManaged.autoGenPassword ? "true" : "false",//"true"
                                    "TF_VAR_master_password": val.cloudManaged.password,
                                    "TF_VAR_log_retention_period": val.cloudManaged.logRetention,
                                    "private_subnet_ids": val.cloudManaged.subnetIds.privateSubnets,
                                    "public_subnet_ids": val.cloudManaged.subnetIds.publicSubnets
                                    //"TF_VAR_enable_backup": val.cloudManaged.enableBackup ? "true" : 'false'
                                }
                            });
                        }    
                    });

                    let postgresSelf = Object.values(addUpdatedData.postgresDataSourcesData);
                    postgresSelf.map((val) => {
                        if (val.serviceEnabled && val.selfManaged.serviceSelf) {
                            servicePayload.push({
                                "name": val.displayName,//"mks2",
                                "service_id": val.serviceSelectedId,//69,
                                "config": {
                                    "db_type": "self-managed-postgresql",
                                    "TF_VAR_postgresql_exporter_enabled": val.selfManaged.enableExporter ? "true" : "false",//"false",
                                    "TF_VAR_replicacount": val.selfManaged.dbType,//"1",
                                    "TF_VAR_storage_class_name": val.selfManaged.storageType.toLowerCase() === "efs" ? "efs-sc" : val.selfManaged.storageType.toLowerCase(),//"gp2",
                                    "TF_VAR_custom_credentials_enabled": !val.selfManaged.autoGenPassword ? "true" : "false",//"true",
                                    "TF_VAR_postgresql_password": val.selfManaged.password, //"",
                                    "TF_VAR_app_version": val.version,//"16.0.0",
                                    "TF_VAR_database_name": val.selfManaged.databaseName,//"atmosly",
                                    "TF_VAR_storage_size": !val.selfManaged.storageSize ? "" : parseInt(val.selfManaged.storageSize),//"10Gi"
                                    "TF_VAR_postgresql_username": val.selfManaged.username,
                                    "TF_VAR_backup_enabled": val.selfManaged.enableBackup ? "true" : "false",
                                    "backup_frequency": val.selfManaged.backupFrequency.toLowerCase(),
                                    "backup_interval": val.selfManaged.backupFrequency === "Weekly" ? days.indexOf(val.selfManaged.backupDay) : val.selfManaged.backupFrequency !== "Hourly" ? 1 : val.selfManaged.backupInterval,
                                    "backup_time": val.selfManaged.backupTiming,
                                    "enable_cross_region_backup": val.selfManaged.enableCrossRegionBackup.toString(),
                                    "TF_VAR_bucket_region": val.selfManaged.crossRegionBackup,
                                    "list_of_nodegroups": val.selfManaged.listOfNodegroups,
                                    "TF_VAR_restore_db": val.selfManaged.enableRestore ? "true" : "false",
                                }
                            });
                        }
                    });

                    console.log("Payload", servicePayload);

                    if (servicePayload.length === 0) {
                        set_alertOptions(alertOptions => ({
                            status: true,
                            type: "error",
                            message: "Environment Creation failed.",
                            description: "Please enable at least one service in order to proceed.",
                        }));

                        // opacity=0 after 10sec
                        setTimeout(() => {
                            set_alertOptions(alertOptions => ({
                                status: false,
                                type: "error",
                                message: "Environment Creation failed.",
                                description: "Please enable at least one service in order to proceed.",
                            }));
                        }, 5 * 1000);
                    }
                    else {
                        setFetching(true);
                        // console.log("Entered else");
                        let envId = location.pathname.split('/').slice(-1)[0];
                        api.post(process.env.REACT_APP_API_URI + `/api/project_env/update/${envId}`, servicePayload).then((res) => {
                            let data = res.data;
                            console.log("Enters");
                            if (data.status) {
                                console.log("Entered");
                                setFetching(false);
                                setAddUpdatedData({});
                                setAddDep(false);
                                setUpdateDep(false);
                                setCloneDep(false);
                                localStorage.setItem('isClone', JSON.stringify(false));
                                localStorage.setItem('updateDep', JSON.stringify(false));
                                localStorage.setItem('addDep', JSON.stringify(false));
                                localStorage.setItem('stopFetch', JSON.stringify(false));
                                localStorage.removeItem('addUpdatedData');
                                navigate(`/environments/environment/${envId}/env-overview`, {
                                    state: {
                                        from: "/environments/services"
                                    }
                                });
                                // if (!cloneDep) {
                                //     setApplicationData({});
                                //     setDataSourcesData({});
                                //     setMongoDataSourcesData({});
                                //     setRedisData({});
                                //     setServicesEnabled({});
                                //     setSelectedProject({});
                                //     setPostgresDataSourcesData({});
                                //     setContainerRegistries({});
                                //     setCurrentProjectDep({});
                                //     setDeploymentData({});
                                //     setServiceCategories({});
                                //     RemoveEnvironmentFromLocal();
                                // }
                                // else {
                                //     handleDeploymentLogout();
                                //     RemoveCloneEnvironmentFromLocal();
                                // }
                            }
                            else if (data.error_details && (data.error_details.cluster_unique_id || data.error_details.name || data.error_details.namespace || data.error_details.services[0]?.service_id)) {
                                setFetching(false);

                                set_alertOptions(alertOptions => ({
                                    status: true,
                                    type: "error",
                                    message: "Add new service failed.",
                                    description: data.error_details.cluster_unique_id ? data.error_details.cluster_unique_id : data.error_details.name ? data.error_details.name : data.error_details.services[0].service_id ? data.error_details.services[0].service_id : data.error_details.namespace,
                                }));

                                // opacity=0 after 10sec
                                setTimeout(() => {
                                    set_alertOptions(alertOptions => ({
                                        status: false,
                                        type: "error",
                                        message: "Add new service failed.",
                                        description: data.error_details.cluster_unique_id ? data.error_details.cluster_unique_id : data.error_details.name ? data.error_details.name : data.error_details.services[0].service_id ? data.error_details.services[0].service_id : data.error_details.namespace,
                                    }));
                                }, 5 * 1000);
                            }
                            else if (data.error_details.detail) {
                                setFetching(false);
                                set_alertOptions(alertOptions => ({
                                    status: true,
                                    type: "error",
                                    message: "Add new service failed.",
                                    description: formatErrors(data.error_details),
                                }));

                                // opacity=0 after 10sec
                                setTimeout(() => {
                                    set_alertOptions(alertOptions => ({
                                        status: false,
                                        type: "error",
                                        message: "Add new service failed.",
                                        description: formatErrors(data.error_details),
                                    }));
                                }, 5 * 1000);
                            }
                            else {
                                console.log("Error...");
                                setFetching(false);
                                let myErrors = {};
                                Object.keys(data.error_details.services).map((val) => {
                                    if (Object.keys(data.error_details.services[val]).length > 0) {
                                        myErrors = {
                                            ...myErrors,
                                            [val]: data.error_details.services[val]
                                        }
                                    }
                                });
                                console.log("MY errors: ", myErrors);
                                for (const val of allForms) {

                                    if (myErrors[val.serviceSelected]) {
                                        console.log("Val data source: ", val.form.getFieldWarning());

                                        val.form.setFields(
                                            Object.entries(myErrors[val.serviceSelected]).map(([field, err]) => ({
                                                name: field === "name" ? (snakeToCamel[field] in val.form.getFieldsValue()) ? snakeToCamel[field] : "appName" : snakeToCamel[field],
                                                //warnings: val.form.getFieldWarning()[snakeToCamel[field]],
                                                errors: err
                                            }))
                                        );

                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                        // // val.form.validateFields(['displayName']).then((res) => {
                                        // //     console.log("Validated", res);
                                        // // }).catch((err) => {
                                        // //     console.log("All errors info: ", err);
                                        // // });
                                        // // val.form.validateFields().then(() => {
                                        // //     console.log("Validated", val.form.getFieldsError());
                                        // // }).catch((error) => {

                                        // // });
                                        // //val.form.validateFields();
                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                        // console.log("Val.form.getFields", val.form.getFieldsError());
                                    }
                                }
                                console.log("All Forms: ", allForms[0].form.getFieldsError());
                                setFormErrors(myErrors);
                                if (Object.keys(myErrors).length > 0) {
                                    set_alertOptions(alertOptions => ({
                                        status: true,
                                        type: "error",
                                        message: "Add new service failed",
                                        description: "Please enter correct information for the services in order to proceed."
                                    }));

                                    // opacity=0 after 10sec
                                    setTimeout(() => {
                                        set_alertOptions(alertOptions => ({
                                            status: false,
                                            type: "error",
                                            message: "Add new service failed",
                                            description: "Please enter correct information for the services in order to proceed.",
                                        }));
                                    }, 5 * 1000);
                                }
                            }
                        }).catch((err) => {
                            console.log("Error catch");
                            set_alertOptions(alertOptions => ({
                                status: true,
                                type: "error",
                                message: "Add new service failed",
                                description: "Something went wrong. Please try again."
                            }));

                            // opacity=0 after 10sec
                            setTimeout(() => {
                                set_alertOptions(alertOptions => ({
                                    status: false,
                                    type: "error",
                                    message: "Add new service failed",
                                    description: "Something went wrong. Please try again."
                                }));
                            }, 5 * 1000);
                            setFetching(false);
                        });
                    }
                }
                else {
                    set_alertOptions(alertOptions => ({
                        status: true,
                        type: "error",
                        message: "Add new service failed.",
                        description: "There were some changes to the selected project. Please fill the information again."
                    }));

                    // opacity=0 after 10sec
                    setTimeout(() => {
                        set_alertOptions(alertOptions => ({
                            status: false,
                            type: "error",
                            message: "Add new service failed",
                            description: "There were some changes to the selected project. Please fill the information again."
                        }));
                    }, 5 * 1000);
                    //navigate('/environments/create');
                }
            }
        }
    }

    const handleDeploymentLogout = () => {
        if (!cloneDep) {
            setApplicationData({});
            setDataSourcesData({});
            setMongoDataSourcesData({});
            setRedisData({});
            setServicesEnabled({});
            setSelectedProject({});
            setContainerRegistries({});
            setCurrentProjectDep({});
            setDeploymentData({ ...initialDeploymentData });
            setServiceCategories({});
            setPostgresDataSourcesData({});
        }
        else {
            setCloneApplicationData({});
            setCloneDataSourcesData({});
            setCloneMongoDataSourcesData({});
            setCloneRedisData({});
            setCloneServicesEnabled({});
            setCloneSelectedProject({});
            setCloneContainerRegistries({});
            setCurrentProjectDep({});
            setCloneDeploymentData({ ...initialDeploymentData });
            setCloneServiceCategories({});
            setClonePostgresDataSourcesData({});
            setImportantEnabled(false);
            setNextBtn(false);
            setPrevBtn(false);
            setImportantFields({});
            setSelectedEnv({});
            setCurrentProjectDep({});
        }
    }

    const [deploymentData, setDeploymentData] = useState(initialDeploymentData);
    const [cloneDeploymentData, setCloneDeploymentData] = useState(initialDeploymentData);
    const [selectedProject, setSelectedProject] = useState({});
    const [cloneSelectedProject, setCloneSelectedProject] = useState({});
    const [updateSelectedProject, setUpdateSelectedProject] = useState({});
    const [applicationData, setApplicationData] = useState({});
    const [applicationPayload, setApplicationPayload] = useState({});
    const [cloneApplicationData, setCloneApplicationData] = useState({});
    const [cloneApplicationPayload, setCloneApplicationPayload] = useState({});
    const [containerRegistries, setContainerRegistries] = useState([]);
    const [cloneContainerRegistries, setCloneContainerRegistries] = useState([]);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [cloneServiceCategories, setCloneServiceCategories] = useState([]);
    const [cloneDep, setCloneDep] = useState(false);
    const [importantEnabled, setImportantEnabled] = useState(false);
    const [currentProjectDep, setCurrentProjectDep] = useState(JSON.parse(JSON.stringify(initialProject)));

    const [dataSourcesData, setDataSourcesData] = useState({});
    const [cloneDataSourcesData, setCloneDataSourcesData] = useState({});

    const [servicesEnabled, setServicesEnabled] = useState({});
    const [cloneServicesEnabled, setCloneServicesEnabled] = useState({});
    const [mongoDataSourcesData, setMongoDataSourcesData] = useState({});
    const [cloneMongoDataSourcesData, setCloneMongoDataSourcesData] = useState({});
    const [redisData, setRedisData] = useState({});
    const [cloneRedisData, setCloneRedisData] = useState({});
    const [postgresDataSourcesData, setPostgresDataSourcesData] = useState({});
    const [clonePostgresDataSourcesData, setClonePostgresDataSourcesData] = useState({});
    const [selectedEnv, setSelectedEnv] = useState({});
    const [allAppsSelected, setAllAppsSelected] = useState([]);
    const [deploymentForms, setDeploymentForms] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [releaseStatus, setReleaseStatus] = useState("");
    const { alertOptions, set_alertOptions } = useContext(AlertContext);
    const [fetching, setFetching] = useState(false);
    const [importantFields, setImportantFields] = useState({});
    const [nextBtn, setNextBtn] = useState(false);
    const [prevBtn, setPrevBtn] = useState(false);
    const [myKeySelected, setMyKeySelected] = useState({
        index: 0,
        serviceName: ""
    });
    const [clonePages, setClonePages] = useState({
        envData: false,
        serviceData: false
    });
    let location = useLocation();
    const [subTab, setSubTab] = useState("");
    const [selectedService, setSelectedService] = useState(""); //for env overview select.
    //const [canFetchEnvs, setCanFetchEnvs] = useState(true);

    const [fetchingUpdateData, setFetchingUpdateData] = useState("");
    const [updatedData, setUpdatedData] = useState({});
    const [updateDep, setUpdateDep] = useState(false);

    const [addUpdatedData, setAddUpdatedData] = useState();
    const [addDep, setAddDep] = useState(false);
    const [callAddMethod, setCallAddMethod] = useState(false);
    const [appServiceData, setAppServiceData] = useState({});
    const [dataSourceServiceData, setDataSourceServiceData] = useState({});
    const [serviceList, setServiceList] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Environment Overview");
    const [loadService, setLoadService] = useState({
        application: false,
        dataSource: false,
        queues: false
    });

    const [initialEnvDeploymentData, setInitialEnvDeploymentData] = useState({
        id: "",
        envName: "",
        project: "",
        namespace: "",
        uniqueId: "",
        projectName: "",
        cloudAccountNo: "",
        cloudLogo: "",
        cloudAccount: "",
        clusterRegion: "",
        clusterId: "",
        kubernetesVersion: "",
        serviceDetails: [],
        totalServices: "",
        healthyServices: "",
        unhealthyServices: "",
        totalReleases: "",
        healthyReleases: "",
        unhealthyReleases: "",
        clusterName: "",
        availabilityZones: [],
        dns: "",
        hostedAccount: "",
        recordType: "",
        clusterIntegrationId: "",
        projectId: "",
        tags: [],
        provisioner: "",
        vpc_id: ""
    });

    const [envDeploymentData, setEnvDeploymentData] = useState(JSON.parse(JSON.stringify(initialEnvDeploymentData)));
    const [warningsObj, setWarningsObj] = useState({});
    const [currentImportantField, setCurrentImportantField] = useState("");
    const [prevImportantField, setPrevImportantField] = useState("");
    const [currentForm, setCurrentForm] = useState();
    const [serviceDisabled, setServiceDisabled] = useState(false);
    const [isRetrigger, setIsRetrigger] = useState(false);
    const [mapIdsWithAppName, setMapIdsWithAppName] = useState({});
    const [isServiceDeleted, setIsServiceDeleted] = useState(false);
    const [prerequisite, setPrerequisite] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [sampleEnvData, setSampleEnvData] = useState({ ...initialDeploymentData });
    const [nextImportantField, setNextImportantField] = useState("");

    const [generateImpField, setGenerateImpField] = useState(true); //set to true by default


    // useEffect(() => {
    //     if(importantEnabled) {
    //         setInitialWarnings({...warningsObj});
    //     }
    // }, [importantEnabled]);

    // useEffect(() => {
    //     console.log("Warnings: ", warningsObj, currentImportantField, prevImportantField, nextImportantField);
    //     let allForms = deploymentForms;
    //     if (importantEnabled) {
    //         if (Object.keys(warningsObj).length > 0 && warningsObj[currentForm]) {
    //             if (importantEnabled) {
    //                 console.log("entering check....2", JSON.parse(JSON.stringify(warningsObj)));
    //                 let arr = Object.entries(warningsObj[currentForm]).map(([field, err]) => ({
    //                     name: field,//snakeToCamel[field],
    //                     warnings: err
    //                 }));
    //                 arr = arr.filter(x => allForms[currentForm].getFieldInstance(x.name)?.input?.disabled === true ? false : x); //this will filter out the elements if they get manually disabled by the user while they are editing the form
    //                 console.log("Filtering current, prev and next: ", currentImportantField, prevImportantField, nextImportantField);
    //                 if (currentImportantField !== "" || prevImportantField !== "" || nextImportantField !== "") {
    //                     arr = arr.filter(x => x.name === currentImportantField || x.name === prevImportantField || x.name === nextImportantField);
    //                 }
    //                 console.log("Setting the arr: ", arr);
    //                 allForms[currentForm].setFields(arr);

    //                 console.log("All Forms val:", allForms[currentForm].getFieldsError());
    //                 //}
    //             }
    //         }
    //     }
    //     else {
    //         if (Object.keys(warningsObj).length > 0 && warningsObj[currentForm]) {
    //             let arr = Object.entries(warningsObj[currentForm]).map(([field, err]) => ({
    //                 name: field,//snakeToCamel[field],
    //                 warnings: []
    //             }));
    //             allForms[currentForm].setFields(arr);
    //             setWarningsObj({});
    //         }
    //     }
    // }, [importantEnabled, deploymentForms, warningsObj]);

    useEffect(() => {
        console.log("I'm inside deployment context", warningsObj);
        //localStorage.setItem('canFetchEnvs', JSON.stringify(true));
        if (!updateDep) {
            const project = localStorage.getItem("ProjectDep");
            if (JSON.parse(project)) {
                setCurrentProjectDep({ ...JSON.parse(project) });
            }
        }
        else {
            const project = JSON.parse(localStorage.getItem("updatedData")).currentProjectDep;
            if (JSON.parse(project)) {
                setUpdatedData({
                    ...updatedData,
                    currentProjectDep: project
                });
            }
        }
    }, []);

    //For application

    useEffect(() => {
        if (updateDep || addDep) {
            // const projData = localStorage.getItem("updateSelectedProjectData");
            // if (JSON.parse(projData)) {
            //     setUpdateSelectedProject({ ...JSON.parse(projData) });
            // }
            if (updateDep) {
                const updateData = localStorage.getItem('updatedData');
                const sampleUpdateData = localStorage.getItem('sampleEnvData');
                if (JSON.parse(updateData)) {
                    setUpdatedData({ ...JSON.parse(updateData) });
                }
                if (JSON.parse(sampleUpdateData)) {
                    setSampleEnvData({ ...JSON.parse(sampleUpdateData) });
                }
            }
            else {
                const addData = localStorage.getItem('addUpdatedData');
                if (JSON.parse(addData)) {
                    setAddUpdatedData({ ...JSON.parse(addData) });
                }
            }
        }
        else if (!cloneDep) {
            const depData = localStorage.getItem("deploymentData");
            if (JSON.parse(depData)) {
                setDeploymentData({ ...JSON.parse(depData) });
            }
            const myData = localStorage.getItem("app-data");
            if (JSON.parse(myData)) {
                console.log("Setting the data to app", myData);
                setApplicationData({ ...JSON.parse(myData) });
            }
            const appPayload = localStorage.getItem("appPayload");
            if (JSON.parse(appPayload)) {
                setApplicationPayload({ ...JSON.parse(appPayload) });
            }
            const projData = localStorage.getItem("selectedProjectData");
            if (JSON.parse(projData)) {
                setSelectedProject({ ...JSON.parse(projData) });
            }
            const containerReg = localStorage.getItem("container-registries");
            if (JSON.parse(containerReg)) {
                setContainerRegistries([...JSON.parse(containerReg)]);
            }
            const serviceCat = localStorage.getItem("service-categories");
            if (JSON.parse(serviceCat)) {
                setServiceCategories([...JSON.parse(serviceCat)]);
            }
            const serviceEnabled = localStorage.getItem("servicesEnabled");
            if (JSON.parse(serviceEnabled)) {
                setServicesEnabled({ ...JSON.parse(serviceEnabled) });
            }
        }

        else {
            const cloneDepData = localStorage.getItem("cloneDeploymentData");
            if (JSON.parse(cloneDepData)) {
                setCloneDeploymentData({ ...JSON.parse(cloneDepData) });
            }
            const cloneAppData = localStorage.getItem("cloneAppData");
            if (JSON.parse(cloneAppData)) {
                setCloneApplicationData({ ...JSON.parse(cloneAppData) });
            }
            const cloneAppPayload = localStorage.getItem("cloneAppPayload");
            if (JSON.parse(cloneAppPayload)) {
                setCloneApplicationPayload({ ...JSON.parse(cloneAppPayload) });
            }
            const cloneProjData = localStorage.getItem("cloneSelectedProjectData");
            if (JSON.parse(cloneProjData)) {
                setCloneSelectedProject({ ...JSON.parse(cloneProjData) });
            }
            const cloneContainerReg = localStorage.getItem("cloneContainerRegistries");
            if (JSON.parse(cloneContainerReg)) {
                setCloneContainerRegistries([...JSON.parse(cloneContainerReg)]);
            }
            const cloneServiceCat = localStorage.getItem("cloneServiceCategories");
            if (JSON.parse(cloneServiceCat)) {
                setCloneServiceCategories([...JSON.parse(cloneServiceCat)]);
            }
            const cloneServiceEnabled = localStorage.getItem("cloneServicesEnabled");
            if (JSON.parse(cloneServiceEnabled)) {
                setCloneServicesEnabled({ ...JSON.parse(cloneServiceEnabled) });
            }
            const impFields = localStorage.getItem("importantFields");
            if (JSON.parse(impFields)) {
                setImportantFields({ ...JSON.parse(impFields) });
            }
        }

        const isUpdate = localStorage.getItem("updateDep");
        if (JSON.parse(isUpdate)) {
            console.log("Setting the value of updateDep", isUpdate);
            setUpdateDep(JSON.parse(isUpdate));
        }

        const isAdd = localStorage.getItem("addDep");
        if (JSON.parse(isAdd)) {
            setAddDep(JSON.parse(isAdd));
        }
        const isClone = localStorage.getItem("isClone");
        if (JSON.parse(isClone)) {
            setCloneDep(JSON.parse(isClone));
        }
        const clonedPages = localStorage.getItem("clonedPages");
        if (JSON.parse(clonedPages)) {
            setClonePages({ ...JSON.parse(clonedPages) });
        }
    }, [cloneDep, updateDep, addDep]);

    //For data sources

    useEffect(() => {
        if (!cloneDep) {
            const dataSources = localStorage.getItem("dataSourcesData");
            if (JSON.parse(dataSources)) {
                setDataSourcesData({ ...JSON.parse(dataSources) });
            }
            const mongoDataSources = localStorage.getItem("mongoDataSourcesData");
            if (JSON.parse(mongoDataSources)) {
                setMongoDataSourcesData({ ...JSON.parse(mongoDataSources) });
            }
            const postgresDataSources = localStorage.getItem("postgresDataSourcesData");
            if (JSON.parse(postgresDataSources)) {
                setPostgresDataSourcesData({ ...JSON.parse(postgresDataSources) });
            }
        }
        else {
            const cloneDataSources = localStorage.getItem("cloneDataSourcesData");
            if (JSON.parse(cloneDataSources)) {
                setCloneDataSourcesData({ ...JSON.parse(cloneDataSources) });
            }
            const cloneMongoDataSources = localStorage.getItem("cloneMongoDataSourcesData");
            if (JSON.parse(cloneMongoDataSources)) {
                setCloneMongoDataSourcesData({ ...JSON.parse(cloneMongoDataSources) });
            }
            const clonePostgresDataSources = localStorage.getItem("clonePostgresDataSourcesData");
            if (JSON.parse(clonePostgresDataSources)) {
                setClonePostgresDataSourcesData({ ...JSON.parse(clonePostgresDataSources) });
            }
            const cloneRedisDataSources = localStorage.getItem("cloneRedisData");
            if (JSON.parse(cloneRedisDataSources)) {
                setCloneRedisData({ ...JSON.parse(cloneRedisDataSources) });
            }
        }
    }, [cloneDep]);

    useEffect(() => {
        if (!cloneDep) {
            const redisService = localStorage.getItem("redisData");
            if (JSON.parse(redisService)) {
                setRedisData({ ...JSON.parse(redisService) });
            }
        }
        else {
            const cloneRedisService = localStorage.getItem("cloneRedisData");
            if (JSON.parse(cloneRedisService)) {
                setRedisData({ ...JSON.parse(cloneRedisService) });
            }
        }
    }, [cloneDep]);

    useEffect(() => {
        if (cloneDep) {
            const selectEnv = localStorage.getItem('selectedEnv');
            if (JSON.parse(selectEnv)) {
                setSelectedEnv({ ...JSON.parse(selectEnv) });
            }
        }
    }, [cloneDep]);

    return (
        <DeploymentContext.Provider value={{
            currentForm, setCurrentForm,
            warningsObj, setWarningsObj,
            currentImportantField, setCurrentImportantField,
            initialEnvDeploymentData, setInitialEnvDeploymentData,
            envDeploymentData, setEnvDeploymentData,
            loadService, setLoadService,
            selectedTab, setSelectedTab,
            serviceList, setServiceList,
            dataSourceServiceData, setDataSourceServiceData,
            appServiceData, setAppServiceData,
            callAddMethod, setCallAddMethod,
            addDep, setAddDep,
            addUpdatedData, setAddUpdatedData,
            updateDep, setUpdateDep,
            updatedData, setUpdatedData,
            fetchingUpdateData, setFetchingUpdateData,
            selectedService, setSelectedService,
            subTab, setSubTab,
            clonePages, setClonePages,
            myKeySelected, setMyKeySelected,
            impFieldConvert,
            nextBtn, setNextBtn,
            importantFields, setImportantFields,
            releaseStatus, setReleaseStatus,
            handleDeploymentLogout,
            deploymentData, setDeploymentData,
            applicationData, setApplicationData,
            applicationPayload, setApplicationPayload,
            selectedProject, setSelectedProject,
            updateSelectedProject, setUpdateSelectedProject,
            containerRegistries, setContainerRegistries,
            serviceCategories, setServiceCategories,
            cloneServiceCategories, setCloneServiceCategories,
            cloneDeploymentData, setCloneDeploymentData,
            cloneSelectedProject, setCloneSelectedProject,
            cloneApplicationData, setCloneApplicationData,
            cloneApplicationPayload, setCloneApplicationPayload,
            cloneContainerRegistries, setCloneContainerRegistries,
            dataSourcesData, setDataSourcesData,
            cloneDataSourcesData, setCloneDataSourcesData,
            cloneDep, setCloneDep,
            initialApplicationData,
            initialDataSourcesData,
            servicesEnabled, setServicesEnabled,
            cloneServicesEnabled, setCloneServicesEnabled,
            handleSubmit,
            selectedEnv, setSelectedEnv,
            initialMongoDataSourcesData,
            mongoDataSourcesData, setMongoDataSourcesData,
            cloneMongoDataSourcesData, setCloneMongoDataSourcesData,
            currentProjectDep, setCurrentProjectDep,
            redisData, setRedisData,
            cloneRedisData, setCloneRedisData,
            initialRedisData,
            postgresDataSourcesData, setPostgresDataSourcesData,
            clonePostgresDataSourcesData, setClonePostgresDataSourcesData,
            initialPostgresData,
            allAppsSelected, setAllAppsSelected,
            deploymentForms, setDeploymentForms,
            formErrors, setFormErrors,
            fetching, setFetching,
            importantEnabled, setImportantEnabled,
            serviceDisabled, setServiceDisabled,
            isRetrigger, setIsRetrigger,
            mapIdsWithAppName, setMapIdsWithAppName,
            isServiceDeleted, setIsServiceDeleted,
            prerequisite, setPrerequisite,
            isSubmitted, setIsSubmitted,
            sampleEnvData, setSampleEnvData,
            prevBtn, setPrevBtn,
            nextImportantField, setNextImportantField,
            validateYamls, warnings,
            generateImpField, setGenerateImpField
        }}>
            {children}
        </DeploymentContext.Provider>
    );
}

export { DeploymentContext, DeploymentProvider };